import { useState } from 'react'
import { Box } from '@mui/material'
import Typography from '@app/stories/Typography'
import Icon from '@app/stories/Icons'

import { createLightBackgroundColor } from '@app/utils/hexcodeUtils'

import BubbleBuilder from '@app/components/SvgBuilders/BubbleBuilder'

const HalfPhoneCard = ({ title, content, bubble, color, setSectionSelected, sectionSelected }) => {
  const [hover, setHover] = useState(false)

  const lightBackgroundColor = createLightBackgroundColor(color)

  return (
    <Box
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() => setSectionSelected(bubble)}
      borderRadius='8px'
      border={
        sectionSelected === bubble
          ? `1px solid ${color}`
          : hover
            ? `1px solid ${color}`
            : '1px solid #e0e0e0'
      }
      padding='8px'
      width='100%'
      color={hover ? color || 'primary' : '#000'}
      display='flex'
      alignItems='center'
      bgcolor={sectionSelected === bubble ? lightBackgroundColor : 'white'}
      style={{
        cursor: 'pointer',
      }}
    >
      {bubble && (
        <Box display='flex' justifyContent='center' alignItems='center' height='75px' width='75px'>
          <BubbleBuilder color={color} bubbleName={bubble} />
        </Box>
      )}
      <Box display='flex' flexDirection='column' width='100%' paddingX='8px'>
        <Typography variant='h5' marginBottom='4px' weight='medium'>
          {title}
        </Typography>
        <Typography variant='body1' weight='light' color='black'>
          {content}
        </Typography>
      </Box>
      <Box>
        <Icon name='chevronRight' color={hover ? color || 'primary' : '#000'} />
      </Box>
    </Box>
  )
}

export default HalfPhoneCard
