import PropTypes from 'prop-types'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import QuickCheckoutNetworkProvider from '@app/context/QuickCheckoutNetwork'

const VITE_STRIPE_KEY = import.meta.env.VITE_STRIPE_KEY

const promise = loadStripe(VITE_STRIPE_KEY)

const QuickCheckoutContainer = ({ clientSecret = null, paymentIntent = null, children }) => {
  if (!clientSecret || !paymentIntent) {
    return (
      <>
        <h1>Missing client secret or payment intent</h1>
      </>
    )
  }

  return (
    <Elements
      options={{
        clientSecret,
      }}
      stripe={promise}
    >
      <QuickCheckoutNetworkProvider clientSecret={clientSecret} paymentIntent={paymentIntent}>
        {children}
      </QuickCheckoutNetworkProvider>
    </Elements>
  )
}

QuickCheckoutContainer.propTypes = {
  children: PropTypes.node.isRequired,
  paymentIntent: PropTypes.string,
  clientSecret: PropTypes.string,
}

export default QuickCheckoutContainer
