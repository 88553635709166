import { useEffect, useState, useCallback } from 'react'
import { query, where, onSnapshot, getDoc, doc } from 'firebase/firestore'
import { collectionModular } from '../firebase/firestore'
import { useCurrentProfile } from '@app/context/CurrentUser'
import PatientDataTable from '@app/components/PatientData'

const PatientData = () => {
  const { uid } = useCurrentProfile()
  const [mergedData, setMergedData] = useState([])

  const getNotes = useCallback(async () => {
    if (!uid) return

    try {
      const noteQuery = query(
        collectionModular('notes'),
        where('patient.id', '==', uid),
        where('sharedWithPatient', '==', true),
      )

      onSnapshot(noteQuery, async (querySnapshot) => {
        if (querySnapshot.empty) {
          setMergedData([])
          return
        }

        const notesList = querySnapshot.docs
          .map((doc) => ({ id: doc.id, ...doc.data() }))
          .filter((note) => note?.voucher?.id)

        // get all distinct unique vouchers
        const voucherIds = [...new Set(notesList.map((note) => note.voucher.id).filter(Boolean))]
        if (voucherIds.length === 0) {
          setMergedData(notesList)
          return
        }

        // gets all the vouchers for the notes
        const vouchersMap = {}
        for (const id of voucherIds) {
          try {
            const docRef = doc(collectionModular('vouchers'), id)
            const docSnap = await getDoc(docRef)
            if (docSnap.exists()) {
              vouchersMap[id] = { id, ...docSnap.data() }
            }
          } catch (error) {
            console.warn(`Error fetching voucher, its probably a subvoucher ${id}:`, error)
          }
        }

        // merge the note with vouchers
        const combinedData = notesList.map((note) => ({
          ...note,
          voucher: vouchersMap[note.voucher.id] || null,
        }))

        setMergedData(combinedData)
      })
    } catch (error) {
      console.error('Error fetching notes and vouchers:', error)
    }
  }, [uid])

  useEffect(() => {
    getNotes()
  }, [uid, getNotes])

  return <PatientDataTable notes={mergedData} />
}

export default PatientData
