// Utility function for handling redirects
export const handleRedirects = (
  redirects: { hostname: string; pathname: string; targetUrl: string }[],
) => {
  redirects.forEach(({ hostname, pathname, targetUrl }) => {
    if (window.location.hostname === hostname && window.location.pathname === pathname) {
      window.location.replace(targetUrl)
    }
  })
}
