import { createContext, useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import useNetworks from '@app/hooks/useNetworks'

import { retrieveFiretableImageURL } from '../libs/formatters'

import { useWhiteLabelingContext } from './WhiteLabelingContext'

const CurrentNetwork = createContext()

export const useCurrentNetwork = () => useContext(CurrentNetwork)

const hexaDecimalColorCodeRegex = /^#(?:[0-9a-fA-F]{3,4}){1,2}$/
const phoneNumberRegex = /^\d{3}-\d{3}-\d{4}$/
const zipcodeRegex = /^\d{5}$/

const CurrentNetworkProvider = ({ children }) => {
  const {
    getNetworkByName,
    getNetworkBySlug,
    getNetworkByID,
    getServicesByMisheCode,
    getServicesBySpecialtyTag,
    getClinicLocations,
    getLanderData,
    checkIfMember,
  } = useNetworks()

  const {
    setLogoSource,
    setPlanColorPrimary,
    setPlanColorSecondary,
    setloginMethods,
    setPlanSlug,
    setPlanPhoneNumber,
    setPlanSMSNumber,
    setDefaultZipcode,
  } = useWhiteLabelingContext()

  const [networkId, setNetworkId] = useState(null)
  const [networkData, setNetworkData] = useState(null)
  const [landerData, setLanderData] = useState(null)
  const [serviceList, setServiceList] = useState(null)
  const [currentServices, setCurrentServices] = useState(null)
  const [clinics, setClinics] = useState(null)
  const [clinicLocations, setClinicLocations] = useState(null)
  const [allUTMs, setAllUTMs] = useState({})
  const [unformattedUTMs, setUnformattedUTMs] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    async function loadLanderData() {
      if (!networkId) {
        return undefined
      }
      setLoading(true)

      if (networkId === 'permission-denied') {
        setLanderData({ error: 'permission-denied' })
        return undefined
      }

      if (networkId === 'not-found') {
        setLanderData({ error: 'network-not-found' })
        return undefined
      }

      const response = await getLanderData(networkId)
      setLanderData(response)
      setLoading(false)
    }
    loadLanderData()
  }, [networkId])

  useEffect(() => {
    const getOrgLocs = async () => {
      if (!currentServices || currentServices?.error) {
        return undefined
      }
      // get all org ids from services and remove duplicates
      const validClinics = await currentServices
        .map((service) => {
          if (service.bundle) {
            const clinicIDs = service.bundleComponents.map((bundleComponent) => {
              return bundleComponent.organization.uid
            })
            return clinicIDs
          }

          return service.organizationId
        })
        .flat()

      const uniqueClinics = [...new Set(validClinics)]

      setClinics(uniqueClinics)

      // Get all locations for each clinic and add the services offered to the clinic based on ID
      const allLocations = await Promise.all(
        uniqueClinics.map(async (clinicID) => {
          const locationsRes = await getClinicLocations(clinicID)

          // First filter for services only from the current clinic
          // Then check if any of the services from this clinic are not on the network
          let notOnNetwork = false

          const services = currentServices.filter((service) => {
            if (service.bundle) {
              return service.bundleComponents.some((bundleComponent) => {
                return bundleComponent.organization.uid === clinicID
              })
            }
            return service.organizationId === clinicID
          })

          let clinic = null
          if (services.length !== 0) {
            // Bundle Service
            if (services[0].bundle) {
              clinic = services[0].bundleComponents[0].organization
              services.forEach((service) => {
                if (service.notOnNetwork) {
                  notOnNetwork = true
                }
              })
            } else {
              // Standard Service
              clinic = services[0]?.organization
              services.forEach((service) => {
                if (service.notOnNetwork) {
                  notOnNetwork = true
                }
              })
            }
          }

          return { ...locationsRes, services, notOnNetwork, clinic }
        }),
      )

      setClinicLocations(allLocations)
    }
    getOrgLocs()
  }, [currentServices])

  useEffect(() => {
    if (networkData?.error || networkData?.code) {
      console.warn(' -- context error with network data')
      console.warn(networkData)
      return
    }
    if (!networkData) {
      console.error('  ** context - No network data')
      return
    }

    console.log('networkData', networkData)

    const {
      loginMethods,
      slug,
      logoSource,
      // customNavElements,
      // showDefaultNavElements,
      planTheme,
      planContact,
      defaultZipcode,
    } = networkData

    if (logoSource) {
      const customLogoURL = retrieveFiretableImageURL(networkData?.logoSource) || null
      setLogoSource(customLogoURL)
    }

    if (planTheme) {
      const { primaryColor = '', secondaryColor = '' } = planTheme

      // Checking if the color is a valid hex color
      if (primaryColor.match(hexaDecimalColorCodeRegex)) {
        setPlanColorPrimary(primaryColor)
      }
      if (secondaryColor.match(hexaDecimalColorCodeRegex)) {
        setPlanColorSecondary(secondaryColor)
      }
    }

    if (loginMethods) {
      setloginMethods(loginMethods)
    }

    if (slug) {
      setPlanSlug(slug)
    }

    if (planContact) {
      console.log(' -- planContact', planContact)
      const { phoneNumber = '', smsNumber = '' } = planContact

      // check if valid phone number in the format of 123-456-7890
      const isPhoneNumberValid = phoneNumber?.toString()?.match(phoneNumberRegex) || false
      const isSMSNumberValid = smsNumber?.toString()?.match(phoneNumberRegex) || false

      if (isPhoneNumberValid && isSMSNumberValid) {
        console.log(' -- both phone and sms are valid')
        setPlanPhoneNumber(phoneNumber)
        setPlanSMSNumber(smsNumber)
      } else if (isPhoneNumberValid) {
        console.log(' -- phone is valid')
        setPlanPhoneNumber(phoneNumber)
        setPlanSMSNumber('')
      } else if (isSMSNumberValid) {
        console.log(' -- sms is valid')
        setPlanPhoneNumber('')
        setPlanSMSNumber(smsNumber)
      } else {
        console.log(' -- neither phone nor sms is valid')
        // setPlanPhoneNumber('')
        // setPlanSMSNumber('')
      }
    }

    if (defaultZipcode) {
      // check if valid zipcode
      if (defaultZipcode.match(zipcodeRegex)) {
        setDefaultZipcode(defaultZipcode)
      }
    }
  }, [networkData])

  const loadNetworkBySlug = async (slug) => {
    setLoading(true)
    const network = await getNetworkBySlug(slug)

    if (!network) {
      setLoading(false)
      setNetworkData({ error: 'network-not-found' })
      return null
    }

    if (network.code) {
      setLoading(false)
      setNetworkData(network)
      setNetworkId(network.code)
      return network.code
    }

    setNetworkData(network)
    setNetworkId(network.uid)
    setLoading(false)
  }

  const loadNetworkByName = async (name) => {
    setLoading(true)
    const network = await getNetworkByName(name)
    setNetworkData(network)
    setNetworkId(network.uid)
    setLoading(false)
  }

  const loadNetowrkByID = async (id) => {
    setLoading(true)
    const network = await getNetworkByID(id)
    setNetworkData(network)
    setNetworkId(network.uid)
    setLoading(false)
  }

  const loadServicesBySpecialtyTag = async (tag) => {
    setLoading(true)
    const services = await getServicesBySpecialtyTag(tag)
    setServiceList(services)
    setLoading(false)
  }

  const loadServicesByMisheCode = async ({ netId, misheCode }) => {
    setLoading(true)
    const services = await getServicesByMisheCode({ netId, misheCode })
    setCurrentServices(services)
    setLoading(false)
  }

  const value = {
    setNetworkId,
    networkId,
    loadNetworkBySlug,
    loadNetworkByName,
    loadNetowrkByID,
    networkData,
    loadServicesBySpecialtyTag,
    serviceList,
    loadServicesByMisheCode,
    setCurrentServices,
    currentServices,
    landerData,
    loading,
    setAllUTMs,
    allUTMs,
    setUnformattedUTMs,
    unformattedUTMs,
    clinics,
    clinicLocations,
    checkIfMember,
  }

  return <CurrentNetwork.Provider value={value}>{children}</CurrentNetwork.Provider>
}

CurrentNetworkProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default CurrentNetworkProvider
