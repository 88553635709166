import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import Form from '@app/components/SignUpForm'
import { signUp } from '../firebase/auth'
import useErrors from '@app/hooks/useAuthErrors'
import { convertDateToUtcMidnight } from '@app/libs/formatters'

const SignUpForm = ({ callback }) => {
  const [errors, handleSubmit] = useErrors()
  const { search } = useLocation()
  const [selectedDate, setSelectedDate] = useState(null)

  const formToProfile = (elements) => {
    const email = elements.email.value.toLowerCase()
    const firstname = elements.firstname.value.toLowerCase()
    const lastname = elements.lastname.value.toLowerCase()
    const password = elements.password.value
    const dob = convertDateToUtcMidnight(selectedDate)
    const profile = {
      email,
      firstname,
      lastname,
      gender: elements.gender.value,
      dob,
      phone: elements.phone.value,
      terms: elements.terms.checked,
      hipaa: elements.hipaa.checked,
      smsOptIn: elements.smsOptIn.checked,
      cart: [],
    }
    return [email, password, profile]
  }

  const ref = new URLSearchParams(search).get('ref')
  if (ref) {
    localStorage.setItem('refvalue', ref)
  }
  const onSubmit = handleSubmit((elements) => {
    const [email, password, profile] = formToProfile(elements)
    if (localStorage.getItem('refvalue')) {
      profile.refvalue = localStorage.getItem('refvalue')
    }

    if (callback) {
      console.log(' -- callback in signup form')
      console.log(' -- callback', callback)
      return signUp(email, password, profile, callback)
    }

    return signUp(email, password, profile)
  })

  return (
    <Form
      onSubmit={onSubmit}
      errors={errors}
      selectedDate={selectedDate}
      setSelectedDate={setSelectedDate}
    />
  )
}

export default SignUpForm
