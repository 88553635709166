import { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Box, Hidden } from '@mui/material'
import TextField from '../stories/TextField'
import Button from '../stories/Button'
import Typography from '../stories/Typography'
import Icon from '../stories/Icons'

import useChat from '@app/hooks/useChat'
import { useCurrentProfile, useCurrentUser } from '@app/context/CurrentUser'
import { MessageInOrders as MessageIn } from './Chats/MessageIn'
import { MessageOutOrders as MessageOut } from './Chats/MessageOut'
import { display, maxWidth } from '@mui/system'

const OrderChatView = ({ chatID, order, color = null, variant = null }) => {
  console.log(order, 'order')
  const { status } = order
  const [displayLinks, setDisplayLinks] = useState(false)
  const [fullScreen, setFullScreen] = useState(null)

  const [chatIDState, setChatID] = useState(null)

  const [messages, setMessages] = useState(null)

  const unsubscribe = useRef(null)

  const [currentImage, setCurrentImage] = useState(null)
  const [currentFile, setCurrentFile] = useState(null)
  const [messageType, setMessageType] = useState(null)

  const desktopFileRef = useRef()
  const mobileFileRef = useRef()

  const desktopImageRef = useRef()
  const mobileImageRef = useRef()

  const profile = useCurrentProfile()
  const { uid } = useCurrentUser()
  const { subscribeToChat, sendMessage } = useChat()

  const handleImageChange = (e) => {
    setCurrentFile(null) // was 0
    setCurrentImage(null)
    setCurrentImage({
      file: e.target.files[0],
      url: URL.createObjectURL(e.target.files[0]),
    })
  }

  const MessageForm = ({ children }) => {
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault()
          setDisplayLinks(displayLinks && !displayLinks)
          const chatMessageBoxValue = document.getElementById('orderChatMessageBox').value
          sendMessage({
            chatID: chatIDState,
            message: chatMessageBoxValue,
            profile,
            currentFile,
            currentImage,
            fileType: messageType,
          })
          document.getElementById('orderChatMessageBox').value = ''
          setCurrentFile(null)
          setCurrentImage(null)
          setMessageType(null)
        }}
        style={{
          width: '100%',
        }}
      >
        {children}
      </form>
    )
  }

  MessageForm.propTypes = {
    children: PropTypes.node.isRequired,
  }

  useEffect(() => {
    if (!chatIDState) {
      return undefined
    }

    console.log('chatIDState', chatIDState)

    unsubscribe.current = subscribeToChat({
      chatID: chatIDState,
      callback: setMessages,
    })

    return unsubscribe.current
  }, [chatIDState])

  useEffect(() => {
    if (window?.Intercom) {
      window.Intercom('update', { hide_default_launcher: fullScreen || false })
    }
  }, [fullScreen])

  const handleChatOpen = ({ chatId }) => {
    document.body.style.overflow = 'hidden'

    setMessages(null)
    if (unsubscribe.current) {
      unsubscribe.current()
    }

    setChatID(chatId)
    setFullScreen(true)
  }

  const handleChatCleanUp = () => {
    document.body.style.overflow = 'auto'
    if (unsubscribe.current) {
      unsubscribe.current()
    }
    setMessages(null)
    setChatID(null)
    setDisplayLinks(false)
    setCurrentFile(null)
    setCurrentImage(null)
    setFullScreen(false)
  }

  const handleImageClick = () => {
    if (currentImage) {
      setCurrentImage(null)
    } else {
      desktopImageRef.current.click()
    }
  }

  const handleFileClick = () => {
    if (currentFile) {
      setCurrentFile(null)
    } else {
      desktopFileRef.current.click()
    }
  }

  return (
    <>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {variant === 'bundle' ? (
          <Button
            fullWidth
            onClick={() => {
              handleChatOpen({ chatId: `${chatID}-bundle` })
            }}
            variant='outlined'
            severity='danger'
            size='small'
            style={{
              color: '#fff',
              backgroundColor: color || `${status === 'paid' ? '#009124' : '#d32f2f'}`,
              border: 'none',
            }}
          >
            <Typography variant='body1' family='poppins' align='center'>
              Bundle Chat
            </Typography>
          </Button>
        ) : (
          <>
            <Button
              fullWidth
              onClick={() => {
                handleChatOpen({ chatId: `${chatID}-group` })
              }}
              variant='outlined'
              severity='danger'
              size='small'
              style={{
                color: '#fff',
                backgroundColor: color || `${status === 'paid' ? '#009124' : '#d32f2f'}`,
                border: 'none',
              }}
            >
              <Typography variant='body1' family='poppins' align='center'>
                Providers and Patient Chat
              </Typography>
            </Button>
            {profile.isProvider && (
              <Button
                fullWidth
                onClick={() => {
                  handleChatOpen({ chatId: `${chatID}-clinician` })
                }}
                variant='outlined'
                severity='danger'
                size='small'
                style={{
                  color: '#fff',
                  backgroundColor: color || `${status === 'paid' ? '#009124' : '#d32f2f'}`,
                  border: 'none',
                }}
              >
                <Typography variant='body1' family='poppins' align='center'>
                  Provider Only Chat
                </Typography>
              </Button>
            )}
          </>
        )}
      </Box>
      {fullScreen && (
        <>
          <Hidden mdDown>
            <Box
              style={{
                position: 'fixed',
                zIndex: '9999',
                width: '100vw',
                height: '100vh',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                top: '0px',
                left: '0px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box
                style={{
                  position: 'relative',
                  zIndex: '10000',
                  maxWidth: '600px',
                  width: '40vw',
                  height: '80vh',
                  backgroundColor: 'white',
                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
                  borderRadius: '4px',
                }}
              >
                <Box
                  style={{
                    position: 'absolute',
                    zIndex: '10002',
                    width: '100%',
                    height: '50px',
                    backgroundColor: '#652d92',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    padding: '0px 12px',
                    borderRadius: '4px 4px 0px 0px',
                  }}
                >
                  {variant === 'bundle' ? (
                    <Typography
                      variant='h4'
                      family='poppins'
                      style={{
                        color: '#fff',
                        marginBottom: '0px',
                      }}
                    >
                      Bundle Chat
                    </Typography>
                  ) : (
                    <Typography
                      variant='h4'
                      family='poppins'
                      style={{
                        color: '#fff',
                        marginBottom: '0px',
                      }}
                    >
                      {chatIDState.includes('clinician')
                        ? 'Provider Only Chat'
                        : 'Providers and Patient Chat'}
                    </Typography>
                  )}
                  <Button
                    onClick={handleChatCleanUp}
                    variant='outlined'
                    size='small'
                    style={{
                      backgroundColor: '#652d92',
                      position: 'absolute',
                      right: '12px',
                      minHeight: '40px',
                      minWidth: '40px',
                      maxWidth: '40px',
                      borderRadius: '50%',
                      border: 'none',
                      boxShadow: 'none',
                      color: '#fff',
                      transform: 'rotate(180deg)',
                    }}
                  >
                    <Icon name='exitToApp' size='large' />
                  </Button>
                </Box>
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'flex-end',
                    flexDirection: 'column',
                    height: '100%',
                    border: '1px solid rgba(0, 0, 0, 0.12)',
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
                    borderRadius: '4px',
                  }}
                >
                  <Box
                    style={{
                      position: 'relative',
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column-reverse',
                      alignItems: 'flex-end',
                      overflow: 'scroll',
                      borderRadius: '4px',
                      marginTop: '55px',
                      padding: '4px 10px',
                    }}
                  >
                    {messages &&
                      messages.map((message, index) => {
                        if (message.sentById === uid) {
                          return (
                            <MessageOut
                              key={index}
                              author={message.sentByName}
                              content={message.content}
                              sendTime={message.sentAt}
                              date={message.date}
                            />
                          )
                        }
                        return (
                          <MessageIn
                            key={index}
                            author={message.sentByName}
                            content={message.content}
                            sendTime={message.sentAt}
                            date={message.date}
                          />
                        )
                      })}
                  </Box>
                  <Box
                    style={{
                      width: '100%',
                      display: 'flex',
                      position: 'relative',
                      justifyContent: 'space-around',
                      alignItems: 'flex-end',
                      marginTop: '0px',
                      borderTop: 'solid #eee 1px',
                      padding: '10px',
                    }}
                  >
                    <Box
                      style={{
                        position: 'absolute',
                        zIndex: '2',
                        left: '10px',
                        bottom: '10px',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Button
                        variant='outlined'
                        size='small'
                        type='button'
                        onClick={() => {
                          setDisplayLinks(!displayLinks)
                        }}
                        style={{
                          borderRadius: '50%',
                          minHeight: '40px',
                          minWidth: '40px',
                          maxWidth: '40px',
                          boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2)',
                        }}
                      >
                        {!displayLinks && <Icon name='add' color='primary' />}
                        {displayLinks && <Icon name='openArrow' color='primary' />}
                      </Button>
                      {displayLinks && (
                        <Box
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            position: 'absolute',
                            left: '0px',
                            bottom: '60px',
                            maxWidth: '300px',
                            minWidth: 'fit-content',
                          }}
                        >
                          {/* <Box>
                        <Button
                          variant='outlined'
                          size='small'
                          onClick={() => {
                            window.location.href = `/videochat?id=${chatIDState}`
                          }}
                          style={{
                            borderRadius: '50%',
                            minHeight: '40px',
                            minWidth: '40px',
                            maxWidth: '40px',
                            boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2)'
                          }}
                        >
                          <Icon name='videoCall' color='white' />
                        </Button>
                      </Box> */}
                          <Box
                            style={{
                              position: 'relative',
                            }}
                          >
                            <Button
                              variant='outlined'
                              size='small'
                              onClick={() => {
                                handleImageClick()
                              }}
                              style={{
                                marginTop: '8px',
                                borderRadius: '50%',
                                minHeight: '40px',
                                minWidth: '40px',
                                maxWidth: '40px',
                                boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2)',
                              }}
                            >
                              {!currentImage && (
                                <input
                                  hidden
                                  type='file'
                                  ref={desktopImageRef}
                                  name='chatImageInput'
                                  accept='image/png, image/gif, image/jpeg'
                                  onChange={(e) => {
                                    setMessageType('image')
                                    handleImageChange(e)
                                  }}
                                />
                              )}
                              <Icon name={currentImage ? 'close' : 'addPhoto'} />
                            </Button>
                            {currentImage && (
                              <Box
                                style={{
                                  position: 'absolute',
                                  bottom: '-5px',
                                  left: '50px',
                                }}
                              >
                                <img
                                  src={currentImage.url}
                                  alt='preview'
                                  style={{
                                    border: 'solid 2px #652d92',
                                    borderRadius: '4px',
                                    objectFit: 'contain',
                                    maxWidth: '200px',
                                    maxHeight: '200px',
                                    boxShadow: '0px 0px 0px 1px rgba(255, 255, 255, 0.5)',
                                  }}
                                />
                              </Box>
                            )}
                          </Box>
                          <Box
                            style={{
                              marginTop: '8px',
                              maxHeight: '40px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              backgroundColor: '#fff',
                              boxShadow: 'inset 0px 0px 0px 1px rgba(101, 45, 146, 0.5)',
                              borderRadius: '20px',
                            }}
                          >
                            <Button
                              variant='outlined'
                              size='small'
                              onClick={() => {
                                handleFileClick()
                              }}
                              style={{
                                borderRadius: '50%',
                                minHeight: '40px',
                                minWidth: '40px',
                                maxWidth: '40px',
                                boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2)',
                              }}
                            >
                              {!currentFile && (
                                <input
                                  hidden
                                  type='file'
                                  ref={desktopFileRef}
                                  onChange={(e) => {
                                    setCurrentImage(null)
                                    setMessageType('file')
                                    setCurrentFile(e.target.files[0])
                                  }}
                                />
                              )}
                              <Icon name={currentFile ? 'close' : 'attachFile'} />
                            </Button>
                            {currentFile && (
                              <Box
                                style={{
                                  whiteSpace: 'nowrap',
                                  maxWidth: '250px',
                                  padding: '0px 10px',
                                }}
                              >
                                <Typography
                                  variant='body1'
                                  color='primary'
                                  family='poppins'
                                  style={{
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    width: '100%',
                                    maxHeight: '24px',
                                  }}
                                >
                                  {currentFile.name}
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        </Box>
                      )}
                    </Box>
                    <MessageForm>
                      <Box
                        style={{
                          width: '100%',
                        }}
                      >
                        <TextField
                          id='orderChatMessageBox'
                          autoFocus
                          name='message'
                          size='small'
                          margin='none'
                          placeholder='Message...'
                          fullWidth
                          multiline
                          style={{
                            padding: '0px 50px',
                          }}
                        />
                      </Box>
                      <Button
                        size='small'
                        type='submit'
                        style={{
                          color: '#fff',
                          position: 'absolute',
                          right: '10px',
                          bottom: '10px',
                          borderRadius: '50%',
                          minHeight: '40px',
                          minWidth: '40px',
                          maxWidth: '40px',
                          marginLeft: '16px',
                          boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2)',
                        }}
                      >
                        <Icon name='send' color='inherit' />
                      </Button>
                    </MessageForm>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Hidden>
          <Hidden mdUp>
            <Box
              style={{
                position: 'fixed',
                zIndex: '10000',
                width: '100vw',
                height: '100vh',
                backgroundColor: 'white',
                top: '0px',
                left: '0px',
              }}
            >
              <Box
                style={{
                  position: 'fixed',
                  zIndex: '10001',
                  display: 'flex',
                  alignItems: 'center',
                  width: '100vw',
                  padding: '3vw',
                  paddingBottom: '0px',
                  marginBottom: '0px',
                  backgroundColor: '#652d92',
                }}
              >
                {variant === 'bundle' ? (
                  <Typography
                    variant='h3'
                    family='poppins'
                    style={{
                      color: '#fff',
                      marginTop: '0px',
                    }}
                  >
                    Bundle Chat
                  </Typography>
                ) : (
                  <Typography
                    variant='h3'
                    family='poppins'
                    style={{
                      color: '#fff',
                      marginTop: '0px',
                    }}
                  >
                    {chatIDState.includes('clinician')
                      ? 'Provider Only Chat'
                      : 'Provider and Patient Chat'}
                  </Typography>
                )}
              </Box>
              <Button
                onClick={handleChatCleanUp}
                variant='outlined'
                size='small'
                style={{
                  position: 'fixed',
                  zIndex: '10001',
                  minHeight: '32px',
                  minWidth: '32px',
                  maxWidth: '32px',
                  borderRadius: '50%',
                  border: 'none',
                  boxShadow: 'none',
                  top: '3vw',
                  right: '3vw',
                  transform: 'rotate(180deg)',
                  backgroundColor: '#652d92',
                  color: '#fff',
                }}
              >
                <Icon name='exitToApp' size='large' />
              </Button>
              <Box>
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'flex-start',
                    flexDirection: 'column-reverse',
                    margin: '0px',
                    height: '100vh',
                    maxHeight: '100vh',
                    marginTop: '55px',
                    width: '100vw',
                    padding: '10px',
                    paddingBottom: '120px',
                    overflowY: 'scroll',
                  }}
                >
                  {messages &&
                    messages.map((message, index) => {
                      if (message.sentById === uid) {
                        return (
                          <MessageOut
                            key={index}
                            author={message.sentByName}
                            content={message.content}
                            sendTime={message.sentAt}
                            date={message.date}
                          />
                        )
                      }
                      return (
                        <MessageIn
                          key={index}
                          author={message.sentByName}
                          content={message.content}
                          sendTime={message.sentAt}
                          date={message.date}
                        />
                      )
                    })}
                </Box>
              </Box>
              <Box
                style={{
                  position: 'fixed',
                  zIndex: '10001',
                  bottom: '0px',
                  left: '0px',
                  backgroundColor: '#fff',
                  paddingBottom: '10px',
                  width: '100vw',
                }}
              >
                <Box
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'flex-end',
                  }}
                >
                  <Box
                    style={{
                      position: 'relative',
                      width: '100vw',
                      borderTop: 'solid #eee 1px',
                      paddingTop: '12px',
                      backgroundColor: '#fff',
                    }}
                  >
                    <Box
                      style={{
                        position: 'absolute',
                        zIndex: '20000',
                        left: '8px',
                        bottom: '0px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Button
                        variant='outlined'
                        size='small'
                        type='button'
                        onClick={() => {
                          setDisplayLinks(!displayLinks)
                        }}
                        style={{
                          borderRadius: '50%',
                          minHeight: '40px',
                          minWidth: '40px',
                          maxWidth: '40px',
                          boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2)',
                        }}
                      >
                        {!displayLinks && <Icon name='add' color='primary' />}
                        {displayLinks && <Icon name='openArrow' color='primary' />}
                      </Button>
                      {displayLinks && (
                        <Box
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            position: 'fixed',
                            left: '8px',
                            bottom: '72px',
                          }}
                        >
                          {/* <Box>
                          <Button
                            variant='outlined'
                            size='small'
                            onClick={() => {
                              setDisplayLinks(!displayLinks)
                              window.location.href = `/videochat?id=${chatIDState}`
                            }}
                            style={{
                              borderRadius: '50%',
                              minHeight: '40px',
                              minWidth: '40px',
                              maxWidth: '40px',
                              boxShadow:
                                '0px 0px 15px 3px rgba(0, 0, 0, 0.25)'
                            }}
                          >
                            <Icon name='videoCall'/>
                          </Button>
                        </Box> */}
                          <Box
                            style={{
                              position: 'relative',
                            }}
                          >
                            <Button
                              variant='outlined'
                              size='small'
                              onClick={() => {
                                mobileImageRef.current.click()
                              }}
                              style={{
                                marginTop: '8px',
                                borderRadius: '50%',
                                minHeight: '40px',
                                minWidth: '40px',
                                maxWidth: '40px',
                                boxShadow: '0px 0px 15px 3px rgba(0, 0, 0, 0.25)',
                              }}
                            >
                              <input
                                hidden
                                type='file'
                                ref={mobileImageRef}
                                name='chatImageInput'
                                accept='image/png, image/gif, image/jpeg'
                                onChange={(e) => {
                                  setMessageType('image')
                                  handleImageChange(e)
                                }}
                              />
                              <Icon name='addPhoto' />
                            </Button>
                            {currentImage && (
                              <Box
                                style={{
                                  position: 'absolute',
                                  bottom: '-5px',
                                  left: '50px',
                                }}
                              >
                                <img
                                  src={currentImage.url}
                                  alt='preview'
                                  style={{
                                    border: 'solid 2px #652d92',
                                    borderRadius: '4px',
                                    objectFit: 'contain',
                                    maxWidth: '200px',
                                    maxHeight: '200px',
                                    boxShadow: '0px 0px 0px 1px rgba(255, 255, 255, 0.5)',
                                  }}
                                />
                              </Box>
                            )}
                          </Box>
                          <Box
                            style={{
                              marginTop: '8px',
                              maxHeight: '40px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              backgroundColor: '#fff',
                              boxShadow: 'inset 0px 0px 0px 1px rgba(101, 45, 146, 0.5)',
                              borderRadius: '20px',
                            }}
                          >
                            <Button
                              variant='outlined'
                              size='small'
                              onClick={() => {
                                mobileFileRef.current.click()
                              }}
                              style={{
                                borderRadius: '50%',
                                minHeight: '40px',
                                minWidth: '40px',
                                maxWidth: '40px',
                                boxShadow: '0px 0px 15px 3px rgba(0, 0, 0, 0.25)',
                              }}
                            >
                              <input
                                hidden
                                type='file'
                                ref={mobileFileRef}
                                onChange={(e) => {
                                  setCurrentImage(null)
                                  setMessageType('file')
                                  setCurrentFile(e.target.files[0])
                                }}
                              />
                              <Icon name='attachFile' />
                            </Button>
                            {currentFile && (
                              <Box
                                style={{
                                  whiteSpace: 'nowrap',
                                  maxWidth: '250px',
                                  padding: '0px 10px',
                                }}
                              >
                                <Typography
                                  variant='body1'
                                  color='primary'
                                  family='poppins'
                                  style={{
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    width: '100%',
                                    maxHeight: '24px',
                                  }}
                                >
                                  {currentFile.name}
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        </Box>
                      )}
                    </Box>
                    <MessageForm>
                      <Box
                        style={{
                          width: '100vw',
                          backgroundColor: '#fff',
                        }}
                      >
                        <TextField
                          id='orderChatMessageBox'
                          autoFocus
                          name='message'
                          size='small'
                          margin='none'
                          placeholder='Message...'
                          fullWidth
                          multiline
                          style={{
                            padding: '0px 56px',
                          }}
                        />
                      </Box>
                      <Button
                        size='small'
                        type='submit'
                        style={{
                          color: '#fff',
                          position: 'absolute',
                          right: '8px',
                          bottom: '0px',
                          borderRadius: '50%',
                          minHeight: '40px',
                          minWidth: '40px',
                          maxWidth: '40px',
                          marginLeft: '16px',
                          boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2)',
                        }}
                      >
                        <Icon name='send' color='inherit' />
                      </Button>
                    </MessageForm>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Hidden>
        </>
      )}
    </>
  )
}

OrderChatView.propTypes = {
  chatID: PropTypes.string.isRequired,
  order: PropTypes.shape({
    status: PropTypes.string,
  }).isRequired,
  color: PropTypes.string,
  variant: PropTypes.string,
}

export default OrderChatView
