import { useCallback } from 'react'
import { useDebounce } from '@react-hook/debounce'

function useInput(defaultValue) {
  const [query, setQuery] = useDebounce(defaultValue, 200)

  const changeQuery = useCallback(
    (event) => {
      setQuery(event.target.value)
    },
    [setQuery],
  )

  return [query, changeQuery]
}

export default useInput
