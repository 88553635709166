import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import Grow from '@mui/material/Grow'
import IconButton from '@app/stories/IconButton'
import Icon from '@app/stories/Icons'
import locationPin from '../assets/images/locationPin.svg'
import locationPinBundle from '../assets/images/locationPinBundle.svg'
import locationPinOutOfNetwork from '../assets/images/locationPinOutOfNetwork.svg'
import Button from '@app/stories/Button'
import Typography from '@app/stories/Typography'
import FormattedText from '@app/stories/FormatFromDB'
import { useMapEffects } from '@app/context/MapEffects'
import Divider from '@app/stories/Divider'

import { useWhiteLabelingContext } from '@app/context/WhiteLabelingContext'
import { createLightBackgroundColor, lightenHexColor } from '@app/utils/hexcodeUtils'

const LocationMarker = ({
  data,
  locationID,
  slug = '',
  locationName = '',
  onClick = () => {},
  lat = 0,
  lng = 0,
  variant = 'default',
  outOfNetwork = false,
  address = '',
  city = '',
  state = '',
  hours = '',
  services = [],
  onPlanOnly = false,
  handleSelectLocation,
  handleSwap,
  activeStep,
  fromBundle,
}) => {
  const _geoloc = [{ lat, lng }]
  const { hovered, handleHover, clicked, handleClick } = useMapEffects()

  const { planColorPrimary } = useWhiteLabelingContext()

  const lightenedColor = lightenHexColor(planColorPrimary, 30)
  const lightBackgroundColor = createLightBackgroundColor(planColorPrimary)

  useEffect(() => {
    if (clicked === locationID && handleSelectLocation) {
      handleSelectLocation(data)
    }
  }, [clicked, locationID, handleSelectLocation, data])

  const CustomPlanLocationMarker = () => {
    return (
      <svg
        width='5.6444445mm'
        height='9.847393mm'
        viewBox='0 0 20 34.892337'
        id='svg3455'
        version='1.1'
      >
        <g id='layer1' transform='translate(-814.59595,-274.38623)'>
          <g id='g3477' transform='matrix(1.1855854,0,0,1.1855854,-151.17715,-57.3976)'>
            <path
              id='path4337-3'
              d='m 817.11249,282.97118 c -1.25816,1.34277 -2.04623,3.29881 -2.01563,5.13867 0.0639,3.84476 1.79693,5.3002 4.56836,10.59179 0.99832,2.32851 2.04027,4.79237 3.03125,8.87305 0.13772,0.60193 0.27203,1.16104 0.33416,1.20948 0.0621,0.0485 0.19644,-0.51262 0.33416,-1.11455 0.99098,-4.08068 2.03293,-6.54258 3.03125,-8.87109 2.77143,-5.29159 4.50444,-6.74704 4.56836,-10.5918 0.0306,-1.83986 -0.75942,-3.79785 -2.01758,-5.14062 -1.43724,-1.53389 -3.60504,-2.66908 -5.91619,-2.71655 -2.31115,-0.0475 -4.4809,1.08773 -5.91814,2.62162 z'
              style={{
                display: 'inline',
                opacity: '1',
                fill: lightenedColor || '#b485d9',
                fillOpacity: '1',
                stroke: planColorPrimary,
                strokeWidth: '1',
                strokeMiterlimit: '4',
                strokeDasharray: 'none',
                strokeOpacity: '1',
              }}
            />
            <circle
              r='3.0355'
              cy='288.25278'
              cx='823.03064'
              id='path3049'
              style={{
                display: 'inline',
                opacity: '1',
                fill: planColorPrimary,
                fillOpacity: '1',
                strokeWidth: '0',
              }}
            />
          </g>
        </g>
      </svg>
    )
  }

  return (
    <>
      {variant === 'clinicPage' && (
        <Box
          style={{
            width: '0px',
            height: '0px',
            display: onPlanOnly && outOfNetwork ? 'none' : 'block',
          }}
        >
          {(hovered === locationID || hovered?.includes(locationID)) && (
            <Grow in timeout={{ enter: 250, exit: 250 }}>
              <Box
                position='absolute'
                zIndex='1000'
                top={outOfNetwork ? '-78px' : '-58px'}
                left='8px'
                bgcolor={outOfNetwork ? '#bfcbf8' : lightBackgroundColor || '#f0e7f7'}
                border={outOfNetwork ? '1px solid #325ae8' : `1px solid ${planColorPrimary}`}
                borderRadius='5px 5px 5px 0'
                padding='4px'
                whiteSpace='nowrap'
                color={outOfNetwork ? '#325ae8' : planColorPrimary}
              >
                {outOfNetwork && (
                  <Typography
                    variant='body1'
                    color='inherit'
                    weight='medium'
                    family='poppins'
                    style={{
                      margin: '0px',
                      fontSize: '12px',
                    }}
                  >
                    Off Plan
                  </Typography>
                )}
                <Typography
                  variant='h5'
                  color='inherit'
                  weight='medium'
                  family='poppins'
                  style={{
                    margin: '0px',
                    textTransform: 'capitalize',
                  }}
                >
                  {locationName.toLowerCase()}
                </Typography>
              </Box>
            </Grow>
          )}
          {clicked === locationID && (
            <Grow in={clicked === locationID} timeout={{ enter: 500, exit: 500 }}>
              <Box
                position='absolute'
                zIndex='1000'
                top='-58px'
                left='20px'
                bgcolor={outOfNetwork ? '#bfcbf8' : lightBackgroundColor || '#f0e7f7'}
                borderRadius='5px'
                padding={outOfNetwork ? '8px 12px 12px 12px' : '12px'}
                border={outOfNetwork ? '1px solid #325ae8' : `1px solid ${planColorPrimary}`}
                whiteSpace='nowrap'
                color={outOfNetwork ? '#325ae8' : planColorPrimary}
              >
                <Box
                  position='absolute'
                  top='-12px'
                  right='-12px'
                  zIndex='1001'
                  bgcolor={outOfNetwork ? '#bfcbf8' : lightBackgroundColor || '#f0e7f7'}
                  border={outOfNetwork ? '1px solid #325ae8' : `1px solid ${planColorPrimary}`}
                  borderRadius='50%'
                  color={outOfNetwork ? '#325ae8' : planColorPrimary}
                >
                  <Button
                    variant='unstyled'
                    style={{
                      padding: '4px',
                      margin: '0px',
                      minWidth: '0px',
                    }}
                    size='small'
                    onClick={() => {
                      handleHover(false)
                      handleClick(false)
                    }}
                  >
                    <Icon name='close' color='inherit' />
                  </Button>
                </Box>
                {outOfNetwork && (
                  <Typography
                    variant='body1'
                    color='inherit'
                    weight='medium'
                    family='poppins'
                    style={{
                      margin: '0px',
                      fontSize: '12px',
                    }}
                  >
                    Off Plan
                  </Typography>
                )}
                <Typography
                  variant='h5'
                  color='inherit'
                  weight='medium'
                  family='poppins'
                  style={{
                    margin: '0px 0px 4px 0px',
                    textTransform: 'capitalize',
                  }}
                >
                  {locationName.toLowerCase()}
                </Typography>
                {address && (
                  <Box paddingLeft='4px'>
                    <Typography
                      variant='body1'
                      color='inherit'
                      weight='light'
                      family='poppins'
                      style={{
                        margin: '0px',
                        fontSize: '14px',
                        lineHeight: '120%',
                      }}
                    >
                      {address}
                    </Typography>
                    <Typography
                      variant='body1'
                      color={outOfNetwork ? 'inherit' : 'primary'}
                      weight='light'
                      family='poppins'
                      style={{
                        margin: '0px',
                        fontSize: '14px',
                        lineHeight: '120%',
                      }}
                    >
                      {city}, {state}
                    </Typography>
                  </Box>
                )}
                {hours && (
                  <>
                    <Box>
                      <Divider
                        style={{
                          margin: '4px 0px',
                          backgroundColor: `${outOfNetwork ? 'rgb(50, 90, 232)' : planColorPrimary}`,
                        }}
                      />
                    </Box>
                    <Typography
                      variant='body1'
                      color='inherit'
                      weight='medium'
                      family='poppins'
                      style={{ margin: '0px' }}
                    >
                      Hours
                    </Typography>
                    <Box paddingLeft='4px'>
                      <FormattedText
                        text={hours}
                        textType='body1'
                        weight='light'
                        align='left'
                        color={outOfNetwork ? 'inherit' : planColorPrimary}
                        mt={0}
                        style={{
                          textTransform: 'capitalize',
                          fontFamily: 'Poppins',
                          fontSize: '14px',
                        }}
                      />
                    </Box>
                  </>
                )}
                {services && (
                  <>
                    <Box>
                      <Divider
                        style={{
                          margin: '4px 0px',
                          backgroundColor: `${outOfNetwork ? 'rgb(50, 90, 232)' : planColorPrimary}`,
                        }}
                      />
                    </Box>
                    <Typography
                      variant='body1'
                      color='inherit'
                      weight='medium'
                      family='poppins'
                      style={{ margin: '0px' }}
                    >
                      Services
                    </Typography>
                    <Box paddingLeft='4px'>
                      {services.map((service) => {
                        const { name, price, id } = service
                        return (
                          <Box key={id}>
                            <Typography
                              variant='body1'
                              color='inherit'
                              weight='light'
                              family='poppins'
                              style={{
                                margin: '0px',
                                fontSize: '14px',
                              }}
                            >
                              {name} - ${price}
                            </Typography>
                          </Box>
                        )
                      })}
                    </Box>
                  </>
                )}
              </Box>
            </Grow>
          )}
          <Box
            onMouseEnter={() => handleHover(true, [locationID])}
            onMouseLeave={() => handleHover(false)}
            onMouseDown={() => {
              handleHover(false)
              handleClick(true, locationID)
            }}
            style={{
              position: 'absolute',
              top: '-36px',
              left: '-10px',
              cursor: 'pointer',
            }}
          >
            {outOfNetwork ? (
              <img src={locationPinOutOfNetwork} alt='location pin' style={{ cursor: 'pointer' }} />
            ) : fromBundle ? (
              <img src={locationPinBundle} alt='location pin' style={{ cursor: 'pointer' }} />
            ) : (
              <CustomPlanLocationMarker />
            )}
          </Box>
        </Box>
      )}
      {variant === 'pharmacyPage' && (
        <Box
          style={{
            position: 'relative',
            width: '0px',
            height: '0px',
          }}
        >
          {(hovered === locationID || hovered?.includes(locationID)) && (
            <Grow in timeout={{ enter: 250, exit: 250 }}>
              <Box
                position='absolute'
                zIndex='1000'
                top='-58px'
                left='8px'
                bgcolor='#f0e7f7'
                borderRadius='5px 5px 5px 0'
                padding='4px'
                boxShadow='0px 0px 0px 2px rgba(0, 0, 0, 0.1)'
                whiteSpace='nowrap'
                color='#325ae8'
              >
                <Typography
                  variant='h5'
                  color='primary'
                  weight='medium'
                  family='poppins'
                  style={{
                    margin: '0px',
                    textTransform: 'capitalize',
                  }}
                >
                  {locationName.toLowerCase()}
                </Typography>
              </Box>
            </Grow>
          )}
          {clicked === locationID && (
            <Grow in={clicked === locationID} timeout={{ enter: 500, exit: 500 }}>
              <Box
                position='absolute'
                zIndex='1000'
                top='-58px'
                left='20px'
                bgcolor='#f0e7f7'
                borderRadius='5px'
                padding='12px'
                boxShadow='0px 0px 0px 2px rgba(0, 0, 0, 0.1)'
                whiteSpace='nowrap'
                color='#325ae8'
              >
                <Box
                  position='absolute'
                  top='-12px'
                  right='-12px'
                  zIndex='1001'
                  bgcolor='#f0e7f7'
                  boxShadow='0px 0px 0px 2px rgba(0, 0, 0, 0.1)'
                  borderRadius='50%'
                >
                  <IconButton
                    size='small'
                    style={{
                      color: '#325ae8',
                    }}
                    onClick={() => {
                      handleHover(false)
                      handleClick(false)
                    }}
                  >
                    <Icon name='close' color='primary' />
                  </IconButton>
                </Box>
                <Typography
                  variant='h5'
                  color='primary'
                  weight='medium'
                  family='poppins'
                  style={{
                    margin: '0px 0px 4px 0px',
                    textTransform: 'capitalize',
                  }}
                >
                  {locationName.toLowerCase()}
                </Typography>
                <Box paddingLeft='4px'>
                  <Typography
                    variant='body1'
                    color='primary'
                    weight='light'
                    family='poppins'
                    style={{
                      margin: '0px',
                      fontSize: '14px',
                      lineHeight: '120%',
                      textTransform: 'capitalize',
                    }}
                  >
                    {address}
                  </Typography>
                  <Typography
                    variant='body1'
                    color='primary'
                    weight='light'
                    family='poppins'
                    style={{
                      margin: '0px',
                      fontSize: '14px',
                      lineHeight: '120%',
                      textTransform: 'capitalize',
                    }}
                  >
                    {city}, {state}
                  </Typography>
                </Box>
                {hours && (
                  <>
                    <Box>
                      <Divider
                        style={{
                          margin: '4px 0px',
                          backgroundColor: '#652d92',
                        }}
                      />
                    </Box>
                    <Typography
                      variant='body1'
                      color='primary'
                      weight='medium'
                      family='poppins'
                      style={{ margin: '0px' }}
                    >
                      Hours
                    </Typography>
                    <Box paddingLeft='4px'>
                      <FormattedText
                        text={hours}
                        textType='body1'
                        weight='light'
                        align='left'
                        color='primary'
                        mt={0}
                        style={{
                          textTransform: 'capitalize',
                          fontFamily: 'Poppins',
                          fontSize: '14px',
                        }}
                      />
                    </Box>
                  </>
                )}
                <Box paddingTop='8px'>
                  <Button
                    variant='contained'
                    size='small'
                    style={{
                      backgroundColor: '#7004c6',
                      borderRadius: '16px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      handleSwap(activeStep + 1)
                    }}
                  >
                    <Typography variant='body1' weight='light' family='poppins'>
                      Select this Pharmacy
                    </Typography>
                  </Button>
                </Box>
              </Box>
            </Grow>
          )}
          <Box
            onMouseEnter={() => handleHover(true, [locationID])}
            onMouseLeave={() => handleHover(false)}
            onMouseDown={() => {
              handleHover(false)
              handleClick(true, locationID)
            }}
            style={{
              position: 'absolute',
              top: '-36px',
              left: '-10px',
              cursor: 'pointer',
            }}
          >
            <img src={locationPin} alt='location pin' style={{ cursor: 'pointer' }} />
          </Box>
        </Box>
      )}
      {variant === 'default' && (
        <Box
          style={{
            position: 'absolute',
            top: '-21px',
          }}
        >
          <Button
            variant='blank'
            onClick={() => {
              onClick({ slug, locationName, _geoloc })
            }}
            style={{
              width: '0px',
              height: '0px',
              minWidth: '0px',
              padding: '0px',
              margin: '0px',
            }}
          >
            <img
              src={outOfNetwork ? locationPinOutOfNetwork : locationPin}
              alt='location pin'
              style={{ cursor: 'pointer' }}
            />
          </Button>
        </Box>
      )}
    </>
  )
}

LocationMarker.propTypes = {
  locationID: PropTypes.string.isRequired,
  locationName: PropTypes.string,
  slug: PropTypes.string,
  onClick: PropTypes.func,
  lat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lng: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variant: PropTypes.string,
  outOfNetwork: PropTypes.bool,
  address: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  hours: PropTypes.string,
  services: PropTypes.array,
  onPlanOnly: PropTypes.bool,
}

export default LocationMarker
