import PropTypes from 'prop-types'
import VoucherUpcomingAppointment from './VoucherUpcomingAppointment'
import ResponsiveVoucherRow from './ResponsiveVoucherRow'
import ResponsiveExternalClaimRow from './ResponsiveExternalClaimRow'
import ExternalClaimRow from './ExternalClaimRow.jsx'
import { Toggle } from '@app/hooks/useToggleOpen'
import VouchersBundle from './VouchersBundle'
import VouchersIndividual from './VouchersIndividual'
import ResponsiveTable from './ResponsiveTable'

const HEADERS = [
  { id: 'info', label: 'Info', align: 'center', type: 'IconOpenClose', user: 'consumer' },
  { id: 'organization', label: 'Clinic', align: 'left', type: 'Link' },
  { id: 'service', label: 'Treatment', align: 'left', type: 'Text' },
  { id: 'appointmentDate', label: 'Appt Date', align: 'left', type: 'Date' },
  { id: 'status', label: 'Status', align: 'center', type: 'StatusChip' },
  { id: 'action', label: 'Actions', align: 'center', type: 'CopyLink' },
]

const HEADERS_BUNDLE = [
  { id: 'info', label: 'Info', align: 'center', type: 'Detail' },
  { id: 'organization', label: 'Clinic', align: 'left', type: 'Link' },
  { id: 'service', label: 'Treatment', align: 'left', type: 'Text' },
  { id: 'appointmentDate', label: 'Appt Date', align: 'left', type: 'Date' },
  { id: 'status', label: 'Status', align: 'center', type: 'StatusChip' },
]

// TODO - Update based on requests from Sidney
const HEADERS_EXTERNAL_CLAIM = [
  { id: 'info', label: 'Info', align: 'center', type: 'IconOpenClose', user: 'consumer' },
  { id: 'organization', label: 'Clinic', align: 'left', type: 'Link' },
  { id: 'service', label: 'Treatment', align: 'left', type: 'Text' },
  { id: 'appointmentDate', label: 'Appt Date', align: 'left', type: 'Date' },
  { id: 'status', label: 'Status', align: 'center', type: 'StatusChip' },
  { id: 'action', label: 'Actions', align: 'center', type: 'CopyLink' },
]

const PatientVouchers = ({ rows = [] }) => (
  <>
    <VoucherUpcomingAppointment rows={rows} />
    <ResponsiveTable headers={HEADERS}>
      {rows.map((voucher) => (
        <Toggle key={voucher.id}>
          {({ open, handleClick, handleClose }) => (
            <>
              {voucher.external ? (
                <>
                  <ResponsiveExternalClaimRow
                    key={voucher.id}
                    headers={HEADERS_EXTERNAL_CLAIM}
                    voucher={voucher}
                    claims={voucher.claims}
                    isActive={open}
                    handleActivate={handleClick}
                    handleDeactivate={handleClose}
                  />
                  <ExternalClaimRow
                    headers={HEADERS_EXTERNAL_CLAIM}
                    open={open}
                    voucher={voucher}
                  />
                </>
              ) : (
                <>
                  <ResponsiveVoucherRow
                    headers={HEADERS}
                    voucher={voucher}
                    isActive={open}
                    handleActivate={handleClick}
                    handleDeactivate={handleClose}
                  />
                  {voucher.bundle ? (
                    <VouchersBundle
                      headers={HEADERS_BUNDLE}
                      cols={HEADERS.length}
                      open={open}
                      voucher={voucher}
                    />
                  ) : (
                    <VouchersIndividual headers={HEADERS} open={open} voucher={voucher} />
                  )}
                </>
              )}
            </>
          )}
        </Toggle>
      ))}
    </ResponsiveTable>
  </>
)

PatientVouchers.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string,
      date: PropTypes.instanceOf(Date),
      code: PropTypes.string,
      service: PropTypes.shape({
        name: PropTypes.string,
      }),
      organization: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
  ),
}

export default PatientVouchers
