import PropTypes from 'prop-types'
import { Grid, Box } from '@mui/material'
import OrganizationServicesCardInput from './OrganizationServicesCardInput'
import Container from '../stories/Container'
import Section from '../stories/Section'
import Typography from '../stories/Typography'
import InstantSearchContainerCustom from '@app/containers/InstantSearchContainerCustom'
import ComponentSequentialLoader from './ComponentSequentialLoader'
import Loader from '@app/stories/Loader'

const OrganizationServices = ({ uid, organization }) => {
  if (!uid) {
    return (
      <Box display='flex' justifyContent='center'>
        <Loader />
      </Box>
    )
  }

  const HitComponent = ({ data, exactMatch, ...props }) => {
    return (
      <Grid item xs={12} sm={12} md={12}>
        <OrganizationServicesCardInput
          distance={false}
          service={data}
          organizationReview={organization?.review || ''}
          organizationImage={organization?.image || ''}
          exactMatch={exactMatch}
          {...props}
        />
      </Grid>
    )
  }

  HitComponent.propTypes = {
    data: PropTypes.shape({
      objectID: PropTypes.string.isRequired,
    }).isRequired,
  }

  return (
    <Section id='services' bgcolor='background.smoke'>
      <Container>
        <Typography variant='h4' gutterBottom>
          Services Offered
        </Typography>
        <br />
        <Box
          spacing={4}
          alignItems='stretch'
          style={{
            position: 'relative',
            overflow: 'scroll',
          }}
        >
          <InstantSearchContainerCustom
            backgroundCollapse
            HitContainer={({ children }) => (
              <ComponentSequentialLoader length={500} cardsPerRow={3} leftJustified noScroll>
                {children}
              </ComponentSequentialLoader>
            )}
            HitComponent={HitComponent}
            variant='homepage'
            indexName='network_services'
            geoSearch
            configuration={{
              filters: `network.slug:mishe AND clinicID:${uid}`,
              hitsPerPage: 6,
            }}
            searchBoxText='Search for a procedure'
            noResultsMessage='No results'
            usePagination
            clearSearchButton
            showInitialHits
            customZIndex={1}
          />
        </Box>
      </Container>
    </Section>
  )
}

OrganizationServices.propTypes = {
  uid: PropTypes.string.isRequired,
  organization: PropTypes.shape({
    id: PropTypes.string.isRequired,
    review: PropTypes.shape({
      count: PropTypes.number,
      link: PropTypes.string,
    }),
    image: PropTypes.string,
  }).isRequired,
}

export default OrganizationServices
