import { useState } from 'react'
import { Button, CircularProgress } from '@mui/material'
import Icon from '@app/stories/Icons'
import Typography from '@app/stories/Typography'
import GeneralModal from '@app/containers/GeneralModal'
import Box from '@mui/material/Box'

const customCloseComponent = (handleRequest, shareStatus) => {
  return (handleClose) => (
    <Box
      display='flex'
      flexDirection='row'
      justifyContent='center'
      alignItems='center'
      style={{ marginTop: '16px' }}
    >
      <Button
        variant='outlined'
        size='small'
        style={{ textTransform: 'none', display: 'flex', alignItems: 'center', marginRight: '8px' }}
        onClick={() => {
          handleClose()
        }}
      >
        <Icon name='close' />
        <Typography variant='body2' style={{ marginLeft: '8px' }}>
          {shareStatus === 'pending' ? 'Close' : 'Cancel'}
        </Typography>
      </Button>
      {shareStatus !== 'pending' && (
        <Button
          size='small'
          variant='contained'
          style={{
            textTransform: 'none',
            display: 'flex',
            alignItems: 'center',
            marginLeft: '8px',
          }}
          onClick={handleRequest}
        >
          <Icon name='check' />
          <Typography variant='body2' style={{ marginLeft: '8px' }}>
            Confirm
          </Typography>
        </Button>
      )}
    </Box>
  )
}

const capitalizeFirstLetter = (string) => {
  return string.replace(/\b\w/g, (char) => char.toUpperCase())
}

const RequestClaimsShareModal = ({
  buttonText = 'Request Access',
  headerText = 'Requesting view access to claims',
  plan = {},
  memberInfo = {},
}) => {
  const [requestStatus, setRequestStatus] = useState(null)
  const [loading, setLoading] = useState(false)

  const memberName = memberInfo.name
    ? capitalizeFirstLetter(memberInfo.name)
    : `${capitalizeFirstLetter(memberInfo.firstname || '')} ${capitalizeFirstLetter(memberInfo.lastname || '')}`.trim()

  return (
    <GeneralModal
      className='request-claims-share-modal'
      headerText={headerText}
      maxWidth='lg'
      customButton={
        <Button
          variant='outlined'
          size='small'
          style={{ textTransform: 'none', display: 'flex', alignItems: 'center' }}
        >
          <Icon name='assignmentOutlined' />
          <Typography variant='body2' style={{ marginLeft: '8px' }}>
            {buttonText}
          </Typography>
        </Button>
      }
      customClose={customCloseComponent(async () => {
        setLoading(true)
        const status = await plan.handleRequestClaimsShare(memberInfo.memberId).catch((e) => {
          console.error(e)
          setRequestStatus('error')
        })
        setLoading(false)
        setRequestStatus(status)
        return status
      }, memberInfo.shareStatus)}
    >
      <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
        {!requestStatus && !loading && (
          <>
            {memberInfo.shareStatus === 'pending' ? (
              <Typography variant='body1'>
                {memberName} has not yet responded to your request to view their claims.
              </Typography>
            ) : (
              <>
                {memberInfo.age <= 16 ? (
                  <Typography variant='body1'>
                    This member is under 16. Their claims will automatically be approved for sharing
                    upon request.
                  </Typography>
                ) : (
                  <>
                    <Typography variant='body1'>
                      {memberName} has not shared their claims with you.
                    </Typography>
                    <Typography variant='body1'>
                      Would you like to request view access to their claims?
                    </Typography>
                  </>
                )}
              </>
            )}
          </>
        )}
        {requestStatus === 'success' && (
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
            style={{ marginTop: '16px' }}
          >
            <Typography variant='body1'>
              Your request to view {memberName}&apos;s claims has been sent.
            </Typography>
            <Button
              variant='contained'
              onClick={() => window.location.reload()}
              style={{ marginTop: '16px' }}
            >
              Refresh
            </Button>
          </Box>
        )}
        {requestStatus === 'error' && (
          <Typography variant='body1'>
            There was an error sending your request at this time.
          </Typography>
        )}
        {loading && (
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
            style={{ marginTop: '16px' }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
    </GeneralModal>
  )
}

export default RequestClaimsShareModal
