import { useCallback, useState } from 'react'

function useToggleOpen() {
  const [anchorel, setAnchorEl] = useState(null)
  const [idx, setIdx] = useState(null)

  const handleClick = useCallback((event, value) => {
    setAnchorEl(event.currentTarget)
    setIdx(value)
  }, [])

  const handleClose = useCallback(() => {
    setIdx(null)
    setAnchorEl(null)
  }, [])

  return [anchorel, idx, handleClick, handleClose, setAnchorEl]
}

export const Toggle = ({ children }) => {
  const [anchorel, idx, handleClick, handleClose] = useToggleOpen(null)
  const open = Boolean(anchorel)
  return children({ open, idx, handleClick, handleClose, anchorel })
}

export default useToggleOpen
