import Button from '@mui/material/Button'
import { List, ListItem, Typography, Box } from '@mui/material'
import Divider from '@app/stories/Divider'

const PlanDocs = ({ docs }) => {
  if (!docs || docs.length === 0) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          paddingTop: 2,
        }}
      >
        <Typography variant='body1'>No plan documents available for download.</Typography>
      </Box>
    )
  }

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}
    >
      <List>
        {docs.map((doc, index) => (
          <ListItem key={index} sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <a
              href={doc.downloadURL}
              download={doc.name}
              target='_blank'
              rel='noopener noreferrer'
              style={{ textDecoration: 'none' }}
            >
              <Button
                variant='contained'
                sx={{
                  backgroundColor: '#652d92',
                  color: 'white',
                  '&:hover': { backgroundColor: '#4a1f6d' },
                }}
              >
                Download {doc.name.replace(/\.[^/.]+$/, '')}
              </Button>
            </a>
          </ListItem>
        ))}
      </List>
    </Box>
  )
}

export default PlanDocs
