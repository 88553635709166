import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import MuiTypography from '@mui/material/Typography'

const PriceTagText = styled(MuiTypography)`
  color: ${(props) => props.theme.palette.text.primary};
  font-size: 1rem;
  font-weight: 500;
  ${(props) =>
    props.decorate === 'underline' &&
    css`
      text-decoration: underline;
    `}
  ${(props) =>
    props.size === 'medium' &&
    css`
      color: ${props.theme.palette.primary.main};
      font-size: 1rem;
      font-weight: 600;
    `}
  ${(props) =>
    props.size === 'large' &&
    css`
      color: ${props.theme.palette.primary.main};
      font-size: 1.125rem;
      font-weight: 600;
    `}
  ${(props) =>
    props.size === 'small' &&
    css`
      font-size: 0.875rem;
      &:hover {
        color: ${props.theme.palette.primary.main};
      }
    `}
`

const PriceTag = ({ children, size = '', prefix = null, decorate = null }) => (
  <PriceTagText size={size} decorate={decorate}>
    {prefix && prefix}${children}
  </PriceTagText>
)

PriceTag.propTypes = {
  children: PropTypes.node,
  size: PropTypes.string,
  prefix: PropTypes.string,
  decorate: PropTypes.string,
}

export default PriceTag
