import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import MuiAlert from '@mui/material/Alert'

const StyledAlert = styled(MuiAlert)`
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  top: ${(props) => props.theme.spacing(18)};
  right: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  text-align: center;
  z-index: 10;
  .MuiCheckbox-root {
    padding: 4px;
  }
  flex-wrap: wrap;
  ${(props) => props.theme.breakpoints.up('md')} {
    flex-wrap: nowrap;
  }
  .MuiAlert-icon {
    display: block;
    width: 100%;
    padding-bottom: 0px;
    ${(props) => props.theme.breakpoints.up('md')} {
      display: flex;
      width: auto;
      padding-bottom: 7px;
    }
  }
  ${(props) =>
    props.severity === 'info' &&
    css`
      background: ${props.theme.palette.primary.light};
      .MuiAlert-icon {
        color: ${props.theme.palette.primary.dark};
      }
    `}
`

const Notification = ({ children, severity = '', ...props }) => (
  <StyledAlert elevation={2} severity={severity} {...props}>
    {children}
  </StyledAlert>
)

Notification.propTypes = {
  children: PropTypes.node.isRequired,
  severity: PropTypes.string,
}

export default Notification
