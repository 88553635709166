import { useCallback, useState, useEffect } from 'react'
import Form from '@app/components/ResetPasswordForm'
import Notification from '@app/components/NotificationBox'
import { resetPassword } from '../firebase/auth'
import { Box } from '@mui/system'
import Typography from '../stories/Typography'
import Button from '../stories/Button'
import { Divider } from '@mui/material'

const ResetPasswordForm = () => {
  const [notification, setNotification] = useState(null)
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    window.Intercom('trackEvent', 'Page View', {
      page_name: 'Reset Password',
    })
  }, [])

  const onSubmit = useCallback(
    async (event) => {
      event.preventDefault()
      const { email } = event.target.elements
      if (notification === null && email.value) {
        // show success notification optimistically
        setNotification({
          severity: 'success',
          message: 'Reset password email sent',
        })
        setSuccess(true)
        try {
          await resetPassword(email.value)
          window.Intercom('trackEvent', 'Reset Password Email Requested')
        } catch (error) {
          // TODO
        }
      }
    },
    [notification],
  )

  const onChange = useCallback(() => {
    setNotification(null)
  }, [])

  return (
    <>
      {notification && <Notification {...notification} />}
      {success ? (
        <Box mt={2}>
          <Typography variant='h2' style={{ marginBottom: '0px' }}>
            {' '}
            Success{' '}
          </Typography>
          <Divider style={{ marginBottom: '16px' }} />
          <Typography variant='h4' gutterBottom>
            Your password reset email is on its way
          </Typography>
          <Typography variant='body2' weight='light'>
            Please check your email inbox for instructions on how to reset your password. If you
            don't see the email right away, be sure to look in other folders where it might have
            been filtered. If the email still doesn&apos;t appear after a few minutes, consider
            refreshing your inbox or trying a keyword search for mishe.
          </Typography>
          <Button href='/dashboard' fullWidth style={{ marginTop: '16px' }}>
            Return to Login
          </Button>
        </Box>
      ) : (
        <Box>
          <Typography variant='h2' gutterBottom>
            Reset Password
          </Typography>
          <Typography variant='body2' weight='light'>
            Enter the e-mail associated with your <i>mishe</i> account.
          </Typography>
          <Form onChange={onChange} onSubmit={onSubmit} />
        </Box>
      )}
    </>
  )
}

export default ResetPasswordForm
