import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Box from '@mui/material/Box'
import Heading from '../stories/Heading'
import AcceptTermsNotification from '@app/containers/AcceptTermsNotification'
import { useCurrentProfile } from '@app/context/CurrentUser'
import Loader from '../stories/Loader'
import DashboardProviders from './DashboardProviders'
import { setTimezoneFromCoordinates } from '@app/firebase/functions'
import { collection } from '@app/firebase/firestore'

const DashboardPage = ({ children }) => {
  const {
    uid,
    isProvider,
    isPlanAdmin,
    organizationName,
    managedPlanData,
    firstname,
    acceptedTerms,
    organizationId,
  } = useCurrentProfile()

  const { search } = useLocation()
  const ref = new URLSearchParams(search).get('ref')

  if (ref) {
    localStorage.setItem('refvalue', ref)
  }

  const getOrgData = async (clinicID) => {
    return collection('organizations')
      .doc(clinicID)
      .get()
      .then((doc) => {
        if (!doc.exists) {
          console.error('  ** No such clinic document!')
          return null
        }
        return {
          id: doc.id,
          ...doc.data(),
        }
      })
      .catch((err) => {
        console.error('  ** Error getting clinic data:', err)
        return null
      })
  }

  useEffect(() => {
    window.Intercom('trackEvent', 'Page View', {
      page_name: 'Dashboard',
    })
    organizationId &&
      getOrgData(organizationId).then(async (orgData) => {
        if (!orgData.timezone && orgData.primaryLocationCoords) {
          const { lat, lng } = orgData.primaryLocationCoords
          await setTimezoneFromCoordinates({ lat, lng, organizationId }).then((timezone) => {
            console.log('timezone updated', timezone)
          })
        }
      })
  }, [organizationId])

  if (!uid) {
    return <Loader />
  }

  return (
    <Box padding='32px'>
      {!acceptedTerms && isProvider && <AcceptTermsNotification />}
      <Box mb={6}>
        {firstname && (
          <Heading gutterBottom>
            {firstname?.endsWith('s') ? (
              <Box component='span' color='primary.main' style={{ textTransform: 'capitalize' }}>
                {firstname}&apos;
              </Box>
            ) : (
              <Box component='span' color='primary.main' style={{ textTransform: 'capitalize' }}>
                {firstname}&apos;s
              </Box>
            )}{' '}
            <i>my mishe</i> dashboard
          </Heading>
        )}
        {isProvider && (
          <Heading variant='h4' weight='medium' component='p' gutterBottom>
            Clinician at {organizationName}
          </Heading>
        )}
        {isPlanAdmin && (
          <Heading variant='h4' weight='medium' component='p' gutterBottom>
            Admin of {managedPlanData?.planData?.name || 'pending'}
          </Heading>
        )}
      </Box>
      <DashboardProviders>{children}</DashboardProviders>
    </Box>
  )
}

export default DashboardPage
