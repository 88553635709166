import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box, Grid } from '@mui/material'
import { collection } from '../firebase/firestore'
import Typography from '../stories/Typography'
import Button from '../stories/Button'
import Icon from '../stories/Icons'
import Divider from '../stories/Divider'
import NotesAttributes from '@app/components/NotesAttributes'
import { useCurrentProfile } from '@app/context/CurrentUser'
import generatePDF from '../libs/pdf'
import usePDFs from '@app/hooks/usePDFs'

import { MuiMarkdown } from 'mui-markdown'
import { formatMarkdown } from '@app/libs/formatters'

const OrderNoteView = ({ order, orderID, status, noDownload = false }) => {
  const { createOrderWithLogo } = usePDFs()
  const [openNotes, setOpenNotes] = useState(false)
  const [notes, setNotes] = useState([])
  const [sharedNotes, setSharedNotes] = useState([])

  const { isProvider } = useCurrentProfile()

  useEffect(() => {
    const getNotes = async (id) => {
      try {
        const notesRef = await collection('notes').where('orderID', '==', id).get()

        const notesData = notesRef.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data(),
          }
        })

        setNotes(notesData)
      } catch (error) {
        console.log('couldnt get notes')
        console.log(error)
      }

      try {
        const sharedNotesRef = await collection('notes').where('shared', 'array-contains', id).get()

        const sharedNotesData = sharedNotesRef.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data(),
          }
        })

        setSharedNotes(sharedNotesData)
      } catch (error) {
        console.log('couldnt get shared notes')
        console.log(error)
      }
    }

    if (!openNotes) {
      setNotes([])
      return undefined
    }

    if (!orderID) {
      return undefined
    }

    if (openNotes) {
      getNotes(orderID)
    }

    return () => {
      setNotes([])
    }
  }, [orderID, openNotes])

  return (
    <>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button
          onClick={() => {
            setOpenNotes(true)
            document.body.style.overflow = 'hidden'
          }}
          variant='outlined'
          size='small'
          style={{
            color: '#fff',
            backgroundColor: `${status === 'paid' ? '#009124' : '#d32f2f'}`,
            border: `${status === 'paid' ? 'solid 1px #009124' : 'solid 1px #d32f2f'}`,
            width: isProvider ? '50%' : '100%',
            maxWidth: noDownload ? 'calc(50% - 8px)' : '100%',
          }}
        >
          <Typography variant='body1' family='poppins' align='center'>
            View Notes
          </Typography>
        </Button>
        {isProvider && !noDownload && (
          <Button
            variant='outlined'
            size='small'
            style={{
              color: '#fff',
              backgroundColor: `${status === 'paid' ? '#009124' : '#d32f2f'}`,
              border: `${status === 'paid' ? 'solid 1px #009124' : 'solid 1px #d32f2f'}`,
              width: '50%',
            }}
            onClick={() => {
              generatePDF(createOrderWithLogo(order), `Order_${orderID}`)
            }}
          >
            <Typography variant='body1' family='poppins' align='center'>
              Print Order
            </Typography>
          </Button>
        )}
      </Box>
      {openNotes && (
        <>
          <Box
            style={{
              position: 'fixed',
              zIndex: '9999',
              width: '100vw',
              height: '100vh',
              backgroundColor: 'rgba(0, 0, 0, 0.75)',
              top: '0',
              left: '0',
            }}
          />
          <Box
            style={{
              position: 'fixed',
              zIndex: '10000',
              width: '80vw',
              height: '80vh',
              backgroundColor: 'white',
              top: '10vh',
              left: '10vw',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
              borderRadius: '4px',
              overflow: 'scroll',
            }}
          >
            <Box
              style={{
                position: 'absolute',
                zIndex: '10002',
                width: '100%',
                height: '60px',
                backgroundColor: '#fff',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                padding: '0px 12px',
                borderBottom: 'solid #eee 1px',
                borderRadius: '4px 4px 0px 0px',
                overflow: 'scroll',
              }}
            >
              <Typography
                family='poppins'
                weight='light'
                style={{
                  marginBottom: '0px',
                }}
              >
                Notes
              </Typography>
              <Button
                onClick={() => {
                  setOpenNotes(false)
                  document.body.style.overflow = 'auto'
                }}
                variant='outlined'
                size='small'
                style={{
                  position: 'absolute',
                  right: '12px',
                  minHeight: '40px',
                  minWidth: '40px',
                  maxWidth: '40px',
                  borderRadius: '50%',
                  border: 'none',
                  boxShadow: 'none',
                  transform: 'rotate(180deg)',
                }}
              >
                <Icon name='exitToApp' color='white' size='large' />
              </Button>
            </Box>

            {notes.length > 0 ? (
              <Box
                style={{
                  padding: '76px 16px',
                  overflow: 'scroll',
                }}
              >
                <Grid container spacing={2}>
                  {notes.map((note) => (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      lg={4}
                      xl={4}
                      key={note.uid}
                      style={{
                        marginBottom: '16px',
                      }}
                    >
                      {note.noteType === 'text' && (
                        <Box
                          style={{
                            border: '1px solid rgba(0, 0, 0, 0.12)',
                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
                            borderRadius: '4px',
                            padding: '20px',
                            height: '100%',
                          }}
                        >
                          <Box>
                            <MuiMarkdown>{formatMarkdown(note?.content || '')}</MuiMarkdown>
                          </Box>
                          <Divider {...{ style: { margin: '1rem 0' } }} />
                          <NotesAttributes {...note} showShare={false} />
                        </Box>
                      )}
                      {note.noteType === 'image' && (
                        <Box
                          style={{
                            border: '1px solid rgba(0, 0, 0, 0.12)',
                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
                            borderRadius: '4px',
                            padding: '20px',
                            height: '100%',
                          }}
                        >
                          <Box key={note.uid} maxWidth='100%' mb={1}>
                            <img
                              src={note.content}
                              alt='note'
                              width='100%'
                              style={{
                                borderRadius: '5px',
                                boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                              }}
                            />
                          </Box>
                          <Box>
                            <Typography variant='body1' mb={2}>
                              {note.fileName || 'Image'}
                              <a
                                href={note.content}
                                download={note.fileName}
                                target='_blank'
                                rel='noopener noreferrer'
                                style={{
                                  marginLeft: '10px',
                                  textDecoration: 'none',
                                }}
                              >
                                <Button variant='outlined' size='small' color='primary'>
                                  Download
                                </Button>
                              </a>
                            </Typography>
                          </Box>
                          <Divider {...{ style: { margin: '1rem 0' } }} />
                          <NotesAttributes {...note} showShare={false} />
                        </Box>
                      )}
                      {note.noteType === 'file' && (
                        <Box
                          style={{
                            border: '1px solid rgba(0, 0, 0, 0.12)',
                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
                            borderRadius: '4px',
                            padding: '20px',
                            height: '100%',
                          }}
                        >
                          <Box>
                            <Typography variant='body1' mb={2}>
                              {note.fileName || 'File'}
                              <a
                                href={note.content}
                                download={note.fileName}
                                target='_blank'
                                rel='noopener noreferrer'
                                style={{
                                  marginLeft: '10px',
                                  textDecoration: 'none',
                                }}
                              >
                                <Button variant='outlined' size='small' color='primary'>
                                  Download
                                </Button>
                              </a>
                            </Typography>
                          </Box>
                          <Divider {...{ style: { margin: '1rem 0' } }} />
                          <NotesAttributes {...note} showShare={false} />
                        </Box>
                      )}
                    </Grid>
                  ))}
                </Grid>
              </Box>
            ) : (
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                <Typography variant='body1' weight='light'>
                  No native notes.
                </Typography>
              </Box>
            )}
            {sharedNotes.length > 0 && (
              <Box
                style={{
                  padding: '76px 16px',
                  overflow: 'scroll',
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography
                      family='poppins'
                      weight='light'
                      style={{
                        marginBottom: '0px',
                      }}
                    >
                      Shared Notes
                    </Typography>
                    <Divider {...{ style: { margin: '1rem 0' } }} />
                  </Grid>
                  {sharedNotes.map((note) => (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      lg={4}
                      xl={4}
                      key={note.uid}
                      style={{
                        marginBottom: '16px',
                      }}
                    >
                      {note.noteType === 'text' && (
                        <Box
                          style={{
                            border: '1px solid rgba(0, 0, 0, 0.12)',
                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
                            borderRadius: '4px',
                            padding: '20px',
                            height: '100%',
                          }}
                        >
                          <Box>
                            <MuiMarkdown>{formatMarkdown(note?.content || '')}</MuiMarkdown>
                          </Box>
                          <Divider {...{ style: { margin: '1rem 0' } }} />
                          <NotesAttributes {...note} showShare={false} />
                        </Box>
                      )}
                      {note.noteType === 'image' && (
                        <Box
                          style={{
                            border: '1px solid rgba(0, 0, 0, 0.12)',
                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
                            borderRadius: '4px',
                            padding: '20px',
                            height: '100%',
                          }}
                        >
                          <Box key={note.uid} maxWidth='100%' mb={1}>
                            <img
                              src={note.content}
                              alt='note'
                              width='100%'
                              style={{
                                borderRadius: '5px',
                                boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                              }}
                            />
                          </Box>
                          <Box>
                            <Typography variant='body1' mb={2}>
                              {note.fileName || 'Image'}
                              <a
                                href={note.content}
                                download={note.fileName}
                                target='_blank'
                                rel='noopener noreferrer'
                                style={{
                                  marginLeft: '10px',
                                  textDecoration: 'none',
                                }}
                              >
                                <Button variant='outlined' size='small' color='primary'>
                                  Download
                                </Button>
                              </a>
                            </Typography>
                          </Box>
                          <Divider {...{ style: { margin: '1rem 0' } }} />
                          <NotesAttributes {...note} showShare={false} />
                        </Box>
                      )}
                      {note.noteType === 'file' && (
                        <Box
                          style={{
                            border: '1px solid rgba(0, 0, 0, 0.12)',
                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
                            borderRadius: '4px',
                            padding: '20px',
                            height: '100%',
                          }}
                        >
                          <Box>
                            <Typography variant='body1' mb={2}>
                              {note.fileName || 'File'}
                              <a
                                href={note.content}
                                download={note.fileName}
                                target='_blank'
                                rel='noopener noreferrer'
                                style={{
                                  marginLeft: '10px',
                                  textDecoration: 'none',
                                }}
                              >
                                <Button variant='outlined' size='small' color='primary'>
                                  Download
                                </Button>
                              </a>
                            </Typography>
                          </Box>
                          <Divider {...{ style: { margin: '1rem 0' } }} />
                          <NotesAttributes {...note} showShare={false} />
                        </Box>
                      )}
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}
          </Box>
        </>
      )}
    </>
  )
}

OrderNoteView.propTypes = {
  orderID: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  order: PropTypes.object.isRequired,
  noDownload: PropTypes.bool,
}

export default OrderNoteView
