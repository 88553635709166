import PropTypes from 'prop-types'
import { Button } from '@mui/material'
import Icon from '@app/stories/Icons'
import Typography from '@app/stories/Typography'
import GeneralModal from '@app/containers/GeneralModal'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
} from '@mui/material'

import { formatDateForUI, formatCurrency } from '@app/libs/formatters'

const convertVoucherIntoNewStructure = (voucher) => {
  if (!voucher) {
    return null
  }

  const { customer, service, organization } = voucher

  return {
    ...voucher,
    point_of_service_description: service?.rendering || '---',
    document_num: voucher.id,
    patient_info: {
      ...customer,
    },
    service_info: {
      ...service,
      date_of_service_initial: formatDateForUI(voucher?.appointmentDate) || '',
    },
    provider_info: {
      ...organization,
    },
    financials: {
      original_charge: service.price,
      allowed_amount: service.price,
      ineligible_amount: 0,
      amount_paid: service.price,
      deductible: 0,
      coinsurance: 0,
      copay: 0,
      paid_date: formatDateForUI(voucher?.redeemedAt) || '',
    },
  }
}

const EobButton = ({ claims = [], label }) => {
  if (!claims || claims.length === 0) {
    return (
      <>
        <Typography variant='body2' style={{ marginLeft: '8px' }}>
          No Claims
        </Typography>
      </>
    )
  }

  // convert any vouchers that do not have external true
  const convertedVouchers = claims.map((claim) => {
    if (!claim.external) {
      return convertVoucherIntoNewStructure(claim)
    }
    return claim
  })

  const totals = {
    original_charge: 0,
    allowed_amount: 0,
    ineligible_amount: 0,
    amount_paid: 0,
    deductible: 0,
    coinsurance: 0,
    copay: 0,
    paid_date: '',
    patient_paid: 0,
  }

  convertedVouchers.forEach((claim) => {
    const { financials } = claim
    console.log('financials', financials)
    if (financials) {
      totals.original_charge += financials.original_charge || 0
      totals.allowed_amount += financials.allowed_amount || 0
      totals.ineligible_amount += financials.ineligible_amount || 0
      totals.amount_paid += financials.amount_paid || 0
      totals.deductible += financials.deductible || 0
      totals.coinsurance += financials.coinsurance || 0
      totals.copay += financials.copay || 0
      totals.paid_date = financials?.paid_date || ''
      // total patient paid is deductible + coinsurance + copay
      totals.patient_paid += financials.deductible + financials.coinsurance + financials.copay
    }
  })

  const document_num = convertedVouchers[0]?.DOCUMENT_NUM || null
  const mainClaim = convertedVouchers[0] || {}
  const { patient_info: mainPatientInfo, provider_info: mainProviderInfo } = mainClaim

  return (
    <GeneralModal
      id='explanationOfBenefits'
      headerText='Explanation of Benefits'
      maxWidth={'lg'}
      customButton={
        <Button
          variant='outlined'
          size='small'
          style={{ textTransform: 'none', display: 'flex', alignItems: 'center' }}
        >
          <Icon name='assignmentOutlined' />
          <Typography variant='body2' style={{ marginLeft: '8px' }}>
            {label}
          </Typography>
        </Button>
      }
    >
      <Box display='flex' justifyContent='center' alignItems='center'>
        <Box p={2}>
          {mainPatientInfo && (
            <Box display='flex' justifyContent='space-between' alignItems='flex-start' pb={2}>
              <Box>
                <Typography variant='h6' fontWeight='bold' style={{ textTransform: 'capitalize' }}>
                  Patient's Name:{' '}
                  {`${mainPatientInfo.firstname || ''} ${mainPatientInfo.lastname || ''}`.trim() ||
                    '---'}
                </Typography>
                <Typography variant='h6' fontWeight='bold'>
                  Plan Paid:{' '}
                  <Typography variant='h6' component='span' color='error' fontWeight='bold'>
                    {formatCurrency(totals?.amount_paid || 0)}
                  </Typography>
                </Typography>
                <Typography variant='h6' fontWeight='bold'>
                  Patient Paid:{' '}
                  <Typography variant='h6' component='span' color='error' fontWeight='bold'>
                    {formatCurrency(totals?.patient_paid || 0)}
                  </Typography>
                </Typography>
                <Typography variant='h6' fontWeight='bold'>
                  To: {mainProviderInfo?.name || '---'}
                </Typography>
                <Typography variant='h6' fontWeight='bold'>
                  It is not your responsibility to pay:{' '}
                  <Typography variant='h6' component='span' color='error' fontWeight='bold'>
                    {formatCurrency(totals?.original_charge - totals?.allowed_amount || 0)}
                  </Typography>
                </Typography>
              </Box>
              <Box>
                <Typography variant='h6' fontWeight='bold'>
                  Claim Number: {document_num || '---'}
                </Typography>
                <Typography variant='h6' fontWeight='bold'>
                  Claim Processed Date: {totals?.paid_date || '---'}
                </Typography>
                <Typography variant='h6' fontWeight='bold'>
                  Provider of Services: {mainProviderInfo?.name || '---'}
                </Typography>
                <Typography variant='h6' fontWeight='bold'>
                  Place of Service: {mainClaim?.point_of_service_description || '---'}
                </Typography>
              </Box>
            </Box>
          )}

          <TableContainer component={Paper} sx={{ marginTop: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>DATE</TableCell>
                  <TableCell>TREATMENT</TableCell>
                  <TableCell>BILLED</TableCell>
                  <TableCell>SAVINGS</TableCell>
                  <TableCell>CONTRACTED</TableCell>
                  <TableCell>INELIGIBLE</TableCell>
                  <TableCell>PLAN PAID</TableCell>
                  <TableCell>DEDUC</TableCell>
                  <TableCell>COINS</TableCell>
                  <TableCell>COPAY</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {convertedVouchers.map((claim, index) => {
                  const { financials, service_info } = claim
                  if (!financials) return null

                  return (
                    <TableRow key={index}>
                      <TableCell>{service_info?.date_of_service_initial || '---'}</TableCell>
                      <TableCell>{service_info?.name || 0}</TableCell>
                      <TableCell>{formatCurrency(financials?.original_charge || 0)}</TableCell>
                      <TableCell>
                        {formatCurrency(financials?.original_charge - financials?.allowed_amount)}
                      </TableCell>
                      <TableCell>{formatCurrency(financials?.allowed_amount || 0)}</TableCell>
                      <TableCell>{formatCurrency(financials?.ineligible_amount || 0)}</TableCell>
                      <TableCell>{formatCurrency(financials?.amount_paid || 0)}</TableCell>
                      <TableCell>{formatCurrency(financials?.deductible || 0)}</TableCell>
                      <TableCell>{formatCurrency(financials?.coinsurance || 0)}</TableCell>
                      <TableCell>{formatCurrency(financials?.copay || 0)}</TableCell>
                    </TableRow>
                  )
                })}
                <TableRow>
                  <TableCell colSpan={2}>CLAIM TOTALS</TableCell>
                  <TableCell>{formatCurrency(totals.original_charge)}</TableCell>
                  <TableCell>
                    {formatCurrency(totals.original_charge - totals.allowed_amount)}
                  </TableCell>
                  <TableCell>{formatCurrency(totals.allowed_amount)}</TableCell>
                  <TableCell>{formatCurrency(totals.ineligible_amount)}</TableCell>
                  <TableCell>{formatCurrency(totals.amount_paid)}</TableCell>
                  <TableCell>{formatCurrency(totals.deductible)}</TableCell>
                  <TableCell>{formatCurrency(totals.coinsurance)}</TableCell>
                  <TableCell>{formatCurrency(totals.copay)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </GeneralModal>
  )
}

EobButton.propTypes = {
  claims: PropTypes.arrayOf(PropTypes.object).isRequired,
  label: PropTypes.string,
}

export default EobButton
