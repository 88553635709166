import { createLightBackgroundColor } from '@app/utils/hexcodeUtils'
import { Box } from '@mui/material'
import Typography from '@app/stories/Typography'

const HalfPhoneBackground = ({ children, color }) => {
  const lightBackgroundColor = createLightBackgroundColor(color)

  const iPhoneBackgroundSvg = (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      id='Layer_1'
      viewBox='0 0 851.59 1010.39'
      height='100%'
      width='100%'
    >
      <path
        fill={color || '#652d92'}
        d='M11.11,859.51c0-240.45.06-480.91-.08-721.36-.02-28.2,6.97-54.1,23.2-77.12C57.72,27.71,89.94,8.86,130.62,4.8c4.3-.43,8.64-.58,12.96-.58,189.47-.02,378.94-.09,568.4.04,63.01.04,119.65,50.73,127.11,113.1.99,8.28,1.44,16.57,1.44,24.89,0,98.82,0,197.63,0,296.45,0,67.64.04,135.28.03,202.93,0,122.92,0,245.84,0,368.76h3.9c-.07-116.76-.14-233.52,0-350.28,0-3.48.22-6.97.34-10.45-.72-2.05-.73-3.68,2.13-3.63,3.84.07,4.7-2.26,4.64-5.55-.34-17.97-.88-35.94-.89-53.92-.02-46.95-.7-93.91.82-140.85.09-2.72-.62-4.86-2.59-6.64-.66-.04-1.32-.08-1.98-.12-.29.02-.58.05-.87.07-2.92-.64-1.52-2.99-1.53-4.47-.11-20.64-.08-41.28-.08-61.92,0-78.89-.01-157.79.03-236.68,0-4.48-1.21-8.92-.43-13.42-.05-.13-.17-.27-.14-.37.08-.25.22-.49.34-.73-.84-1.15-.88-2.42-.58-3.74-.24-.78-.58-1.55-.71-2.34-7.07-44.14-30.31-77.04-69.09-99.02-15.25-8.64-31.94-13-49.15-15.62-.98-.08-1.95-.16-2.93-.25-.52-.05-1.04-.09-1.56-.14-1.47.42-2.89.08-4.31-.32-1.98.13-3.96.38-5.94.38-189.47.02-378.94.02-568.4,0-1.81,0-3.63-.25-5.44-.38-.74.04-1.47.09-2.21.13-8.03,1.22-16.12,2.01-24.09,3.75C57.39,15.3,13.65,64.12,8.09,117.48c-1.7,16.3-.8,32.63-.89,48.95-.17,33.49-.04,66.99-.08,100.48,0,2.06,1.16,5.21-2.87,4.88-3.85-.31-4.35,1.75-4.22,4.91.77,18.98.78,37.97,0,56.96-.14,3.28.15,5.7,4.38,5.13,2.17-.29,2.8.9,2.8,2.99-.07,17-.08,33.99-.03,50.99,0,2.01-.38,3.36-2.69,3.02-4.23-.61-4.56,1.85-4.45,5.13.27,7.99.48,15.99.46,23.98-.08,32.16-.23,64.32-.42,96.47-.02,3.08-.21,5.86,4.24,5.28,2.11-.28,2.87.84,2.85,2.98-.09,9.33-.06,18.66,0,27.99.01,1.99-.52,3.35-2.74,3.1-4.24-.48-4.49,2.1-4.39,5.27.9,30.99.39,61.98.45,92.97.02,9.49-.26,18.99-.4,28.48-.03,1.83-.27,4.4,2.31,4.05,5.6-.77,4.77,2.8,4.77,6.11,0,104.26,0,208.53,0,312.79h3.95c0-50.29,0-100.59,0-150.88Z'
      />
      <path
        fill={color || '#652d92'}
        d='M836.48,138.81c0-43.33-17.09-78.53-51.42-104.89-23.23-17.83-49.86-25.74-79.24-25.71-187.64.16-375.28.08-562.91.06-8.53,0-16.94.82-25.32,2.46C59.2,22.19,14.94,75.51,14.94,134.51c0,291.96,0,583.92,0,875.88h32.84c0-50.46,0-100.92,0-151.38,0-240.8,0-481.59,0-722.39,0-53.1,42.68-95.97,95.88-95.99,188.14-.06,376.27-.07,564.41,0,52.85.02,95.01,42.57,95.77,96.31.06,4,.02,8,.02,12,0,287.15,0,574.29,0,861.44h32.62c0-50.4,0-100.79,0-151.19,0-240.13.02-480.26-.01-720.39Z'
      />
      <path
        fill={color || '#652d92'}
        d='M488.87,65.77c-44.62-.11-89.23-.04-133.85-.03-6.38,0-12.1,2.03-17.05,6.04-9.1,7.38-12.5,19.54-8.65,30.65,3.8,10.99,14.32,18.14,27.18,18.19,21.81.08,43.62.02,65.43.02,21.64,0,43.28,0,64.93-.01,1.66,0,3.33-.07,4.98-.29,15.31-1.98,25.13-13.89,24.33-29.46-.72-13.96-12.55-25.08-27.28-25.11Z'
      />
    </svg>
  )

  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      width='400px'
      position='relative'
      zIndex='1'
    >
      {iPhoneBackgroundSvg}
      <Box
        position='absolute'
        top='8px'
        left='8px'
        width='calc(100% - 16px)'
        height='calc(100% - 8px)'
        zIndex='-1'
        bgcolor='white'
        borderRadius='64px 64px 0px 0px'
      />
      <Box
        position='absolute'
        top='0px'
        left='4px'
        width='calc(100% - 8px)'
        height='100%'
        zIndex='-2'
        bgcolor={lightBackgroundColor}
        borderRadius='64px 64px 0px 0px'
      />
      <Box
        position='absolute'
        bottom='0px'
        left='0px'
        zIndex='1'
        width='100%'
        height='100%'
        padding='72px 25px 0px 25px'
        display='flex'
        flexDirection='column'
        alignItems='center'
      >
        <Typography variant='h5' color='inherit'>
          Get access to
        </Typography>
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='flex-start'
          alignItems='center'
          color={color}
          gap='16px'
          paddingX='15px'
          overflow='auto'
          zIndex='2' // Added zIndex to ensure visibility
          position='relative' // Added position to ensure proper stacking context
        >
          {children}
          <Box width='100%' height='32px' />
        </Box>
      </Box>
    </Box>
  )
}

export default HalfPhoneBackground
