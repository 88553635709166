import Box from '@mui/material/Box'
import Typography from '@app/stories/Typography'
import Button from '@app/stories/Button'

const NoResults = ({ noResultsMessage, noResultsColor, noResultsLink }) => {
  return (
    <Box color={noResultsColor}>
      <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
        <Typography variant='body1' align='center' mb={2}>
          {noResultsMessage}
        </Typography>
        {noResultsLink && (
          <Button to={noResultsLink} target='blank' variant='contained' color='primary'>
            View more results
          </Button>
        )}
      </Box>
    </Box>
  )
}

export default NoResults
