import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Icon from '../stories/Icons'
import useToggleOpen from '@app/hooks/useToggleOpen'
import Modal from '../stories/Modal'
import Divider from '../stories/Divider'
import Button from '../stories/Button'
import Typography from '../stories/Typography'
import FormattedText from '../stories/FormatFromDB'

const PricingDisclaimerModal = ({ disclaimerBody = null }) => {
  const [anchorel, handleClick, handleClose] = useToggleOpen(null)
  const open = Boolean(anchorel)

  return (
    <>
      <Box color='#777' onClick={handleClick} cursor='pointer'>
        <Icon name='info' color='inherit' />
      </Box>
      <Modal maxWidth='xs' open={open} anchorel={anchorel} onClose={handleClose}>
        <Box>
          <Typography variant='h2' weight='medium' family='poppins'>
            Pricing Disclaimer
          </Typography>
          <Divider spacing='vertical' />
          {disclaimerBody ? (
            <FormattedText text={disclaimerBody} />
          ) : (
            <Box mt={1}>
              <Typography variant='body1' weight='light'>
                Prices include the cost of the procedure, facility fee, surgeon&apos;s fee,
                anesthesiologist&apos;s fee, and any hardware or implants necessary for completion
                of the procedure. Not included in the procedure bundle: any diagnostic studies
                necessary prior to the surgery such as lab, MRI, X-rays, consultations with
                specialists to determine medical risk/management, physical therapy and
                rehabilitation, and expenses or fees resulting from complications subsequent to the
                completion of the surgery and discharge from the facility.
              </Typography>
            </Box>
          )}
        </Box>
        <Box mt={4}>
          <Button size='medium' variant='contained' onClick={handleClose}>
            Close
          </Button>
        </Box>
      </Modal>
    </>
  )
}

PricingDisclaimerModal.propTypes = {
  disclaimerBody: PropTypes.string,
}

export default PricingDisclaimerModal
