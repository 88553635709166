import { Box } from '@mui/system'

const MisheLoginBackgroundBuilderDynamic = ({ width, height, color }) => {
  const ambulanceSVG = (
    <svg
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 40 33.33'
      style={{ overflow: 'visible' }}
    >
      <path d='M39.17,5h-15v-.87c0-2.39-1.57-4.13-3.75-4.13s-3.75,1.73-3.75,4.13v5.03h-7.48c-.28,0-.55.15-.71.39l-4.1,6.52L.32,19.32c-.2.16-.32.4-.32.65v10.03c0,.46.37.83.83.83h3.89c.9,2.1,3.33,3.07,5.43,2.16.97-.42,1.74-1.19,2.16-2.16h16.2c.9,2.1,3.33,3.07,5.43,2.16.97-.42,1.74-1.19,2.16-2.16h3.06c.46,0,.83-.37.83-.83V5.83c0-.46-.38-.83-.83-.83ZM18.33,4.13c0-1.22.64-2.47,2.08-2.47s2.08,1.24,2.08,2.47v.87h-4.17v-.87h0ZM4.38,29.17H1.67v-2.5h3.61c-.57.71-.88,1.59-.89,2.5ZM10.94,29.61c-.08.12-.12.26-.12.4,0,0,0,.02,0,.03-.34.97-1.27,1.63-2.32,1.63-1.36,0-2.46-1.11-2.46-2.47s1.11-2.46,2.47-2.46,2.46,1.11,2.46,2.47c0,.13-.02.27-.04.4ZM1.67,25v-4.63l3.86-3.08c.07-.06.13-.12.18-.21l3.93-6.24h7.02v14.17H1.67ZM28.17,29.17h-15.52c0-.91-.32-1.79-.89-2.5h17.31c-.57.71-.88,1.59-.9,2.5ZM32.32,31.67c-1.04,0-1.97-.66-2.32-1.64,0,0,0-.02,0-.03,0-.14-.04-.28-.12-.4-.03-.13-.04-.27-.04-.4,0-1.42,1.18-2.56,2.62-2.47,1.23.07,2.23,1.07,2.32,2.3.07,1.44-1.06,2.64-2.48,2.64ZM38.33,29.17h-1.88c0-.91-.32-1.79-.89-2.5h2.77v2.5ZM38.33,25h-20V6.67h20v18.33Z' />
      <path d='M22.5,19.17h3.33v2.5c0,.46.37.83.83.83h3.33c.46,0,.83-.37.83-.83v-2.5h3.33c.46,0,.83-.37.83-.83v-4.17c0-.46-.37-.83-.83-.83h-3.33v-3.33c0-.46-.37-.83-.83-.83h-3.33c-.46,0-.83.37-.83.83v3.33h-3.33c-.46,0-.83.37-.83.83v4.17c0,.46.37.83.83.83ZM23.33,15h3.33c.46,0,.83-.37.83-.83v-3.33h1.67v3.33c0,.46.37.83.83.83h3.33v2.5h-3.33c-.46,0-.83.37-.83.83v2.5h-1.67v-2.5c0-.46-.37-.83-.83-.83h-3.33v-2.5h0Z' />
    </svg>
  )

  const bandaidSVG = (
    <svg
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 40 40'
      style={{ overflow: 'visible' }}
    >
      <path d='M37.24,22.74l-5.83-5.83-8.33-8.33-5.83-5.83C13.6-.91,6.4-.91,2.75,2.74s-3.67,10.85,0,14.51l5.83,5.83,8.33,8.33,5.83,5.83c1.83,1.83,4.54,2.75,7.26,2.75s5.42-.92,7.26-2.75c3.65-3.66,3.65-10.85-.02-14.51ZM3.92,16.08C.91,13.07.91,6.94,3.92,3.93c1.51-1.51,3.79-2.26,6.07-2.26s4.57.75,6.07,2.26l5.24,5.24-12.15,12.15-5.24-5.24ZM10.35,22.5l12.15-12.16,7.15,7.15-12.15,12.16-7.15-7.15ZM36.07,36.08c-3.01,3.01-9.14,3.01-12.15,0l-5.24-5.24,12.15-12.16,5.24,5.24c3.01,3.02,3.01,9.15,0,12.16Z' />
      <path d='M31.65,30.4c0-.69-.56-1.25-1.25-1.25s-1.25.56-1.25,1.25.56,1.25,1.25,1.25,1.25-.56,1.25-1.25Z' />
      <path d='M34.98,29.98c-.69,0-1.25.56-1.25,1.25s.56,1.25,1.25,1.25,1.25-.56,1.25-1.25-.56-1.25-1.25-1.25Z' />
      <path d='M31.23,33.32c-.69,0-1.25.56-1.25,1.25s.56,1.25,1.25,1.25,1.25-.56,1.25-1.25-.56-1.25-1.25-1.25Z' />
      <path d='M29.98,25.4c0-.69-.56-1.25-1.25-1.25s-1.25.56-1.25,1.25.56,1.25,1.25,1.25,1.25-.56,1.25-1.25Z' />
      <path d='M26.65,29.57c0-.69-.56-1.25-1.25-1.25s-1.25.56-1.25,1.25.56,1.25,1.25,1.25,1.25-.56,1.25-1.25Z' />
      <path d='M27.07,32.48c-.69,0-1.25.56-1.25,1.25s.56,1.25,1.25,1.25,1.25-.56,1.25-1.25c0-.69-.57-1.25-1.25-1.25Z' />
      <path d='M34.98,26.65c0-.69-.56-1.25-1.25-1.25s-1.25.56-1.25,1.25.56,1.25,1.25,1.25,1.25-.56,1.25-1.25Z' />
      <circle cx='10.4' cy='10.41' r='1.25' />
      <circle cx='14.99' cy='11.24' r='1.25' />
      <circle cx='11.24' cy='14.57' r='1.25' />
      <path d='M9.99,5.41c0-.69-.56-1.25-1.25-1.25s-1.25.56-1.25,1.25.56,1.25,1.25,1.25,1.25-.57,1.25-1.25Z' />
      <path d='M6.65,9.58c0-.69-.56-1.25-1.25-1.25s-1.25.56-1.25,1.25.56,1.25,1.25,1.25,1.25-.57,1.25-1.25Z' />
      <path d='M7.07,12.49c-.69,0-1.25.56-1.25,1.25s.56,1.25,1.25,1.25,1.25-.56,1.25-1.25-.56-1.25-1.25-1.25Z' />
      <path d='M14.99,6.66c0-.69-.56-1.25-1.25-1.25s-1.25.56-1.25,1.25.56,1.25,1.25,1.25,1.25-.56,1.25-1.25Z' />
    </svg>
  )

  const chartSVG = (
    <svg
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 40 40'
      style={{ overflow: 'visible' }}
    >
      <path d='M35.2,0H4.8C2.15,0,0,2.15,0,4.8v30.4c0,2.65,2.15,4.8,4.8,4.8h30.4c2.65,0,4.8-2.15,4.8-4.8V4.8c0-2.65-2.15-4.8-4.8-4.8ZM38.33,35.18c0,1.75-1.4,3.15-3.13,3.15H4.8c-1.73,0-3.13-1.4-3.13-3.13v-3.53h2.71c.21,0,.41-.08.57-.23l1.91-1.77,2.77,4.34c.13.22.37.36.62.38h.08c.23,0,.44-.09.6-.26l2.4-2.48h2.95c.21,0,.41-.08.57-.23l1.91-1.77,2.77,4.34c.25.39.76.5,1.15.26.06-.03.11-.07.15-.13l2.4-2.49h2.29c.21,0,.41-.08.57-.23l1.91-1.77,2.77,4.35c.25.39.77.5,1.15.26.06-.03.11-.07.15-.13l2.4-2.49h1.89v3.53h0ZM38.33,30h-2.23c-.23,0-.44.09-.6.26l-1.91,1.98-2.73-4.29c-.25-.39-.76-.5-1.15-.26-.04.03-.08.06-.12.09l-2.41,2.22h-2.32c-.23,0-.44.09-.6.26l-1.91,1.98-2.73-4.29c-.25-.39-.76-.5-1.15-.26-.04.03-.08.06-.12.09l-2.41,2.22h-2.98c-.23,0-.44.09-.6.26l-1.91,1.98-2.73-4.29c-.25-.39-.76-.5-1.15-.26-.04.03-.08.06-.12.09l-2.41,2.22H1.67v-14.17h6.03c.28,0,.54-.14.7-.38l2.43-3.76,2.69,6.54c.13.31.43.52.77.52h.04c.34,0,.64-.22.77-.53l3.11-8.26,4.53,14.21c.11.34.43.58.79.58h.07c.37-.02.69-.29.77-.67l3.38-16.48,2.93,7.7c.13.33.43.53.78.53h6.88v14.17h0ZM38.33,14.17h-6.28l-3.76-9.87c-.17-.43-.64-.64-1.08-.48-.27.1-.46.33-.52.61l-3.32,16.22-4.33-13.5c-.11-.34-.42-.58-.77-.58-.36-.02-.68.21-.8.54l-3.22,8.55-2.5-6.07c-.18-.43-.66-.62-1.09-.45-.16.07-.29.17-.38.32l-3.06,4.72H1.67V4.8c0-1.73,1.4-3.13,3.13-3.13h30.4c1.73,0,3.13,1.4,3.13,3.13v9.37Z' />
    </svg>
  )

  const clipboardSVG = (
    <svg
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 30.83 40'
      style={{ overflow: 'visible' }}
    >
      <path d='M30,4.17h-6.74c-.17-.5-.64-.83-1.17-.83h-1.25V.83c0-.46-.38-.83-.83-.83h-9.17c-.46,0-.83.37-.83.83v2.5h-1.32c-.52,0-.97.34-1.12.83H.83c-.46,0-.83.37-.83.83v34.17c0,.46.37.83.83.83h29.17c.46,0,.83-.37.83-.83V5c0-.46-.38-.83-.83-.83ZM11.67,1.67h7.5v1.67h-7.5v-1.67ZM9.17,5h12.5v3.33h-12.5v-3.33ZM8.34,10h14.17c.46,0,.83-.37.83-.83h2.5v12.82h0l-.95-1.58c-.16-.27-.44-.42-.75-.4-.3,0-.57.19-.72.46l-.92,1.84-1.75-3.51c-.21-.41-.71-.58-1.12-.37-.22.11-.37.31-.43.54l-1.7,6.77-1.69-6.77c-.09-.37-.42-.63-.81-.63s-.72.27-.81.63l-.93,3.82-1.61-7.13c-.08-.37-.42-.64-.8-.65s-.72.25-.82.63l-1.81,7.22-.76-2.28c-.14-.43-.62-.67-1.05-.52-.13.04-.23.11-.32.2l-1.08,1.07v-12.16h2.5c0,.46.38.83.83.83ZM25.83,25.22v9.78H5v-11.32l1.3-1.3,1.24,3.72c.14.43.62.67,1.05.52.27-.09.47-.31.54-.59l1.65-6.6,1.62,7.17c.08.37.42.65.81.65.42.03.72-.26.82-.63l.97-3.99,1.68,6.73c.11.45.57.72,1.01.61.3-.07.53-.31.61-.61l1.95-7.82,1.49,2.98c.28.57,1.21.57,1.49,0l.99-1.97,1.6,2.67ZM29.17,38.33H1.67V5.83h5.83v1.67h-3.33c-.46,0-.83.37-.83.83v27.5c0,.46.37.83.83.83h22.5c.46,0,.83-.37.83-.83V8.33c0-.46-.37-.83-.83-.83h-3.33v-1.67h5.83v32.5Z' />
    </svg>
  )

  const crossSVG = (
    <svg
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 40 40'
      style={{ overflow: 'visible' }}
    >
      <path d='M22.11,40h-4.22c-2.95,0-5.34-2.39-5.34-5.34v-7.27l-7.2.06c-2.95,0-5.34-2.39-5.35-5.34v-4.22c0-2.95,2.39-5.34,5.35-5.35h7.26l-.06-7.2c0-2.95,2.39-5.34,5.34-5.34h4.22c2.95,0,5.34,2.39,5.34,5.34v7.27l7.2-.07c2.95,0,5.34,2.4,5.35,5.35v4.22c0,2.95-2.4,5.34-5.35,5.34h-7.26l.06,7.2c0,2.95-2.39,5.34-5.34,5.34ZM5.35,14.78c-1.71,0-3.1,1.39-3.11,3.11v4.22c0,1.71,1.39,3.1,3.11,3.1h7.26c1.2,0,2.18.97,2.18,2.17v7.27c0,1.71,1.39,3.1,3.1,3.1h4.22c1.71,0,3.1-1.39,3.1-3.1v-7.27c0-1.2.98-2.17,2.18-2.17h7.26c1.71,0,3.1-1.39,3.11-3.1v-4.22c0-1.72-1.39-3.11-3.11-3.11h-7.26c-1.2,0-2.17-.97-2.18-2.17v-7.27c0-1.71-1.39-3.1-3.1-3.1h-4.22c-1.71,0-3.1,1.39-3.1,3.1v7.27c0,1.2-.98,2.17-2.18,2.17h-7.26Z' />
    </svg>
  )

  const dnaSVG = (
    <svg
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 40 40'
      style={{ overflow: 'visible' }}
    >
      <path d='M9.15,39.19c-.35-.31.22-.96.45-1.38.7-1.26,1.23-2.63,1.4-3.77.15-.96.18-1.97.15-3.08,0-.37.3-.67.67-.65h0s.84.04.84.04c.32.02.58.28.58.6.03,1.21-.01,2.33-.18,3.42-.28,1.83-.9,3.53-2.14,5.36-.2.3-.62.37-.91.16l-.7-.54M5.17,29.23l4.44,4.45c.15.15.22.37.17.58l-.08.39c-.1.5-.72.68-1.07.32l-4.91-4.93c-.18-.18-.46-.24-.7-.14-.52.23-1.02.5-1.52.84-.28.19-.66.13-.87-.14l-.5-.65c-.22-.29-.15-.71.15-.92,3.74-2.55,7.73-2.34,11.73-2.13.47.02.95.05,1.33.07,1.2.05,2.4.07,3.57-.02.54-.04.78-.7.4-1.09l-3.01-3.02c-.15-.15-.22-.37-.17-.58l.08-.39c.1-.5.72-.68,1.07-.32l4.49,4.5c.18.18.44.24.67.15.77-.29,1.53-.69,2.27-1.21.32-.23.36-.69.08-.97l-6.86-6.88c-.27-.27-.72-.24-.95.06-1.1,1.4-1.65,2.91-1.89,4.5-.16,1.04-.19,2.14-.16,3.27,0,.37-.3.67-.67.65h-.11s-.09-.01-.14-.01c-.19,0-.38-.02-.57-.03-.33-.02-.6-.29-.6-.62-.03-1.21,0-2.4.18-3.57.34-2.24,1.18-4.36,3.03-6.3,1.97-2.08,4.08-3.16,6.26-3.71,1.54-.38,3.08-.49,4.63-.49.33,0,.61.26.63.6.01.23.03.45.05.69,0,.04,0,.09,0,.13.03.37-.27.68-.64.68-.64,0-1.28.01-1.91.06-.54.04-.78.7-.4,1.09l3.1,3.11c.15.15.22.37.17.58l-.08.39c-.1.5-.72.68-1.07.32l-4.58-4.59c-.18-.18-.44-.24-.67-.15-.8.31-1.58.72-2.35,1.28-.32.23-.36.69-.08.97l6.88,6.9c.27.27.71.24.95-.05,1.16-1.43,1.72-2.97,1.97-4.6.29-1.93.15-4.04,0-6.23-.17-2.47-.28-4.49.02-6.44.28-1.83.9-3.53,2.14-5.36.2-.3.62-.37.91-.16l.66.49c.27.2.34.59.15.87-1.03,1.54-1.55,2.96-1.78,4.47-.26,1.73-.16,3.64,0,5.97.16,2.31.31,4.54-.01,6.7-.34,2.24-1.18,4.36-3.03,6.3-1.97,2.08-4.08,3.16-6.26,3.71-2.14.53-4.29.53-6.45.44-.51-.02-.93-.04-1.35-.07-2.28-.12-4.56-.24-6.73.22h0ZM34.65,10.81l-4.28-4.29c-.15-.15-.22-.37-.17-.58l.08-.39c.1-.5.72-.68,1.07-.32l4.78,4.79c.18.18.46.24.69.14.57-.24,1.13-.54,1.68-.91.28-.19.66-.13.86.14l.5.65c.22.29.16.71-.15.91-2.81,1.91-5.76,2.27-8.75,2.25-.33,0-.61-.26-.63-.6-.02-.26-.04-.52-.05-.78,0-.02,0-.03,0-.05-.03-.37.27-.69.64-.68,1.26.01,2.51-.05,3.72-.29h0Z' />
    </svg>
  )

  const grailSVG = (
    <svg
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 30 40'
      style={{ overflow: 'visible' }}
    >
      <path d='M11.67,26.67c0,.5.44.9.96.83.42-.06.71-.43.71-.85v-5.05c5.9-.69,10.83-6.2,10.83-12.42,0-.47-.37-.84-.83-.84H.83c-.46,0-.83.38-.83.83,0,6.52,5.41,12.23,11.67,12.48v5.02h0ZM1.7,10h20.77c-.44,5.42-5.1,10-10.38,10S2.14,15.42,1.7,10Z' />
      <path d='M15.81,38.33h-2.48v-4.14c0-.42-.3-.79-.71-.85-.52-.07-.96.33-.96.83v4.17h-2.48c-.42,0-.79.3-.85.71-.08.52.33.96.83.96h6.67c.5,0,.9-.44.83-.96-.06-.41-.43-.71-.85-.71Z' />
      <path d='M28,4.79C24.23.42,16.02-.08,11.47,0c-1.13.03-2.66.23-3.45,1.12-.38.41-.56.96-.51,1.51.04.74.39,1.43.96,1.91,1,.85,2.75,1.16,5.33.93,3.56-.31,6.35-2.32,7.03-2.86,2.35.63,4.52,1.64,5.92,3.26,1.4,1.62,1.87,3.63,1.45,6.18-1.57,9.44-11.23,10.42-11.64,10.45-.46.02-.82.41-.8.87.02.46.41.82.87.8h.07c.12,0,11.36-1.11,13.13-11.83.51-3.03-.11-5.56-1.83-7.54ZM13.65,3.81c-2.6.23-3.67-.18-4.11-.54-.23-.19-.37-.47-.38-.77-.02-.09.02-.19.08-.26.29-.32,1.19-.55,2.25-.57.29,0,.59,0,.91,0,1.78,0,3.98.1,6.17.47-1.14.68-2.91,1.51-4.93,1.68Z' />
      <path d='M14.13,29.59c-5.32-.33-6.36-1.63-6.56-2.18-.43-1.14,1.07-2.63,1.08-2.65.33-.32.34-.85.03-1.18-.32-.33-.85-.34-1.18-.03-.09.09-2.3,2.24-1.5,4.43.7,1.92,3.33,2.98,8.03,3.28,4.56.28,5.64,1.43,5.89,2.09.45,1.23-1.03,3.02-1.58,3.55-.33.33-.33.86,0,1.18.32.32.83.33,1.15.03.3-.28,2.88-2.87,1.98-5.33-.7-1.88-3.11-2.92-7.36-3.18Z' />
    </svg>
  )

  const heartBeatSVG = (
    <svg
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 40 33.33'
      style={{ overflow: 'visible' }}
    >
      <path d='M37.85,0H2.15C.96,0,0,.96,0,2.15v29.04c0,1.19.96,2.15,2.15,2.15h35.7c1.19,0,2.15-.96,2.15-2.15V2.15c0-1.19-.96-2.15-2.15-2.15ZM38.33,31.18c0,.27-.22.48-.48.48H2.15c-.27,0-.48-.22-.48-.48v-13.68h5.98c.21,0,.42-.08.57-.23l2.27-2.15,1.96,5.35c.16.43.64.65,1.07.5.26-.09.45-.31.52-.58l2.71-10.87,1.24,18.84c.03.4.33.72.73.77.04,0,.07,0,.11,0,.36,0,.68-.23.79-.57l4.17-12.58,2.29,4.48c.14.28.4.44.76.45.32,0,.6-.19.74-.48l1.37-2.93h3.15c.39.52,1,.83,1.66.83,1.46,0,2.08-.62,2.08-2.08,0-1.15-.93-2.08-2.08-2.09-.99,0-1.85.7-2.04,1.67h-3.3c-.32,0-.62.19-.75.48l-.88,1.87-2.42-4.74c-.21-.41-.71-.57-1.12-.36-.2.1-.34.27-.41.48l-3.45,10.42-1.31-19.91c-.02-.46-.42-.81-.88-.79-.37.02-.68.28-.77.64l-3.33,13.41-1.46-3.98c-.16-.43-.64-.65-1.07-.5-.11.04-.2.1-.29.18l-2.96,2.8H1.67V2.15c0-.27.22-.48.48-.48h35.7c.27,0,.48.22.48.48v29.04h0Z' />
    </svg>
  )

  const heartSVG = (
    <svg
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 36.67 40'
      style={{ overflow: 'visible' }}
    >
      <path d='M36.53,28.47c-.6-8.63-1.65-17.24-7.2-19.55l-.92-.8c.32-1.27,1.29-3.42,1.7-4.24.19-.36.19-.79,0-1.15-1.17-2.09-3.95-2.12-4.1-2.12-.13,0-.28.03-.39.1-1.49.8-2.64,3.7-3.18,5.27-2-.6-4.55-.04-5.88.34l-1.21-5.07c-.04-.17-.13-.32-.27-.43-2.16-1.88-4.87-.1-6,1.1-.19.2-.27.48-.21.75l1.37,6.33-1.69,1.06c-.07.04-.13.1-.18.16l-.04.05c-2.41-2.22-4.38-3.43-5.83-3.59-.37-.04-.72.17-.87.51L.06,10.99c-.16.39,0,.83.36,1.04.03.02,3.03,1.73,5.45,3.84-.61,3.17-.21,6.55,1.2,10.07,3.65,9.11,14.39,11.56,18.42,12.49.29.07.55.13.75.18.66.17,1.33.37,1.99.58,1.36.42,2.68.82,3.83.82.83,0,1.57-.21,2.18-.77,1.78-1.63,2.83-3.17,2.29-10.76ZM33.09,38.02c-.74.68-2.5.13-4.37-.43-.68-.21-1.38-.42-2.08-.59-.22-.05-.48-.12-.78-.18-3.81-.87-13.92-3.18-17.24-11.48-1.35-3.38-1.7-6.59-1.03-9.56.07-.29-.03-.6-.25-.8-1.96-1.78-4.35-3.32-5.47-4l1.03-2.48c1.21.43,2.97,1.71,4.98,3.64.18.18.43.26.68.23.25-.03.47-.17.6-.38.18-.28.33-.47.41-.58l2.07-1.3c.3-.18.44-.54.37-.88l-1.39-6.44c.62-.54,2.06-1.58,3.18-.85l1.36,5.65c.11.45.56.73,1,.62.03,0,.07-.02.1-.03,1.17-.43,4.57-1.33,6.29-.35.4.23.91.09,1.14-.31.03-.06.06-.12.08-.18.51-1.73,1.58-4.33,2.48-5.04.49.04,1.68.24,2.28,1.05-.4.83-1.66,3.53-1.84,4.99-.03.28.07.55.28.73l1.39,1.2c.08.06.15.11.24.15,4.76,1.87,5.74,10.36,6.28,18.17.49,7.36-.51,8.27-1.79,9.44Z' />
    </svg>
  )

  const ivBagSVG = (
    <svg
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 25.61 40'
      style={{ overflow: 'visible' }}
    >
      <path d='M4.21,31.55h4.67v1.29c0,1,.82,1.82,1.82,1.82h1.49v4.72c0,.34.28.63.63.63s.63-.28.63-.63v-4.72h1.49c1,0,1.82-.82,1.82-1.82v-1.29h4.67c2.32,0,4.21-1.89,4.21-4.21,0-.85,0-19.57,0-19.75,0-2.32-1.89-4.21-4.21-4.21h-4.62c-.32-1.91-1.98-3.38-3.97-3.38s-3.66,1.47-3.97,3.38h-4.62C1.89,3.38,0,5.27,0,7.59c0,1.62,0,17.8,0,19.75,0,2.32,1.89,4.21,4.21,4.21h0ZM24.36,27.34c0,1.63-1.33,2.96-2.96,2.96H4.21c-1.63,0-2.96-1.33-2.96-2.96v-9.73c.55.1,1.14.15,1.82.15,1.74,0,2.61-.32,3.46-.64.78-.29,1.52-.56,3.03-.56s2.25.27,3.03.56c.85.31,1.73.64,3.47.64s2.61-.32,3.46-.64c.78-.29,1.52-.56,3.03-.56.81,0,1.39.08,1.81.18v10.6ZM15.48,32.84c0,.31-.26.57-.57.57h-4.22c-.31,0-.57-.26-.57-.57v-1.29h5.36v1.29ZM12.8,1.25c1.3,0,2.41.91,2.7,2.13h-5.4c.3-1.22,1.4-2.13,2.7-2.13h0ZM4.21,4.63h17.19c1.63,0,2.96,1.33,2.96,2.96v7.89c-.55-.1-1.13-.16-1.81-.16-1.74,0-2.62.32-3.46.64-.78.29-1.52.56-3.03.56s-2.25-.27-3.03-.56c-.85-.31-1.73-.64-3.46-.64s-2.61.32-3.46.64c-.78.29-1.52.56-3.03.56-.82,0-1.39-.08-1.82-.18v-2.11h1.83c.35,0,.63-.28.63-.63s-.28-.63-.63-.63h-1.83v-1.56h1.83c.35,0,.63-.28.63-.63s-.28-.63-.63-.63h-1.83v-1.56h1.83c.35,0,.63-.28.63-.63s-.28-.63-.63-.63h-1.8c.13-1.51,1.39-2.71,2.93-2.71h0Z' />
    </svg>
  )

  const lungsSvg = (
    <svg fill={color} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 37.5'>
      <path d='M39.45,21.92C38.12,8.35,30.77,1.36,27.92,1.36c-1.87,0-3.25,2.98-4.13,8.86-.03.2-.37.45-.69.41-.32-.04-.61-.29-.61-.47V2.46c0-1.54-.92-2.46-2.49-2.46s-2.5.92-2.5,2.46v7.69c0,.18-.3.45-.57.47h-.17c-.27.02-.53-.16-.57-.4-.88-5.67-2.27-8.55-4.1-8.55C9.23,1.67,1.87,8.67.56,22.24c-1.12,11.48-.44,14.21,1.11,15.03.28.15.6.22.92.22,1.2,0,2.56-1.03,4.14-2.34.9-.75,2.47-1.67,3.92-1.79.35-.03.7-.03,1.05,0,1.01.04,2.27.08,3.3-.92,1.56-1.5,2.22-5.03,2.22-11.8,0-.81,0-1.2-.03-1.62-.03-.41.23-.77.62-.9.48-.14.99-.22,1.5-.22.73,0,2.47.13,2.79.24.38.12.64.48.62.88,0,.32-.02.64-.02,1.3,0,6.75.67,10.27,2.25,11.79,1.04,1.01,2.32.97,3.34.92.35-.03.7-.03,1.05,0,1.46.13,3.02,1.05,3.92,1.79,2,1.66,3.64,2.87,5.06,2.12,1.54-.82,2.22-3.54,1.11-15.03ZM37.57,35.52h0c-.55.28-2.55-1.37-3.22-1.92-1.13-.94-3.01-2.01-4.85-2.17-.42-.03-.84-.03-1.26,0-.98.04-1.62.03-2.13-.47-1.17-1.12-1.74-4.6-1.74-10.61,0-.62,0-.94.02-1.24.04-1.16-.69-2.2-1.79-2.55-.65-.2-2.72-.32-3.29-.32-.67,0-1.32.1-1.97.29-1.13.33-1.88,1.4-1.82,2.57.02.4.03.78.03,1.55,0,6.03-.56,9.51-1.72,10.62-.5.48-1.09.49-2.09.45-.42-.03-.84-.03-1.26,0-1.83.16-3.71,1.22-4.85,2.17-.66.55-2.67,2.21-3.22,1.92-.67-.36-1.25-2.97-.24-13.43C3.43,9.45,10.48,3.31,12.07,3.31c.22,0,1.53,1.24,2.46,7.16.18,1.12,1.19,1.91,2.32,1.82h.17c1.18-.11,2.14-1.07,2.14-2.15V2.46c0-.61.19-.8.83-.8s.83.18.83.8v7.67c0,1.08.96,2.05,2.14,2.14,1.18.1,2.32-.74,2.48-1.82.85-5.69,2.12-7.46,2.48-7.46,1.58,0,8.62,6.14,9.88,19.1,1.02,10.46.42,13.07-.24,13.43Z' />
    </svg>
  )

  const microscopeSVG = (
    <svg
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 26.17 40'
      style={{ overflow: 'visible' }}
    >
      <path d='M25.33,33.08h-5.06l-1.39-3.42c.85-.56,1.59-1.26,2.21-2.08,1.17-1.56,1.79-3.42,1.79-5.37,0-2.34-.9-4.56-2.54-6.24l-7.01-7.19.61-2.29c.12-.45-.15-.91-.6-1.03l-.17-.05,1.05-3.67c.06-.22.03-.45-.08-.65-.11-.2-.3-.34-.52-.4L11.07.03c-.45-.12-.9.14-1.03.59l-1.06,3.7-.27-.07c-.22-.06-.45-.03-.64.09s-.34.3-.39.51l-3.39,12.75c-.06.22-.03.45.09.64.11.19.3.34.51.39l.26.07-.74,2.59c-.06.22-.03.45.08.65.11.2.3.34.52.4l2.54.68c.07.02.15.03.22.03.37,0,.71-.24.81-.61l.75-2.62.18.05c.07.02.15.03.22.03.37,0,.72-.25.82-.63l.35-1.33c.17.03.34.05.51.05.75,0,1.44-.28,1.96-.75l.08.09c1.14,1.25,1.48,3.01.88,4.59-.65,1.73-2.33,2.89-4.18,2.89-.25,0-.51-.02-.76-.06l-5.88-1.02c-.23-.04-.47.02-.65.16-.19.14-.31.35-.33.59l-.35,3.29c-.05.42.23.81.64.91l8.98,2.23c.38.09.76.16,1.15.21v1.99H.85c-.47,0-.85.38-.85.85v3.05c0,1.67,1.36,3.02,3.02,3.02h1.99c1.06,0,2.05-.56,2.59-1.47.24-.4.68-.65,1.14-.65h8.68c.47,0,.9.25,1.14.65.54.91,1.54,1.47,2.59,1.47h1.99c1.67,0,3.02-1.36,3.02-3.02v-3.05c0-.47-.38-.85-.85-.85ZM11.44,1.88l.91.24-.82,2.87-.91-.24.82-2.87ZM7.18,21.16l-.91-.24.51-1.79.91.24-.51,1.79ZM9.13,18l-2.98-.79,2.95-11.12,2.98.79-1.41,5.32c-1.26.33-2.19,1.48-2.19,2.84,0,.82.34,1.56.88,2.09l-.23.87ZM11.41,16.28c-.68,0-1.24-.56-1.24-1.24s.56-1.24,1.24-1.24,1.24.56,1.24,1.24-.56,1.24-1.24,1.24ZM12.19,29.25l-8.27-2.06.18-1.66,5,.86c.35.06.7.09,1.05.09,2.55,0,4.86-1.6,5.76-3.99.82-2.18.36-4.61-1.21-6.33l-.43-.47c.05-.21.08-.43.08-.66,0-1.27-.81-2.35-1.94-2.76l.42-1.58,6.31,6.47c1.33,1.36,2.06,3.16,2.06,5.06,0,1.58-.5,3.09-1.45,4.35-.43.57-.94,1.07-1.51,1.49l-.18-.44c-.41-1-1.37-1.65-2.45-1.65-1.46,0-2.65,1.19-2.65,2.65v.77c-.25-.04-.5-.08-.74-.14ZM14.63,28.62c0-.53.43-.96.96-.96.39,0,.74.23.89.6l1.96,4.82h-3.81v-4.46h0ZM24.48,36.98c0,.73-.6,1.33-1.33,1.33h-1.99c-.47,0-.9-.25-1.14-.65-.54-.91-1.54-1.47-2.59-1.47h-8.68c-1.06,0-2.05.56-2.59,1.47-.24.4-.68.65-1.14.65h-1.99c-.73,0-1.33-.6-1.33-1.33v-2.2h22.79v2.2Z' />
    </svg>
  )

  const misheSVG = (
    <svg
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 31.34 40'
      style={{ overflow: 'visible' }}
    >
      <path d='M27.97,32.93c-.19.01-.37.02-.56.02-.9,0-1.76-.14-2.58-.39,0,0,0,0,0,0-1.47-.46-2.78-1.3-3.82-2.4-.81-.85-1.45-1.86-1.89-2.96-.38-.94-.59-1.95-.66-3.02,0-.19.02-.37.02-.56,0-.17-.02-.33-.02-.5.07-1.4.42-2.71,1.02-3.88.67-1.31,1.65-2.46,2.85-3.31,0,0,0,0,0,0,1.44-1.03,3.17-1.63,5.07-1.63,1.76,0,3.18-1.58,3.18-3.53s-1.42-3.53-3.18-3.53c-3.13,0-6.07,1.01-8.51,2.76,0,0,0,0,0,0-1.38.98-2.58,2.22-3.6,3.63-2.22-3.08-5.42-5.26-9.07-6.06,0,0,0,0,0,0-.99-.21-2.01-.32-3.05-.32-1.76,0-3.18,1.58-3.18,3.53s1.42,3.53,3.18,3.53c.63,0,1.25.07,1.84.2,0,0,0,0,0,0,2.07.45,3.86,1.64,5.13,3.26.63.81,1.13,1.73,1.47,2.72.27.78.44,1.6.5,2.45,0,.23-.02.47-.02.7,0,.19.02.37.03.56-.05.95-.23,1.86-.54,2.71-.36,1.02-.9,1.96-1.57,2.78,0,0,0,0,0,0-1.48,1.81-3.63,3.04-6.08,3.25,0,0,0,0,0,0-.09,0-.16.01-.22.02-1.75.13-3.08,1.81-2.96,3.75.11,1.86,1.51,3.29,3.17,3.29.07,0,.14,0,.22,0,.1,0,.2-.02.28-.02,0,0,0,0,0,0,0,0,0,0,0,0,4.08-.35,7.73-2.41,10.29-5.54,0,0,0,0,0,0,.2-.25.39-.52.58-.78.42.58.85,1.13,1.34,1.64h0s0,0,0,0c1.79,1.9,4.01,3.32,6.49,4.09,0,0,0,0,0,0,0,0,0,0,0,0,1.36.42,2.8.65,4.29.65.31,0,.62-.01.93-.03,1.75-.11,3.09-1.78,2.99-3.73-.1-1.95-1.61-3.43-3.36-3.32Z' />
      <path d='M14.45,7.9c.2,0,.4-.03.59-.07.19.04.39.07.59.07,1.97,0,3.56-1.77,3.56-3.95s-1.59-3.95-3.56-3.95c-.2,0-.4.03-.59.07-.19-.04-.39-.07-.59-.07-1.97,0-3.56,1.77-3.56,3.95s1.59,3.95,3.56,3.95Z' />
    </svg>
  )

  const pillBottleSVG = (
    <svg
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 33.33 40'
      style={{ overflow: 'visible' }}
    >
      <path d='M29.83,0H3.31C1.52,0,0,1.59,0,3.48v3.19c0,.46.37.83.83.83h.83v27.21c0,3.26,2.17,5.29,5.67,5.29h18.46c3.57,0,5.87-2.07,5.87-5.29V7.5h.83c.46,0,.83-.37.83-.83v-3.19c0-1.82-1.67-3.48-3.5-3.48ZM30,34.71c0,2.27-1.57,3.62-4.21,3.62H7.33c-1.49,0-4-.47-4-3.62V7.5h26.67v27.21h0ZM31.67,5.83H1.67v-2.36c0-.96.77-1.81,1.64-1.81h26.52c.92,0,1.83.9,1.83,1.81v2.36h0Z' />
      <path d='M5.83,33.33h21.67c.46,0,.83-.37.83-.83V11.67c0-.46-.37-.83-.83-.83H5.83c-.46,0-.83.37-.83.83v20.83c0,.46.37.83.83.83ZM6.67,12.5h20v19.17H6.67V12.5Z' />
      <path d='M24.17,18.33h-3.33v-3.33c0-.46-.37-.83-.83-.83h-6.67c-.46,0-.83.37-.83.83v3.33h-3.33c-.46,0-.83.37-.83.83v5.83c0,.46.37.83.83.83h3.33v3.33c0,.46.37.83.83.83h6.67c.46,0,.83-.37.83-.83v-3.33h3.33c.46,0,.83-.37.83-.83v-5.83c0-.46-.37-.83-.83-.83ZM23.33,24.17h-3.33c-.46,0-.83.37-.83.83v3.33h-5v-3.33c0-.46-.37-.83-.83-.83h-3.33v-4.17h3.33c.46,0,.83-.37.83-.83v-3.33h5v3.33c0,.46.37.83.83.83h3.33v4.17Z' />
    </svg>
  )

  const pillsSVG = (
    <svg
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 40 40'
      style={{ overflow: 'visible' }}
    >
      <path d='M30,0c-5.52,0-10,4.66-10,10.38v19.23c0,5.73,4.48,10.38,10,10.38s10-4.66,10-10.38V10.38c0-5.73-4.49-10.38-10-10.38ZM38.33,29.62c0,4.81-3.74,8.72-8.33,8.72s-8.33-3.91-8.33-8.72v-8.78h16.67v8.78h0ZM38.33,19.17h-16.67v-8.78c0-4.81,3.74-8.72,8.33-8.72s8.33,3.91,8.33,8.72v8.78Z' />
      <path d='M9.17,21.67c-5.06,0-9.17,4.1-9.17,9.17s4.1,9.17,9.17,9.17,9.17-4.1,9.17-9.17c0-5.06-4.11-9.16-9.17-9.17ZM9.17,38.33c-4.14,0-7.5-3.36-7.5-7.5s3.36-7.5,7.5-7.5,7.5,3.36,7.5,7.5c0,4.14-3.36,7.5-7.5,7.5Z' />
      <path d='M1.1,13.51s0,.01,0,.02.01.02.02.03c2.41,4.45,7.97,6.1,12.42,3.69,4.32-2.34,6.02-7.67,3.86-12.08,0-.02,0-.04-.02-.07,0-.02-.02-.02-.03-.04C15.06.54,9.55-1.27,5.04,1.01.52,3.29-1.29,8.8,1,13.32c.03.06.06.13.1.19ZM16.22,6.63c1.41,3.88-.59,8.17-4.47,9.59-.83.3-1.7.45-2.58.45-2.45,0-4.74-1.2-6.14-3.2l13.19-6.83ZM6.32,2.23c.9-.37,1.87-.56,2.85-.56,2.56,0,4.94,1.31,6.31,3.47L2.23,12c-1.57-3.83.26-8.2,4.09-9.77Z' />
    </svg>
  )

  const stethoscopeSVG = (
    <svg
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 34.26 40'
      style={{ overflow: 'visible' }}
    >
      <path d='M32.77,30.85h.02c1.03-1.97.74-4.5-.76-6.01-.91-.92-2.16-1.44-3.45-1.44h-.03c-1.29,0-2.53.51-3.44,1.43l-8.99,8.01c-1.4,1.43-3.02,2.18-4.65,2.18s-3.07-.7-4.38-2.02c-1.05-1.07-1.81-4.16-2.01-6.5,4.52,3.01,10.54,2.41,14.37-1.43l7.77-7.77c1.61-1.61,1.61-4.23,0-5.85l-.05-.05c.8-1.19.43-2.83-.83-3.57-1.08-.63-2.49-.35-3.25.64-.89,1.16-.6,2.81.59,3.61.41.28.89.43,1.4.43.24,0,.48-.04.72-.12l.23.23c.96.96.96,2.52,0,3.48l-7.77,7.77c-1.35,1.35-3.06,2.27-4.92,2.65-.03-.14-.09-.27-.18-.38L2.28,14.48c-.07-.07-.14-.12-.23-.16.45-1.57,1.29-3,2.46-4.16l7.77-7.77c.93-.93,2.55-.93,3.48,0l.23.23c-.4,1.32.35,2.72,1.67,3.12.89.27,1.84.03,2.49-.63.98-.99.97-2.57,0-3.54-.84-.84-2.16-.97-3.16-.31l-.05-.05c-1.61-1.61-4.23-1.61-5.85,0l-7.77,7.77c-4.44,4.44-4.44,11.66,0,16.11h0c-.05,2.31.75,7.27,2.56,9.1,1.62,1.65,3.55,2.52,5.57,2.52s4.12-.93,5.81-2.64l9-8.01c.6-.62,1.43-.97,2.29-.97s1.67.34,2.27.95c.94.94,1.15,2.51.6,3.79-2.7-1.47-6.1-.4-7.45,2.4-1.21,2.5-.31,5.55,2.06,6.98,2.66,1.6,6.09.66,7.56-2.05,1.12-2.06.79-4.62-.84-6.32ZM25.65,10.64c-.32.33-.84.33-1.18,0-.33-.33-.33-.85,0-1.18.33-.33.85-.33,1.18,0s.33.85,0,1.18ZM17.8,2.78c.15-.16.37-.24.58-.24.46,0,.84.38.84.84,0,.22-.09.43-.24.58-.33.33-.85.33-1.18,0-.33-.33-.33-.85,0-1.18ZM1.68,17.07c0-.25.02-.5.03-.74l9.68,10.43h0c-5.36,0-9.69-4.34-9.7-9.68ZM31.58,37.27l-.03.03c-1.47,1.47-3.85,1.47-5.31,0s-1.47-3.85,0-5.31c1.47-1.47,3.85-1.47,5.31,0,1.47,1.45,1.49,3.82.03,5.28Z' />
    </svg>
  )

  const syringeSVG = (
    <svg
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 40 40'
      style={{ overflow: 'visible' }}
    >
      <path d='M21.5,5.46c-.35-.33-.9-.32-1.23.02-.32.34-.32.87,0,1.21l1.9,1.9L4.87,25.91c-.73.73-.5,1.52-.32,2.1.03.1.07.22.1.35l1.2,4.58L.27,38.51c-.35.33-.36.89-.02,1.23.34.34.89.36,1.23.02,0,0,.02-.02.02-.02l5.55-5.54,4.64,1.2c.13.03.26.07.37.1.29.1.58.15.89.16.43,0,.85-.17,1.16-.49l17.31-17.32,1.9,1.9c.35.33.9.32,1.23-.02.32-.34.32-.87,0-1.21l-13.04-13.05ZM12.94,33.91s-.26-.05-.4-.1-.3-.09-.44-.11l-4.42-1.14c-.03-.05-.05-.1-.09-.14-.03-.03-.06-.04-.1-.06l-1.17-4.44c-.03-.15-.08-.28-.11-.4-.04-.16-.1-.37-.11-.38l1.65-1.66,2.96,2.96c.35.33.9.32,1.23-.02.32-.34.32-.87,0-1.21l-2.96-2.96,2.37-2.37c.03.17.1.34.23.47l1.74,1.74c.35.33.9.32,1.23-.02.32-.34.32-.87,0-1.21l-1.74-1.74c-.13-.12-.29-.2-.47-.23l2.49-2.49c.03.17.1.34.23.47l2.61,2.61c.35.33.9.32,1.23-.02.32-.34.32-.87,0-1.21l-2.61-2.61c-.13-.12-.3-.2-.47-.23l1.93-1.94,1.65,1.65c.35.33.9.32,1.23-.02.32-.34.32-.87,0-1.21l-1.65-1.65,1.82-1.82,2.09,2.09c.35.33.9.32,1.23-.02.32-.34.32-.87,0-1.21l-2.09-2.09,1.38-1.38,6.78,6.78-17.22,17.29Z' />
      <path d='M39.76,8.94L31.06.24c-.35-.33-.9-.32-1.23.02-.32.34-.32.87,0,1.21l1.99,2-4.6,4.6c-.35.33-.36.89-.02,1.23.33.35.89.36,1.23.02,0,0,.02-.02.02-.02l4.6-4.6,2.25,2.25-4.6,4.6c-.35.33-.36.89-.02,1.23.33.35.89.36,1.23.02,0,0,.02-.02.02-.02l4.6-4.6,1.99,1.99c.35.33.9.32,1.23-.02.33-.34.33-.87,0-1.21Z' />
    </svg>
  )

  const testTubeSVG = (
    <svg
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 20.83 40'
      style={{ overflow: 'visible' }}
    >
      <path d='M20,0H.83c-.46,0-.83.37-.83.83s.37.83.83.83h2.5v30.88c0,4.25,3.04,7.45,7.08,7.45s7.08-3.2,7.08-7.45V1.67h2.5c.46,0,.83-.37.83-.83,0-.46-.37-.83-.83-.83ZM15.83,32.55c0,3.3-2.33,5.78-5.42,5.78s-5.42-2.49-5.42-5.78v-8.38h1.67c.46,0,.83-.37.83-.83s-.37-.83-.83-.83h-1.67v-3.33h4.17c.46,0,.83-.37.83-.83s-.37-.83-.83-.83h-4.17v-4.17h1.67c.46,0,.83-.37.83-.83s-.37-.83-.83-.83h-1.67v-3.33h4.17c.46,0,.83-.37.83-.83s-.37-.83-.83-.83h-4.17V1.67h10.83v30.88h0Z' />
    </svg>
  )

  const thermometerSVG = (
    <svg
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 17.14 40'
      style={{ overflow: 'visible' }}
    >
      <path d='M13.4,24.35V4.83c0-2.66-2.17-4.83-4.83-4.83S3.74,2.17,3.74,4.83v19.52C1.33,26-.09,28.79,0,31.73c.15,4.4,3.71,8.03,8.1,8.26.16,0,.31.01.47.01,2.21,0,4.28-.83,5.89-2.35,1.72-1.64,2.67-3.84,2.67-6.22,0-2.86-1.39-5.48-3.74-7.08ZM13.53,36.66c-1.45,1.37-3.35,2.07-5.35,1.97-3.7-.19-6.69-3.25-6.81-6.95-.09-2.58,1.22-5.02,3.42-6.38.2-.12.32-.34.32-.58V4.83c0-1.91,1.56-3.47,3.47-3.47s3.47,1.56,3.47,3.47v19.89c0,.24.12.45.32.58,2.14,1.33,3.42,3.62,3.42,6.13,0,2-.8,3.86-2.25,5.23Z' />
      <path d='M14.58,29.51c-.11-.36-.5-.56-.85-.44-.36.11-.56.5-.44.85.15.48.23.99.23,1.5,0,1.32-.51,2.56-1.45,3.5-1,1-2.34,1.52-3.77,1.44-2.53-.13-4.58-2.23-4.67-4.76-.06-1.77.84-3.45,2.34-4.38l1.07-.66c.2-.12.32-.34.32-.58V5.89h2.4v20.09c0,.24.12.45.32.58l1.07.66c.32.2.74.1.94-.22.2-.32.1-.74-.22-.94l-.75-.47V5.21c0-.38-.3-.68-.68-.68h-3.76c-.38,0-.68.3-.68.68v20.39l-.75.47c-1.92,1.19-3.06,3.33-2.99,5.58.11,3.24,2.73,5.9,5.96,6.07.11,0,.23,0,.34,0,1.68,0,3.27-.66,4.46-1.85,1.19-1.19,1.85-2.77,1.85-4.46,0-.65-.1-1.3-.29-1.92Z' />
      <path d='M12.97,28.6c.18,0,.35-.07.48-.2.25-.26.27-.67.02-.94-.21-.23-.56-.29-.84-.14-.27.15-.41.49-.32.79.09.29.35.49.65.49Z' />
    </svg>
  )

  const toothSVG = (
    <svg
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 29.17 40'
      preserveAspectRatio='xMinYMin slice'
      style={{ overflow: 'visible' }}
    >
      <path d='M26.09,19.19c2.08-4.68,4.01-11.44,2.59-15.08C26.67-1.04,22.37-.19,18.58.56c-1.35.27-2.75.54-4,.54s-2.65-.27-4-.54C6.79-.19,2.49-1.03.49,4.12c-1.42,3.63.51,10.39,2.59,15.07.06.13.08.27.07.42-.2,1.96-.3,3.92-.29,5.9,0,5.37.82,14.48,3.86,14.48,2.61,0,2.97-3.46,3.35-7.12.54-5.27,1.19-9.01,4.52-9.01s3.92,3.7,4.46,8.92c.38,3.71.74,7.21,3.42,7.21,3.05,0,3.87-9.11,3.87-14.48,0-1.97-.09-3.93-.29-5.9-.02-.14,0-.28.06-.42ZM15.48,2.74c1.13-.08,2.29-.31,3.42-.53,4.17-.82,6.79-1.14,8.22,2.52.98,2.52-.07,8.19-2.56,13.8-.17.4-.25.83-.2,1.27.19,1.9.28,3.81.28,5.72,0,7.92-1.44,12.48-2.2,12.8-1.17,0-1.48-3.04-1.76-5.72-.48-4.64-1.07-10.41-6.12-10.41s-5.7,5.82-6.18,10.5c-.27,2.64-.58,5.63-1.65,5.63-.8-.32-2.23-4.87-2.23-12.82,0-1.91.09-3.82.28-5.72.04-.43-.02-.87-.2-1.27C2.1,12.91,1.05,7.24,2.04,4.72c.92-2.34,2.31-3.06,4.28-3.06,1.12,0,2.42.23,3.94.53,1.15.22,2.33.45,3.48.53h1.74Z' />
    </svg>
  )

  const svgs = [
    { svg: misheSVG, widthPercent: '0.7835' },
    { svg: stethoscopeSVG, widthPercent: '0.8565' },
    { svg: syringeSVG, widthPercent: '1' },
    { svg: microscopeSVG, widthPercent: '0.65425' },
    { svg: crossSVG, widthPercent: '1' },
    { svg: grailSVG, widthPercent: '0.75' },
    { svg: pillBottleSVG, widthPercent: '0.83325' },
    { svg: toothSVG, widthPercent: '0.72925' },
    { svg: heartSVG, widthPercent: '0.91675' },
    { svg: bandaidSVG, widthPercent: '1' },
    { svg: ambulanceSVG, widthPercent: '1', paddingTop: '2px' },
    { svg: ivBagSVG, widthPercent: '0.64025' },
    { svg: heartBeatSVG, widthPercent: '1', paddingTop: '2px' },
    { svg: dnaSVG, widthPercent: '1' },
    { svg: lungsSvg, widthPercent: '1' },
    { svg: testTubeSVG, widthPercent: '0.52075' },
    { svg: pillsSVG, widthPercent: '1' },
    { svg: chartSVG, widthPercent: '1' },
    { svg: thermometerSVG, widthPercent: '0.43' },
    { svg: clipboardSVG, widthPercent: '0.77075' },
  ]

  const size = 28
  const gap = 14
  const numberOfColumns = Math.floor(width / (size + gap))
  const numberOfRows = Math.floor(height / (size + gap))
  const numberOfIcons = numberOfColumns * numberOfRows

  return (
    <Box
      display='flex'
      flexWrap='wrap'
      width='100%'
      height='100%'
      gap={`${gap}px`}
      justifyContent='center'
      alignItems='center'
    >
      {Array.from({ length: numberOfIcons }).map((_, i) => {
        const svg = svgs[i % svgs.length]
        const calculatedWidth = `${Math.ceil(parseFloat(svg.widthPercent) * size)}px`
        return (
          <Box
            key={i}
            display='flex'
            alignItems='center'
            justifyContent='center'
            width={`${size}px`}
            height={`${size}px`}
            paddingTop={svg.paddingTop ? svg.paddingTop : '0'}
          >
            <Box width={calculatedWidth}>{svg.svg}</Box>
          </Box>
        )
      })}
    </Box>
  )
}

export default MisheLoginBackgroundBuilderDynamic
