import { useEffect, useState } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { collection } from '../firebase/firestore'

const fetchCollection = async (slug) => collection('blogs').where('slug', '==', slug).limit(1).get()

const BlogFunction = ({ children }) => {
  const navigate = useNavigate()
  const { slug } = useParams()
  const { search } = useLocation()

  const [blog, setBlog] = useState({})

  const ref = new URLSearchParams(search).get('ref')
  if (ref) {
    localStorage.setItem('refvalue', ref)
  }

  useEffect(() => {
    fetchCollection(slug)
      .then((snapshot) => {
        if (!snapshot.empty) {
          snapshot.docs.forEach((doc) => {
            const { content, authorImage, ...data } = doc.data()
            let authorImageURL = false
            if (authorImage && Array.isArray(authorImage)) {
              authorImageURL = authorImage[0]?.downloadURL
            }
            setBlog({
              content,
              authorImageURL,
              ...data,
              uid: doc.id,
            })
          })
        } else {
          // redirect to landing page if no organization found
          navigate('/')
        }
      })
      .catch((error) => {
        console.log('******** There was an error ********')
        console.log(error.message)
        setBlog({
          error,
        })
      })
  }, [slug, navigate])

  return children(blog)
}

BlogFunction.propTypes = {
  children: PropTypes.node.isRequired,
}

export default BlogFunction
