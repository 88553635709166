import { useState } from 'react'
import { Box, Collapse } from '@mui/material'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import Typography from '../stories/Typography'
import GoogleMap from '@app/components/GoogleMap'

import InstantSearchContainerCustom from '@app/containers/InstantSearchContainerCustom'
import PharmacySearchCard from './PharmacySearchCard'

const PlanMedicationLanderMap = ({
  pharmacySelected,
  openPharmacyMap,
  alreadyHavePrescription,
  setPharmacySelected,
  boxRef,
  setActiveStep,
  activeStep,
}) => {
  const [locationsFromSearch, setLocationsFromSearch] = useState([])
  // const [pharmacySelected, setPharmacySelected] = useState(null)

  const handleSelectPharmacy = (pharmacy) => {
    setPharmacySelected(pharmacy)
    if (boxRef.current) {
      boxRef.current.scrollTop = 0
    }
  }
  return (
    <Collapse in={openPharmacyMap} timeout={500} unmountOnExit mountOnEnter>
      <Box width='100%'>
        <Grid container width='100%' id='serviceCards' spacing={2}>
          <Grid item xs={12}>
            <Box position='relative'>
              <InstantSearchContainerCustom
                HitComponent={PharmacySearchCard}
                hitClick={handleSelectPharmacy}
                showInitialHits
                indexName='pharmacies'
                showZipcode
                geoSearch
                configuration={{ hitsPerPage: 25 }}
                searchBoxText='Search for a pharmacy'
                noResultsMessage='No results'
                exportFunction={setLocationsFromSearch}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            {/* Desktop Service Cards */}
            <Box
              ref={boxRef}
              overflow='scroll'
              borderRadius='8px'
              sx={{
                height: {
                  xs: '40vh',
                  sm: '70vh',
                },
              }}
            >
              {locationsFromSearch.length > 0 ? (
                <>
                  {pharmacySelected && (
                    <PharmacySearchCard
                      locationID={pharmacySelected.objectID}
                      key={pharmacySelected.objectID}
                      data={pharmacySelected}
                      selected={pharmacySelected}
                      onClick={handleSelectPharmacy}
                      handleSwap={setActiveStep}
                      alreadyHavePrescription={alreadyHavePrescription}
                      activeStep={activeStep}
                    />
                  )}
                  {locationsFromSearch
                    .filter((location) => location !== pharmacySelected)
                    .map((location) => (
                      <PharmacySearchCard
                        locationID={location.objectID}
                        key={location.objectID}
                        data={location}
                        selected={pharmacySelected}
                        onClick={handleSelectPharmacy}
                        handleSwap={setActiveStep}
                        alreadyHavePrescription={alreadyHavePrescription}
                        activeStep={activeStep}
                      />
                    ))}
                </>
              ) : (
                <Typography variant='h4' align='center' color='textSecondary'>
                  Search for a pharmacy to see results.
                </Typography>
              )}
            </Box>
          </Grid>
          {/* Desktop Map */}
          <Grid item xs={12} sm={8} md={8} lg={8}>
            {locationsFromSearch && (
              <Box width='100%' borderRadius='8px' overflow='hidden'>
                <GoogleMap
                  clinics={locationsFromSearch}
                  displayHeight='70vh'
                  pharmacyPage
                  locationSelected={pharmacySelected}
                  handleSelectLocation={handleSelectPharmacy}
                  handleSwap={setActiveStep}
                  activeStep={activeStep}
                />
              </Box>
            )}
          </Grid>
          {/* END Desktop Map */}
        </Grid>
      </Box>
    </Collapse>
  )
}

PlanMedicationLanderMap.propTypes = {
  medicationData: PropTypes.array,
}

export default PlanMedicationLanderMap
