import { Box, Collapse } from '@mui/material'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'

import QuickCheckoutContainer from '@app/containers/QuickCheckoutContainerMedication'
import QuickCheckoutMedication from './QuickCheckoutMedication'
import LoadingBar from '../stories/RandomLoadingBar'

const PlanMedicationLanderPayment = ({
  medicationInfo,
  paymentIntent,
  medicationRequested,
  deliveryMethod,
  pharmacySelected,
  openCheckout,
  alreadyHavePrescription,
  setOrderComplete,
}) => {
  return (
    <>
      {/* Desktop Service Cards Container */}
      <Box width='100%'>
        {/* payment form */}
        <Box marginTop='16px'>
          <Collapse in={!paymentIntent && openCheckout} timeout={500} mountOnEnter unmountOnExit>
            <Box width='100%'>
              <Grid container width='100%' id='serviceCards' spacing={2}>
                <Grid item xs={12}>
                  <Box position='relative'>
                    <LoadingBar
                      speed={1}
                      customWording='Just a moment while we load the checkout...'
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
          <Collapse in={paymentIntent && openCheckout} timeout={500} mountOnEnter unmountOnExit>
            <Box width='100%'>
              <Grid container width='100%' id='serviceCards'>
                <Grid item xs={12}>
                  <Box position='relative'>
                    <QuickCheckoutContainer
                      clientSecret={paymentIntent?.secret}
                      paymentIntent={paymentIntent?.paymentIntentId}
                    >
                      <QuickCheckoutMedication
                        orderCompletionCallback={setOrderComplete}
                        deliveryMethod={deliveryMethod}
                        hasScript={alreadyHavePrescription}
                        paymentIntentID={paymentIntent?.paymentIntentId}
                        medicationRequested={medicationRequested}
                        pharmacyRequested={pharmacySelected}
                        otherMedicationInfo={medicationInfo}
                      />
                    </QuickCheckoutContainer>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
        </Box>
        {/* END payment form */}
      </Box>
      {/* END Desktop Service Cards Container */}
    </>
  )
}

PlanMedicationLanderPayment.propTypes = {
  medicationData: PropTypes.array,
}

export default PlanMedicationLanderPayment
