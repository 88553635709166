import { useState, useCallback } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { setMergeOrCreateDocument } from '../firebase/firestore'
import PhoneField from '../stories/PhoneInput'
import SelectField from '../stories/Select'
import TextField from '../stories/TextField'
import DobPicker from './DobPicker'
import Button from '../stories/Button'
import { isValid } from 'date-fns'
import { Timestamp } from 'firebase/firestore'
import { convertFirestoreToDate, convertDateToUtcMidnight } from '../libs/formatters'
import Typography from '@mui/material/Typography'
import { useTimezoneSelect, allTimezones } from 'react-timezone-select'

// const INSURED = { [true]: 'Insured', [false]: 'Not Insured' }

const ProfileForm = ({ uid, values }) => {
  const [firstname, setFirstname] = useState(values?.firstname || '')
  const [lastname, setLastname] = useState(values?.lastname || '')
  const [phone, setPhone] = useState(values?.phone || '')
  // too many dates fliying around. string, Date and FireStore timestamp. lets agree to use Date across our application
  const [dob, setDob] = useState(convertFirestoreToDate(values?.dob || values?.birthday) || '')
  // const [gender, setGender] = useState(values?.gender || '')
  const [raceEthnicity, setRaceEthnicity] = useState(values?.raceEthnicity || [])
  // const [insured, setInsured] = useState(values?.insuranceInfo?.insured || INSURED.false)
  // const [insuranceProvider, setInsuranceProvider] = useState()
  // values?.insuranceInfo?.insuranceProvider || '',
  const [savingData, setSavingData] = useState('Save Changes')
  const [selectedTimezone, setSelectedTimezone] = useState(values?.timezone || '')
  const { options, parseTimezone } = useTimezoneSelect({
    labelStyle: 'original',
    timezones: allTimezones,
  })

  console.log('uid', uid)
  const processProfileUpdate = useCallback(async () => {
    setSavingData('Saving...')

    // convert dob from local to UTC midnight
    const dobUTC = dob ? convertDateToUtcMidnight(dob) : null

    console.log('selectedTimezonesssss', selectedTimezone)
    await setMergeOrCreateDocument('profiles', uid, {
      firstname: firstname,
      lastname: lastname,
      phone: phone,
      dob: isValid(dobUTC) ? Timestamp.fromDate(dobUTC) : null,
      // gender: gender,
      raceEthnicity: raceEthnicity,
      timezone: selectedTimezone,
      // insuranceInfo: {
      //   insured: insured,
      //   insuranceProvider: insured === INSURED.true ? insuranceProvider : '',
      // },
    })
      .then(() => {
        console.log('this works too')
      })
      .catch((error) => {
        console.warn('there was an error')
        console.error(error)
      })
    console.log('this does work after')
    setSavingData('Saved')
  }, [
    firstname,
    lastname,
    phone,
    dob,
    // gender,
    raceEthnicity,
    selectedTimezone,
    // insured,
    // insuranceProvider,
    uid,
  ])

  const handleChange = (event) => {
    const {
      target: { value },
    } = event
    setRaceEthnicity(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value === undefined ? '' : value,
    )
  }

  const onTimezoneChange = (e) => {
    const s = parseTimezone(e.target.value)
    console.log('ssssss', s)
    setSelectedTimezone(s)
  }

  return (
    <>
      <Typography variant='h3'>Personal Information</Typography>
      <Box>
        <TextField
          required
          name='firstname'
          label='first name'
          value={firstname}
          onChange={(e) => setFirstname(e.target.value)}
        />
        <TextField
          required
          name='lastname'
          label='last name'
          value={lastname}
          onChange={(e) => setLastname(e.target.value)}
        />
        <PhoneField
          required
          name='phone'
          label='Telephone number'
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        <Grid container spacing={2} justifyContent='space-between'>
          <Grid item xs={12} sm={12}>
            <Box mt={2}>
              <DobPicker dob={dob} setDob={setDob} />
            </Box>
            {/* <DateField
            required
            name='dob'
            label='Date of Birth'
            value={formatDateForUI(dob)}
            onChange={e => setDob(e.target.value)}
          /> */}
          </Grid>
          {/* <Grid item xs={12} sm={5}>
            <SelectField
              required
              label='gender'
              name='gender'
              options={GENDER_OPTIONS}
              value={gender}
              onChange={e => setGender(e.target.value)}
            />
          </Grid> */}
        </Grid>
        <SelectField
          label='Race/Ethnicity'
          name='raceEthnicity'
          multiple
          value={raceEthnicity}
          onChange={handleChange}
          options={[
            { value: 'preferNotToSay', label: 'Prefer not to say' },
            {
              value: 'americanIndian',
              label: 'American Indian or Alaska Native',
            },
            { value: 'asian', label: 'Asian' },
            { value: 'hispanic', label: 'Hispanic or Latino' },
            { value: 'black', label: 'Black or African American' },
            {
              value: 'nativeHawaiian',
              label: 'Native Hawaiian or Other Pacific Islander',
            },
            { value: 'white', label: 'White' },
            { value: 'other', label: 'Other' },
          ]}
        />

        <SelectField
          label='Timezone'
          name='timezone'
          value={selectedTimezone.value}
          onChange={onTimezoneChange}
          options={options.map((option) => ({ value: option.value, label: option.label }))}
        />

        {/* <SelectField
          label='Insured'
          name='insured'
          value={insured}
          onChange={e => {
            setInsured(e.target.value)
          }}
          options={[
            { value: INSURED.false, label: 'Not Insured' },
            { value: INSURED.true, label: 'Insured' },
          ]}
        />
        <Collapse in={insured === INSURED.true} timeout={500} unmountOnExit>
          <TextField
            name='insuranceProvider'
            label='Insurance Provider'
            value={insuranceProvider}
            onChange={e => {
              setInsuranceProvider(e.target.value)
            }}
          />
        </Collapse> */}

        <Box mt={2}>
          <Button fullWidth onClick={processProfileUpdate} variant='contained' color='primary'>
            {savingData}
          </Button>
        </Box>
      </Box>
    </>
  )
}

export default ProfileForm
