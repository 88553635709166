import PropTypes from 'prop-types'
import { useInstantSearch, useConnector } from 'react-instantsearch'
import connectHits from 'instantsearch.js/es/connectors/hits/connectHits'
import NoResults from '@app/components/NoResultMapSearch'

import Box from '@mui/material/Box'

import { useWhiteLabelingContext } from '@app/context/WhiteLabelingContext'

// the container the shows the center content with all the markers
const CustomHits = ({
  HitContainer = null,
  HitComponent,
  hitClick = null,
  variant = '',
  noResultsMessage = 'No results',
  noResultsColor,
  preventBooking,
  noResultsLink,
  showServiceSearchResponsibilityBreakdown = false,
}) => {
  const { hits } = useConnector(connectHits)

  const { indexUiState } = useInstantSearch()

  const { planColorPrimary } = useWhiteLabelingContext()

  if (hits.length === 0) {
    return (
      <NoResults
        noResultsMessage={noResultsMessage}
        noResultsColor={noResultsColor}
        noResultsLink={noResultsLink}
      />
    )
  }

  if (HitContainer) {
    return (
      <HitContainer>
        {hits.map((hit) => (
          <HitComponent
            key={hit.objectID}
            data={hit}
            onClick={hitClick}
            variant={variant}
            preventBooking={preventBooking}
            exactMatch={
              indexUiState?.query?.toString() === hit?.cptCode?.toString() && indexUiState?.query
            }
            showServiceSearchResponsibilityBreakdown={showServiceSearchResponsibilityBreakdown}
          />
        ))}
      </HitContainer>
    )
  }

  return (
    <Box
      backgroundColor='white'
      border={`1px solid ${planColorPrimary}`}
      borderRadius='4px'
      maxHeight='60vh'
      overflow='auto'
    >
      {hits.map((hit) => (
        <HitComponent
          key={hit.objectID}
          data={hit}
          onClick={hitClick}
          variant={variant}
          preventBooking={preventBooking}
          exactMatch={
            indexUiState?.query?.toString() === hit?.cptCode?.toString() && indexUiState?.query
          }
          showServiceSearchResponsibilityBreakdown={showServiceSearchResponsibilityBreakdown}
        />
      ))}
    </Box>
  )
}

CustomHits.propTypes = {
  HitContainer: PropTypes.func,
  HitComponent: PropTypes.func.isRequired,
  hitClick: PropTypes.func,
  variant: PropTypes.string,
  noResultsMessage: PropTypes.string,
  showServiceSearchResponsibilityBreakdown: PropTypes.bool,
}

export default CustomHits
