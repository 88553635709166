import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import { Highlight } from 'react-instantsearch'
import Typography from '../stories/Typography'
import Button from '../stories/Button'
import { collection } from 'firebase/firestore'

const MedicationSearchCard = ({ data, onClick = null, buttonText = '', variant = null }) => {
  const { strength, dosageForm, price, misheCode, additionalItems = {} } = data
  const [additionalItemsData, setAdditionalItemsData] = useState([])
  console.log('additionalItems', additionalItems)

  useEffect(() => {
    const getAdditionalItems = async () => {
      const additionalData = await Promise.all(
        Object.keys(additionalItems).map(async (key) => {
          console.log('key', key)
          const doc = await collection('medications')
            .doc(key)
            .get()
            .catch((err) => console.error(err))
          console.log('doc', doc.data())
          return {
            ...doc.data(),
            rate: additionalItems[key]?.rate,
          }
        }),
      )
      setAdditionalItemsData(additionalData)
    }

    if (additionalItems && Object.keys(additionalItems).length > 0) {
      getAdditionalItems()
    }
  }, [additionalItems])

  return (
    <Box
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      width='100%'
      padding='16px'
    >
      <Box width='50%'>
        <Typography variant='body1' weight='bold' capitalize='first'>
          {data?.brandName ? (
            <Highlight attribute='brandName' hit={data} style={{ textTransform: 'capitalize' }} />
          ) : (
            <Highlight attribute='genericName' hit={data} style={{ textTransform: 'capitalize' }} />
          )}
        </Typography>
        <Typography variant='body1' style={{ textTransform: 'capitalize' }}>
          {strength} {dosageForm}
        </Typography>
        <Typography variant='body1' weight='light'>
          ${(price && price?.toFixed(2)) || '---'} per Unit
        </Typography>
        {additionalItemsData.length > 0 && (
          <Typography variant='body1' weight='light'>
            Additional Items:
            {additionalItemsData.map((item, index) => (
              <>
                <Typography key={index} variant='body1' weight='light'>
                  {item.medName || '---'}
                </Typography>
                <Typography key={index} variant='body1' weight='light'>
                  {item.price || '---'}
                </Typography>
                <Typography key={index} variant='body1' weight='light'>
                  Per {item.rate || '---'} units
                </Typography>
              </>
            ))}
          </Typography>
        )}
      </Box>
      {onClick && (
        <>
          {!variant && (
            <Box>
              <Button
                variant='outlined'
                onClick={() => {
                  onClick(data)
                }}
              >
                {buttonText || 'Select'}
              </Button>
            </Box>
          )}
        </>
      )}
      {variant === 'homepage' && (
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='flex-end'
          alignItems='flex-end'
          sx={{
            width: {
              xs: '175px',
              sm: '175px',
              md: '100%',
              lg: '100%',
            },
          }}
        >
          {misheCode && (
            <Box
              style={{
                width: '100%',
                maxWidth: '150px',
                marginBottom: '8px',
              }}
            >
              <a href={`/medication/${misheCode}`} style={{ margin: '0px 8px' }}>
                <Button variant='outlined' size='small' fullWidth>
                  <Typography variant='body1'>Book now</Typography>
                </Button>
              </a>
            </Box>
          )}
        </Box>
      )}
    </Box>
  )
}

MedicationSearchCard.propTypes = {
  data: PropTypes.shape({
    price: PropTypes.number,
    brandName: PropTypes.string,
    dosageForm: PropTypes.string,
    strength: PropTypes.string,
    misheCode: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func,
  buttonText: PropTypes.string,
  variant: PropTypes.string,
}

export default MedicationSearchCard
