import { useState } from 'react'
import { useRefinementList } from 'react-instantsearch'
import Box from '@mui/material/Box'
import { Divider, Grid, FormControlLabel, Checkbox } from '@mui/material'
import Typography from '@app/stories/Typography'
import { useWhiteLabelingContext } from '@app/context/WhiteLabelingContext'

/**
 * Only to be used for attributes that are boolean.
 * Note: Algolia changes them to strings.
 */
const CustomRefinementToggle = ({ attribute, label, title }) => {
  const { planColorPrimary } = useWhiteLabelingContext()
  const { refine, canRefine } = useRefinementList({ attribute })
  const [isChecked, setIsChecked] = useState(false)

  if (!canRefine) return null

  return (
    <Grid item xs={12} mt={2} mb={1}>
      <Typography variant='h6'>{title}</Typography>
      <Box paddingLeft='8px'>
        <FormControlLabel
          key={label}
          control={
            <Checkbox
              checked={isChecked}
              onChange={(e) => {
                setIsChecked(e.target.checked)
                refine('true')
              }}
              color='primary'
              sx={{
                color: planColorPrimary,
                '&.Mui-checked': { color: planColorPrimary },
              }}
            />
          }
          label={label}
        />
      </Box>
      <Divider sx={{ mt: 1 }} />
    </Grid>
  )
}

export default CustomRefinementToggle
