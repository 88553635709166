import { useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import DateField from '../../stories/DateField'
import Typography from '../../stories/Typography'

const DateFilters = ({ filterFunction }) => {
  const [startDateFilter, setStartDateFilter] = useState('')
  const [endDateFilter, setEndDateFilter] = useState('')

  return (
    <>
      <Typography variant='h5' gutterBottom>
        Filter by date.
      </Typography>
      <Box
        display='flex'
        sx={{
          flexDirection: { xs: 'column', sm: 'row' },
          margin: { xs: '0px', sm: '-16px 0px -8px 0px' },
        }}
      >
        <Box padding='8px'>
          <DateField
            label='Start Date'
            value={startDateFilter}
            onChange={(e) => {
              filterFunction('startDate', e.target.value)
              setStartDateFilter(e.target.value)
            }}
          />
        </Box>
        <Box padding='8px'>
          <DateField
            label='End Date'
            value={endDateFilter}
            onChange={(e) => {
              filterFunction('endDate', e.target.value)
              setEndDateFilter(e.target.value)
            }}
          />
        </Box>
      </Box>
    </>
  )
}

DateFilters.propTypes = {
  filterFunction: PropTypes.func.isRequired,
}

export default DateFilters
