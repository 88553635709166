import { Box, Divider } from '@mui/material'
import PropTypes from 'prop-types'
import Typography from '../stories/Typography'
import Capsules from '../assets/images/Capsules.png'

const PlanMedicationLanderHeader = ({ medicationInfo }) => {
  return (
    <Box
      sx={{
        marginTop: {
          xs: '60px',
          sm: '0px',
        },
      }}
    >
      <Box width='100%' display='flex' justifyContent='flex-start' alignItems='center'>
        <Box
          marginRight='16px'
          sx={{
            display: {
              xs: 'none',
              sm: 'block',
            },
          }}
        >
          <img src={Capsules} alt='Medication' style={{ objectFit: 'contain', maxWidth: '80px' }} />
        </Box>
        <Box display='flex' flexDirection='column' justifyContent='center' height='100%'>
          {medicationInfo?.prescriptionRequired && (
            <Box color='#7004c6'>
              <Typography variant='body1' color='inherit' family='poppins' style={{ margin: '0' }}>
                Prescription Required
              </Typography>
            </Box>
          )}
          <Box
            display='flex'
            sx={{
              flexDirection: {
                xs: 'column',
                sm: 'row',
              },
              alignItems: {
                xs: 'flex-start',
                sm: 'flex-end',
              },
            }}
          >
            {medicationInfo?.brandName ? (
              <>
                <Typography
                  variant='h2'
                  family='poppins'
                  style={{
                    margin: '0',
                    textTransform: 'capitalize',
                  }}
                >
                  {medicationInfo?.brandName}
                </Typography>
                {medicationInfo?.genericName && (
                  <Box
                    sx={{
                      marginLeft: {
                        xs: '0px',
                        sm: '16px',
                      },
                    }}
                  >
                    <Typography
                      variant='h4'
                      family='poppins'
                      weight='light'
                      style={{
                        marginBottom: '3px',
                        textTransform: 'capitalize',
                      }}
                    >
                      {medicationInfo?.genericName}
                    </Typography>
                  </Box>
                )}
              </>
            ) : (
              medicationInfo?.genericName && (
                <Typography
                  variant='h4'
                  family='poppins'
                  weight='light'
                  style={{
                    marginBottom: '3px',
                    textTransform: 'capitalize',
                  }}
                >
                  {medicationInfo?.genericName}
                </Typography>
              )
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

PlanMedicationLanderHeader.propTypes = {
  medicationData: PropTypes.array,
}

export default PlanMedicationLanderHeader
