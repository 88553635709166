import { useState, useCallback } from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import OrganizationFunction from '@app/containers/OrganizationFunction'
import OrganizationInfo from '@app/components/OrganizationInfo'
import OrganizationServices from '@app/components/OrganizationServices'
import OrganizationLocations from '@app/components/OrganizationLocations'
import OrganizationDoctors from '@app/components/OrganizationDoctors'
import OrganizationPhotos from '@app/components/OrganizationPhotos'
import Loader from '../stories/Loader'
import Container from '../stories/Container'
import Section from '../stories/Section'
import Typography from '../stories/Typography'
import Box from '@mui/material/Box'
import Button from '../stories/Button'
import { useCurrentUser } from '@app/context/CurrentUser'

import useSignInUpModals from '@app/hooks/useSignInUpModals'
import SignUpModal from '@app/components/SignUpModal'
import { createChatVoucher } from '../firebase/functions'

import { GeneralModalNew as GeneralModal } from '@app/containers/GeneralModal'

const OrganizationPage = () => {
  const { slug } = useParams()
  const { uid } = useCurrentUser()

  const [confirmOpen, setConfirmOpen] = useState(false)

  const { signUpOpen, handleOpen } = useSignInUpModals()

  const [fetchingVoucherCode, setFetchingVoucherCode] = useState(false)

  return (
    <>
      <OrganizationFunction>
        {(organization, locations, primary) => {
          // These functions are in the OrganizationFunction component and are passed down as props

          const callbackCreateChatVoucher = useCallback(async () => {
            try {
              console.log('  ====== Callback called')
              const { code, error } = await createChatVoucher({
                clinicID: organization.id,
              })
              if (error) {
                console.log('error', error)
                handleOpen('signUp')
                return
              }

              console.log('voucherID', code)
              handleOpen('signUp')
              setFetchingVoucherCode(false)

              // nagivate window to dashboard?vc=code
              window.location.href = `/dashboard?vc=${code}`
            } catch (error) {
              console.log('error', error)
              handleOpen('signUp')
              setFetchingVoucherCode(false)
            }
          }, [organization])

          const handleChatWithClinician = useCallback(async () => {
            setFetchingVoucherCode(true)
            try {
              if (!uid) {
                handleOpen('signUp')
                return
              }
              console.log('create chat voucher')
              const { code, error } = await createChatVoucher({
                clinicID: organization.id,
              })
              if (error) {
                console.log('error', error)
                return
              }
              console.log('voucherID', code)

              // nagivate window to dashboard?vc=code
              window.location.href = `/dashboard?vc=${code}`

              setFetchingVoucherCode(false)
            } catch (error) {
              console.log('error', error)
              setFetchingVoucherCode(false)
            }
          }, [uid, handleOpen, organization])

          if (organization?.pharmacy) {
            return (
              <Section>
                <Container>
                  <Typography variant='h2' align='center'>
                    Pharmacies Coming Soon
                  </Typography>
                </Container>
              </Section>
            )
          }

          // if (!primary) {
          //   return (
          //     <Section>
          //       <Container>
          //         <Loader />
          //       </Container>
          //     </Section>
          //   )
          // }

          return (
            <>
              <Helmet>
                {organization.metaTitle && (
                  <>
                    <title>{organization.metaTitle}</title>
                    <meta property='og:title' content={organization.metaTitle} />
                  </>
                )}
                {organization.metaDescription && (
                  <>
                    <meta
                      name='description'
                      property='og:description'
                      content={organization.metaDescription}
                    />
                    <meta name='description' content={organization.metaDescription} />
                  </>
                )}
                <meta property='og:url' content={`https://mishe.co/clinics/${slug}`} />
                <link rel='canonical' href={`https://mishe.co/clinics/${slug}`} />
              </Helmet>
              <GeneralModal
                open={confirmOpen}
                handleClose={() => setConfirmOpen(false)}
                content='This will create a virtual encounter enabling you to chat in real time and share records with your provider. Click Chat Now to proceed.'
                primaryAction={{
                  label: uid ? 'Chat now' : 'Create account and chat now',
                  action: () => {
                    setConfirmOpen(false)
                    handleChatWithClinician()
                  },
                }}
                secondaryAction={{
                  label: 'Cancel',
                  action: () => setConfirmOpen(false),
                }}
              />
              <SignUpModal
                open={signUpOpen}
                handleClose={() => {
                  setFetchingVoucherCode(false)
                  handleOpen('signUp')
                }}
                callback={callbackCreateChatVoucher}
              />
              {primary && <OrganizationInfo primary={primary} organization={organization} />}
              <Box
                width='100%'
                padding='0 24px 48px 24px'
                display='flex'
                flexDirection='column'
                alignItems='center'
              >
                <Button
                  onClick={() => {
                    setConfirmOpen(true)
                  }}
                  disabled={fetchingVoucherCode}
                  variant='contained'
                  color='primary'
                  size='large'
                  style={{ marginTop: '1rem' }}
                >
                  <Typography variant='h4'>Chat with Clinician</Typography>
                </Button>
              </Box>
              <OrganizationServices uid={organization.id} organization={organization} />
              {!organization.orgVirtual && <OrganizationLocations locations={locations} />}
              <OrganizationDoctors uid={organization.id} />
              {organization.images && <OrganizationPhotos images={organization.images} />}
              {/* <LiveChat /> */}
            </>
          )
        }}
      </OrganizationFunction>
    </>
  )
}

export default OrganizationPage
