import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import MuiDivider from '@mui/material/Divider'

const StyledDivider = styled(MuiDivider)`
  background: ${(props) => props.theme.palette.auxLight.dark};
  transition: all 0.2s ${(props) => props.theme.transitions.easing.easeInOut};
  ${(props) =>
    props.spacing === 'horizontal' &&
    css`
      margin: 0px ${props.theme.spacing(3)};
    `}
  ${(props) =>
    props.spacing === 'vertical' &&
    css`
      margin: ${props.theme.spacing(1.5)} 0px;
    `}
  ${(props) =>
    props.spacing === 'none' &&
    css`
      margin: 0px;
    `}
  ${(props) =>
    props.color === 'primary' &&
    css`
      background: ${props.theme.palette.primary.main};
    `}
`

const Divider = ({ spacing = '', color = '', ...props }) => (
  <StyledDivider spacing={spacing} color={color} {...props} />
)

Divider.propTypes = {
  spacing: PropTypes.string,
  color: PropTypes.string,
}

export default Divider
