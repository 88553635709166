import { useCallback, useState, useRef } from 'react'
import firebase from 'firebase/compat/app'
import PhoneForm from '@app/components/SignInFormPhone'
import { CODES } from '@app/hooks/useAuthErrors'
import auth, { setPersistencePhone } from '../firebase/auth'
import { formatPhoneNumberForAuth } from '../libs/formatters'

const SignInFormPhoneContainer = ({ callback }) => {
  const [errors, setError] = useState({})

  const [loginStep, setLoginStep] = useState(1)
  const [disable, setDisable] = useState(false)

  const [code, setCode] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')

  // const confirmationResult = useRef(null)
  const [confirmationResult, setConfirmationResult] = useState(null)

  const onSubmit = useCallback(async () => {
    setError({})
    setDisable(true)

    const formattedPhone = formatPhoneNumberForAuth(phoneNumber.trim())

    if (formattedPhone) {
      const verify = new firebase.auth.RecaptchaVerifier('recaptcha-container')
      setPersistencePhone().then(() =>
        auth()
          .signInWithPhoneNumber(formattedPhone, verify)
          .then((confirmation) => {
            // SMS sent. Prompt user to type the code from the message, then sign the
            // user in with confirmationResult.confirm(code).
            setConfirmationResult(confirmation)
            setLoginStep(2)
            setDisable(false)
          })
          .catch((error) => {
            console.warn('  ** error - Firebase sign in with phoneNumber')
            console.log(error.code)
            setError({ code: error?.code })
            setDisable(false)
          }),
      )
    } else {
      // if given email is not valid
      // set validation error on form
      setError({
        code: CODES.INVALID_PHONE,
      })
      setDisable(false)
    }
  }, [setError, setDisable, setConfirmationResult, phoneNumber])

  const onCodeSubmit = useCallback(async () => {
    setDisable(true)
    const formattedCode = code.trim()
    if (formattedCode) {
      await confirmationResult
        .confirm(formattedCode)
        .then((user) => {
          setDisable(false)
          if (callback) {
            callback(user)
          }
        })
        .catch((error) => {
          console.log('Bad code')
          console.log(error)
          setError({
            code: 'Bad code',
          })
          setDisable(false)
        })
    } else {
      // if given code is not valid
      // set validation error on form
      setError({
        code: 'Bad code',
      })
      setDisable(false)
    }
  }, [setError, code, callback, confirmationResult, setDisable])

  return (
    <PhoneForm
      onSubmit={onSubmit}
      onCodeSubmit={onCodeSubmit}
      errors={errors}
      stepHandler={{
        step: loginStep,
        setStep: setLoginStep,
      }}
      disable={disable}
      codeHandler={{
        code,
        setCode,
      }}
      phoneHandler={{
        phone: phoneNumber,
        setPhone: setPhoneNumber,
      }}
    />
  )
}

export default SignInFormPhoneContainer
