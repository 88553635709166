import PropTypes from 'prop-types'
import { Divider, Grid, Box } from '@mui/material'
import Typography from '../stories/Typography'
import Button from '../stories/Button'
import OrderChatView from './OrderChatView'
import { useCurrentProfile } from '@app/context/CurrentUser'
import generatePDF from '../libs/pdf'
import usePDFs from '@app/hooks/usePDFs'

const OriginalOrderBar = ({ order }) => {
  const { isProvider } = useCurrentProfile()
  const { createOrderWithLogo } = usePDFs()

  return (
    <>
      <Divider />
      <Grid container style={{ marginBottom: '16px' }}>
        <Grid item xs={12} sm={12} md={4} style={{ display: 'flex', alignItems: 'center' }}>
          <Box mt={2}>
            <Typography
              variant='h4'
              style={{
                marginBottom: '0px',
                textTransform: 'capitalize',
              }}
            >
              Origin: {order?.orderType}
            </Typography>
          </Box>
        </Grid>
        {isProvider && order && (
          <Grid item xs={12} sm={12} md={2}>
            <Box mt={2} display='flex' justifyContent='center' alignItems='center'>
              <Button
                variant='outlined'
                size='small'
                style={{
                  width: '100%',
                  padding: '2px 9px',
                }}
                sx={{
                  marginRight: {
                    xs: '0px',
                    md: '16px',
                  },
                }}
                onClick={() => {
                  generatePDF(createOrderWithLogo(order), `Order_${order.id}`)
                }}
              >
                <Typography variant='body1'>Print order</Typography>
              </Button>
            </Box>
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={isProvider ? 6 : 8}>
          <Box mt={2} display='flex' alignItems='center' width='100%'>
            <OrderChatView order={order} chatID={order.id} color='#652d92' />
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

OriginalOrderBar.propTypes = {
  order: PropTypes.object.isRequired,
}

export default OriginalOrderBar
