import PropTypes from 'prop-types'
import { Box, Grid, TextField, Button, Typography, Divider } from '@mui/material'
import Icon from '../../stories/Icons'

import {
  MDXEditor,
  toolbarPlugin,
  headingsPlugin,
  quotePlugin,
  listsPlugin,
  linkPlugin,
  linkDialogPlugin,
  tablePlugin,
  UndoRedo,
  BlockTypeSelect,
  CreateLink,
  InsertTable,
  ListsToggle,
  BoldItalicUnderlineToggles,
} from '@mdxeditor/editor'
import '@mdxeditor/editor/style.css'

const OrderNotes = ({ values, setters }) => {
  const { providerFirstname, providerNPI, currentFile } = values

  const { setProviderFirstname, setProviderNPI, setNoteContent, setCurrentFile } = setters

  return (
    <Grid container spacing={3}>
      <style jsx>
        {' '}
        {`
          .mdxeditor-popup-container {
            z-index: 100001;
            background-color: #fff;
          }
        `}
      </style>
      {/* Provider Info */}
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography variant='h4' gutterBottom>
          Referring Provider
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextField
          required
          display='standard'
          id='t-referring-provider-name'
          label='Your Name'
          variant='outlined'
          value={providerFirstname}
          onChange={(e) => setProviderFirstname(e.target.value)}
          fullWidth
          style={{ backgroundColor: '#fff' }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextField
          required
          display='standard'
          id='t-referring-provider-npi'
          label='Your NPI'
          variant='outlined'
          value={providerNPI}
          onChange={(e) => setProviderNPI(e.target.value)}
          fullWidth
          style={{ backgroundColor: '#fff' }}
        />
      </Grid>
      {/* END Provider Info */}
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Divider style={{ margin: '1rem 0' }} />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Box>
          <Typography variant='h5' family='poppins'>
            Optionally attach any relevant documents or notes
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={9} lg={9} style={{ marginTop: '7px' }}>
        <MDXEditor
          markdown=''
          onChange={setNoteContent}
          plugins={[
            headingsPlugin(),
            quotePlugin(),
            listsPlugin(),
            linkPlugin(),
            linkDialogPlugin(),
            tablePlugin(),
            toolbarPlugin({
              toolbarContents: () => (
                <>
                  {' '}
                  <UndoRedo />
                  <BlockTypeSelect />
                  <BoldItalicUnderlineToggles />
                  <CreateLink />
                  <ListsToggle />
                  <InsertTable />
                </>
              ),
            }),
          ]}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3} style={{ display: 'flex', alignItems: 'center' }}>
        {!currentFile && (
          <Button
            variant='outlined'
            size='small'
            component='label'
            fullWidth
            style={{
              height: '58px',
              marginTop: '6px',
              backgroundColor: '#fff',
            }}
          >
            <Typography variant='body1'>Choose File</Typography>
            <input
              type='file'
              hidden
              onChange={(e) => {
                setCurrentFile(e.target.files[0])
              }}
            />
          </Button>
        )}
        {currentFile && (
          <Button
            variant='outlined'
            size='small'
            component='label'
            fullWidth
            style={{
              height: '58px',
              marginTop: '6px',
            }}
            onClick={() => {
              setCurrentFile(null)
            }}
          >
            <Icon name='clear' />
            <Typography variant='body1'>{currentFile?.name}</Typography>
          </Button>
        )}
      </Grid>
    </Grid>
  )
}

OrderNotes.propTypes = {
  values: PropTypes.shape({
    providerFirstname: PropTypes.string,
    providerNPI: PropTypes.string,
    noteContent: PropTypes.string,
    currentFile: PropTypes.object,
  }).isRequired,
  setters: PropTypes.shape({
    setProviderFirstname: PropTypes.func,
    setProviderNPI: PropTypes.func,
    setNoteContent: PropTypes.func,
    setCurrentFile: PropTypes.func,
  }).isRequired,
}

export default OrderNotes
