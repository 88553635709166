import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import MuiTypography from '@mui/material/Typography'

const StyledTypography = styled(MuiTypography)`
  ${(props) =>
    props.mask === 'h0' &&
    css`
      font-size: ${props.theme.typography.h0.fontSize};
      font-weight: ${props.theme.typography.h0.fontWeight};
      line-height: ${props.theme.typography.h0.lineHeight};
      margin-bottom: ${props.theme.typography.h0.marginBottom};
    `}
  ${(props) =>
    props.weight === 'bold' &&
    css`
      font-weight: 600;
    `}
    ${(props) =>
    props.weight === 'medium' &&
    css`
      font-weight: 300;
    `}
`

const Heading = ({
  children,
  variant = 'h1',
  align = 'left',
  weight = '',
  mask = '',
  ...props
}) => (
  <StyledTypography name='' variant={variant} align={align} weight={weight} mask={mask} {...props}>
    {children}
  </StyledTypography>
)

Heading.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string,
  variant: PropTypes.string,
  align: PropTypes.string,
  mask: PropTypes.string,
  weight: PropTypes.string,
}

export default Heading
