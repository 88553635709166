import { useInstantSearch, useConnector } from 'react-instantsearch'
import connectHits from 'instantsearch.js/es/connectors/hits/connectHits'
import Box from '@mui/material/Box'
import GoogleMap from '@app/components/GoogleMap'
import { useWhiteLabelingContext } from '@app/context/WhiteLabelingContext'
import NoResults from '@app/components/NoResultMapSearch'

const HitMap = ({ noResultsMessage, displayHeight, noResultsColor, noResultsLink }) => {
  const { hits } = useConnector(connectHits)
  const { indexUiState } = useInstantSearch()

  const { planColorPrimary } = useWhiteLabelingContext()

  if (hits.length === 0) {
    return (
      <NoResults
        noResultsMessage={noResultsMessage}
        noResultsColor={noResultsColor}
        noResultsLink={noResultsLink}
      />
    )
  }
  const uniqueClinicsBundle = hits
    .map((hit) => {
      if (hit?.bundle) {
        return Object.values(hit.uniqueClinicData)
      }
      return null
    })
    .filter((hit) => hit)
    .flat()
    .filter(Boolean)
  const setBundleMarker = uniqueClinicsBundle.map((e) => {
    return { ...e, fromBundle: true }
  })
  const placeholderfornow = [...setBundleMarker, ...hits]

  const uniqueClinics = placeholderfornow.reduce((acc, hit) => {
    if (!hit?.bundle) {
      if (!acc[hit.clinicID || hit.id]) {
        acc[hit.clinicID || hit.id] = hit
      }
    }
    return acc
  }, {})
  const uniqueClinicsArray = Object.values(uniqueClinics)

  const goodLocations = uniqueClinicsArray
    .map((hit) => {
      if (!hit?._geoloc) {
        return null
      }
      return hit?._geoloc?.map((location) => {
        return {
          _geoloc: {
            lat: location.lat,
            lng: location.lng,
          },
          name: hit.clinicName || hit.name || '',
          id: `${hit.clinicID || hit.id}`,
          fromBundle: hit.fromBundle,
        }
      })
    })
    .flat()

  return (
    <Box
      border={planColorPrimary ? `2px solid ${planColorPrimary}` : '2px solid #652d92'}
      borderRadius='4px'
      sx={{
        marginTop: {
          xs: '8px',
          md: '0px',
        },
      }}
    >
      <GoogleMap clinics={[{ locations: goodLocations }]} displayHeight={displayHeight} />
    </Box>
  )
}

export default HitMap
