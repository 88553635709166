import React, { useState, useEffect } from 'react'
import { Outlet, NavLink, useLocation } from 'react-router-dom'
import Paper from '@mui/material/Paper'
import Tabs, { Tab } from '../stories/Tabs'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

const DashboardTabs = ({ tabs }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const location = useLocation()
  const [currentTabIndex, setCurrentTabIndex] = useState(0)

  useEffect(() => {
    const activeIndex = tabs.findIndex((tab) => location.pathname.includes(tab.path))
    setCurrentTabIndex(activeIndex)
  }, [location.pathname, tabs])

  return (
    <>
      <Paper>
        <Tabs
          value={currentTabIndex}
          variant={isMobile ? 'standard' : 'scrollable'}
          orientation={isMobile ? 'vertical' : 'horizontal'}
          allowScrollButtonsMobile
        >
          {tabs.map((tab, index) => (
            <Tab key={index} label={tab.label} component={NavLink} to={tab.path} size='growXS' />
          ))}
        </Tabs>
      </Paper>
      <Box mt={4}>
        <Outlet />
      </Box>
    </>
  )
}

export default DashboardTabs
