import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useVouchers } from '@app/context/Vouchers'
import useInput from '@app/hooks/useInput'
import Form from '@app/components/ProviderVouchersForm'
import useVouchersFilter from '@app/hooks/useVouchersFilter'

const ProviderVouchersForm = ({ uid }) => {
  const [date, setDate] = useInput()
  const [query, setQuery] = useInput()
  const [status, setStatus] = useInput('purchased')
  const { changeVouchers } = useVouchers()
  const { fetchVouchersFilter } = useVouchersFilter()

  useEffect(() => {
    if (uid) {
      let updatedQuery = []
      if (query) {
        if (parseInt(query, 10)) {
          updatedQuery = ['code', query.trim()]
        } else if (query.startsWith('pi_')) {
          updatedQuery = ['orderId', query.trim()]
        } else if (query) {
          updatedQuery = ['customer.lastname', query.trim()]
        }
      }

      fetchVouchersFilter({ userInputQuery: updatedQuery, date, status })
    }
  }, [uid, query, date, changeVouchers, status])

  return (
    <Form
      onQueryChange={setQuery}
      onDateChange={setDate}
      onStatusChange={setStatus}
      currentStatus={status}
    />
  )
}

ProviderVouchersForm.propTypes = {
  uid: PropTypes.string.isRequired,
}

export default ProviderVouchersForm
