const Sales32bj = () => {
  return (
    <iframe
      loading='lazy'
      src='https://www.canva.com/design/DAGcIw5axM4/ByM6m7Yp_bQw-yCrmqs3xg/view?embed'
      style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        border: 'none',
        padding: 0,
        margin: 0,
      }}
      allowFullScreen
      allow='fullscreen'
    />
  )
}

export default Sales32bj
