import { useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Typography from '../stories/Typography'
import preventativeCare from '../assets/images/misheServiceCardImages/preventativeCare.png'
import primaryCare from '../assets/images/misheServiceCardImages/primaryCare.png'
import specialistCare from '../assets/images/misheServiceCardImages/specialistCare.png'
import prescriptionDrugs from '../assets/images/misheServiceCardImages/prescriptionDrugs.png'
import urgentCare from '../assets/images/misheServiceCardImages/urgentCare.png'
import ComponentShifterFadeControl from './ComponentShifterFadeControl'

const misheCodeMappings = {
  'Preventive Care': 'primary-initialvisit',
  'Primary Care': 'primary-initialvisit',
  'Urgent Care': 'primary-urgentcarevisit',
  'Specialist Care': 'specialist-initialvisit',
  'Prescription Drugs': 'prescriptiondrugs-initialvisit',
}

const NetworkCards = ({ networkSlug }) => {
  const ServiceCard = ({ serviceName, serviceImage, width, to }) => {
    const [hover, setHover] = useState(false)

    return (
      <Box
        width={width || '100%'}
        height='218px'
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        backgroundColor='#f1e7fb'
        outline='1px solid #652d92'
        borderRadius='8px'
        style={{
          transform: hover ? 'scale(1.05)' : 'scale(1)',
          transition: 'transform 0.3s',
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={() => {
          window.location.href = to
        }}
      >
        <Box>
          <img
            src={serviceImage}
            alt={serviceName}
            style={{
              width: '100px',
              height: '100px',
              objectFit: 'contain',
              filter:
                'invert(23%) sepia(20%) saturate(4605%) hue-rotate(249deg) brightness(94%) contrast(99%)',
            }}
          />
        </Box>
        <Typography variant='h6' align='center' color='primary'>
          {serviceName}
        </Typography>
      </Box>
    )
  }

  if (networkSlug !== 'mishehealthplan') {
    return
  }

  return (
    <Box width='100%' padding='96px 16px 0px 16px'>
      <Box
        width='100%'
        justifyContent='center'
        alignItems='center'
        padding='16px'
        gap='32px'
        sx={{
          display: {
            xs: 'none',
            sm: 'none',
            md: 'flex',
            lg: 'flex',
          },
        }}
      >
        <ServiceCard
          serviceName='Preventive Care'
          serviceImage={preventativeCare}
          width='15%'
          to={`/plans/${networkSlug}/service/${misheCodeMappings['Preventive Care']}`}
        />
        <ServiceCard
          serviceName='Primary Care'
          serviceImage={primaryCare}
          width='15%'
          to={`/plans/${networkSlug}/service/${misheCodeMappings['Primary Care']}`}
        />
        <ServiceCard
          serviceName='Specialist Care'
          serviceImage={specialistCare}
          width='15%'
          to={`/plans/${networkSlug}/service/${misheCodeMappings['Specialist Care']}`}
        />
        <ServiceCard
          serviceName='Prescription Drugs'
          serviceImage={prescriptionDrugs}
          width='15%'
          to={`/plans/${networkSlug}/service/${misheCodeMappings['Prescription Drugs']}`}
        />
        <ServiceCard
          serviceName='Urgent Care'
          serviceImage={urgentCare}
          width='15%'
          to={`/plans/${networkSlug}/service/${misheCodeMappings['Urgent Care']}`}
        />
      </Box>
      <Box
        width='100%'
        sx={{
          display: {
            xs: 'block',
            sm: 'block',
            md: 'none',
            lg: 'none',
          },
        }}
      >
        <ComponentShifterFadeControl healthPlanHomePage>
          <ServiceCard
            serviceName='Preventive Care'
            serviceImage={preventativeCare}
            to={`/plans/${networkSlug}/service/${misheCodeMappings['Preventive Care']}`}
          />
          <ServiceCard
            serviceName='Primary Care'
            serviceImage={primaryCare}
            to={`/plans/${networkSlug}/service/${misheCodeMappings['Primary Care']}`}
          />
          <ServiceCard
            serviceName='Specialist Care'
            serviceImage={specialistCare}
            to={`/plans/${networkSlug}/service/${misheCodeMappings['Specialist Care']}`}
          />
          <ServiceCard
            serviceName='Prescription Drugs'
            serviceImage={prescriptionDrugs}
            to={`/plans/${networkSlug}/service/${misheCodeMappings['Perscription Drugs']}`}
          />
          <ServiceCard
            serviceName='Urgent Care'
            serviceImage={urgentCare}
            to={`/plans/${networkSlug}/service/${misheCodeMappings['Urgent Care']}`}
          />
        </ComponentShifterFadeControl>
      </Box>
    </Box>
  )
}

NetworkCards.propTypes = {
  networkSlug: PropTypes.string.isRequired,
}

export default NetworkCards
