// removes alpha if present because it is not supported by the color picker
export const createLightBackgroundColor = (hex) => {
  const strippedHex = hex.length === 9 ? hex.slice(1, 7) : hex.slice(1)
  const num = parseInt(strippedHex, 16)
  const R = num >> 16
  const G = (num >> 8) & 0x00ff
  const B = num & 0x0000ff
  const newR = Math.min(255, Math.floor(R + (255 - R) * 0.75))
  const newG = Math.min(255, Math.floor(G + (255 - G) * 0.75))
  const newB = Math.min(255, Math.floor(B + (255 - B) * 0.75))
  return `#${(0x1000000 + newR * 0x10000 + newG * 0x100 + newB)
    .toString(16)
    .slice(1)
    .toUpperCase()}`
}

// removes alpha if present because it is not supported by the color picker
export const lightenHexColor = (hex, percent) => {
  const strippedHex = hex.length === 9 ? hex.slice(1, 7) : hex.slice(1)
  const num = parseInt(strippedHex, 16)
  const amt = Math.round(2.55 * percent)
  const R = (num >> 16) + amt
  const G = ((num >> 8) & 0x00ff) + amt
  const B = (num & 0x0000ff) + amt
  return `#${(
    0x1000000 +
    (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
    (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
    (B < 255 ? (B < 1 ? 0 : B) : 255)
  )
    .toString(16)
    .slice(1)
    .toUpperCase()}`
}

export const getBetterContrastColor = (baseColor) => {
  const hex1 = '#652d92'
  const hex2 = '#ffffff'

  function hexToRgb(hex) {
    hex = hex.replace(/^#/, '')
    if (hex.length === 3) {
      hex = hex
        .split('')
        .map((c) => c + c)
        .join('')
    }
    const bigint = parseInt(hex, 16)
    const r = (bigint >> 16) & 255
    const g = (bigint >> 8) & 255
    const b = bigint & 255
    return [r, g, b]
  }

  function relativeLuminance(rgb) {
    const [r, g, b] = rgb.map((c) => c / 255)
    const adjusted = (c) => {
      return c <= 0.03928 ? c / 12.92 : Math.pow((c + 0.055) / 1.055, 2.4)
    }
    return 0.2126 * adjusted(r) + 0.7152 * adjusted(g) + 0.0722 * adjusted(b)
  }

  function contrastRatio(hex1, hex2) {
    const luminance1 = relativeLuminance(hexToRgb(hex1))
    const luminance2 = relativeLuminance(hexToRgb(hex2))
    const lighter = Math.max(luminance1, luminance2)
    const darker = Math.min(luminance1, luminance2)
    return (lighter + 0.05) / (darker + 0.05)
  }

  const contrast1 = contrastRatio(baseColor, hex1)
  const contrast2 = contrastRatio(baseColor, hex2)

  console.log(`Contrast with ${hex1}: ${contrast1}`)
  console.log(`Contrast with ${hex2}: ${contrast2}`)

  return contrast1 > contrast2 ? hex1 : hex2
}
