import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import { Divider } from '@mui/material'
import Button from '@app/stories/Button'
import Typography from '@app/stories/Typography'

import useSignInUpModals from '@app/hooks/useSignInUpModals'
import SignUpModal from '@app/components/SignUpModal'
import SignInModal from '@app/components/SignInModal'
import EnrollmentModal from '@app/components/EnrollmentModal'

import { useWhiteLabelingContext } from '@app/context/WhiteLabelingContext'
import Icon from '@app/stories/Icons'

import MisheLoginBackgroundBuilderDynamic from '@app/components/SvgBuilders/MisheLoginBackgroundBuilderDynamic'

import BubbleBuilder from '@app/components/SvgBuilders/BubbleBuilder'
import HalfPhoneBackground from '@app/components/HalfPhoneBackground'
import HalfPhoneCard from '@app/components/HalfPhoneCard'

import { createLightBackgroundColor, lightenHexColor } from '@app/utils/hexcodeUtils'

const NetworkHeroLogin = ({ reason = null, loginMethods, landerData }) => {
  const { signUpOpen, signInOpen, handleOpen } = useSignInUpModals()
  const [enrollmentOpen, setEnrollmentOpen] = useState(false)
  const [sectionSelected, setSectionSelected] = useState('benefits')
  const [loginWidth, setLoginWidth] = useState('')
  const [loginHeight, setLoginHeight] = useState('')

  const { planColorPrimary } = useWhiteLabelingContext()

  const headerText = reason === 'not-loggedIn' ? 'Sign in' : 'Become a member'

  const lightenedColor = lightenHexColor(planColorPrimary, 30)

  const lightBackgroundColor = createLightBackgroundColor(planColorPrimary)

  const loginBackgroundDynamic = MisheLoginBackgroundBuilderDynamic({
    width: loginWidth,
    height: loginHeight,
    color: lightenedColor,
  })

  const cardData = [
    {
      title: 'Benefits',
      content: 'View your benefits and coverage details',
      bubble: 'benefits',
      infomationCards: [
        {
          title: 'Coverage',
          content: 'Summary of what is covered and included under your plans.',
          bubble: 'coverage',
        },
        {
          title: 'ID Card',
          content: 'View, download and share your member ID card.',
          bubble: 'idCard',
        },
        {
          title: 'Out-of-Pocket',
          content: 'Deductible, co-payment, and out-of-pocket totals.',
          bubble: 'outOfPocket',
        },
      ],
    },
    {
      title: 'Claims',
      content: 'View your claims and EOBs',
      bubble: 'claims',
      infomationCards: [
        {
          title: 'Claim Status',
          content: 'A dashboard showing the status of all recent claims.',
          bubble: 'claimStatus',
        },
        {
          title: 'Claim Details',
          content:
            'View detailed information about a specific claim, including date, provider, and amount covered.',
          bubble: 'claimDetails',
        },
        {
          title: 'Claim History',
          content: 'A list of recently submitted claims with their statuses.',
          bubble: 'claimHistory',
        },
      ],
    },
    {
      title: 'Network',
      content: 'Search for providers and treatments',
      bubble: 'mishe',
      infomationCards: [
        {
          title: 'Providers',
          content:
            'Search and request in-network and zero cost providers by location, specialty and name.',
          bubble: 'idCard',
        },
        {
          title: 'Treatments',
          content: 'Search and book zero cost treatments',
          bubble: 'treatments',
        },
      ],
    },
    {
      title: 'Support',
      content: 'Get help and support',
      bubble: 'support',
      infomationCards: [
        {
          title: 'Contact Us',
          content: 'Get in touch with our support team for help with your account.',
          bubble: 'contactUs',
        },
        {
          title: 'FAQs',
          content: 'Answers to common questions about your plan and benefits.',
          bubble: 'faqs',
        },
        {
          title: 'Chat',
          content: 'Chat with a support agent for immediate assistance.',
          bubble: 'chat',
        },
      ],
    },
  ]

  useEffect(() => {
    const updateDimensions = () => {
      const loginBackground = document.getElementById('loginBackground')
      const loginBackgroundMobile = document.getElementById('loginBackgroundMobile')
      if (window.innerWidth >= 900 && loginBackground) {
        const { offsetWidth: width, offsetHeight: height } = loginBackground
        setLoginWidth(width)
        setLoginHeight(height)
      }
      if (window.innerWidth < 900 && loginBackgroundMobile) {
        const { offsetWidth: width, offsetHeight: height } = loginBackgroundMobile
        setLoginWidth(width)
        setLoginHeight(height)
      }
    }

    window.addEventListener('resize', updateDimensions)
    updateDimensions()

    return () => window.removeEventListener('resize', updateDimensions)
  }, [])

  const cycleTitle = (direction) => {
    const currentIndex = cardData.findIndex((data) => data.bubble === sectionSelected)
    if (direction === 'forwards') {
      const nextIndex = (currentIndex + 1) % cardData.length
      setSectionSelected(cardData[nextIndex].bubble)
    } else if (direction === 'backwards') {
      const prevIndex = (currentIndex - 1 + cardData.length) % cardData.length
      setSectionSelected(cardData[prevIndex].bubble)
    }
  }

  const BubbleCard = ({ title, content, bubble }) => {
    return (
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        width='250px'
        border='1px solid white'
        padding='16px'
        borderRadius='8px'
      >
        <Box width='50px' marginBottom='16px'>
          <BubbleBuilder color={planColorPrimary} bubbleName={bubble} />
        </Box>
        <Typography variant='h4' color='white' align='center' marginBottom='16px'>
          {title}
        </Typography>
        <Typography variant='body1' color='white' align='center'>
          {content}
        </Typography>
      </Box>
    )
  }

  return (
    <Box
      variant='noPadding'
      style={{ overflowX: 'hidden', paddingBottom: '0px', paddingTop: '0px' }}
      sx={{
        marginTop: {
          xs: '8px',
          sm: '0px',
        },
      }}
    >
      <Box style={{ position: 'relative', padding: '0px' }}>
        <SignUpModal open={signUpOpen} handleClose={() => handleOpen('signUp')} />
        <SignInModal
          open={signInOpen}
          loginMethods={loginMethods}
          preventRegister={loginMethods?.length === 0 || loginMethods?.includes('preEnrollment')}
          handleClose={() => handleOpen('signIn')}
          customColor={planColorPrimary}
        />
        <EnrollmentModal open={enrollmentOpen} handleClose={() => setEnrollmentOpen(false)} />
        <Box height='100%'>
          {/* Desktop */}
          <Box
            sx={{
              display: {
                xs: 'none',
                md: 'block',
              },
            }}
            position='relative'
            width='100%'
          >
            {/* Background Image */}
            <Box
              id='loginBackground'
              position='absolute'
              top='0px'
              left='0px'
              width='100%'
              height='100%'
              bgcolor={lightBackgroundColor}
              zIndex='-1'
              overflow='hidden'
              padding='8px'
            >
              {loginBackgroundDynamic}
            </Box>
            {/* End of Background Image */}
            <Box
              display='flex'
              gap='64px'
              width='100%'
              paddingX={7}
              justifyContent='space-around'
              alignItems='flex-end'
              height='600px'
              zIndex='1'
            >
              {/* Sign In Box */}
              <Box
                style={{
                  boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.3)',
                  borderRadius: '8px',
                  backgroundColor: 'white',
                  padding: '36px',
                  height: 'min-content',
                  marginTop: '96px',
                  alignSelf: 'flex-start',
                  maxWidth: '500px',
                }}
                zIndex={1}
                color={planColorPrimary || 'primary'}
              >
                <Box>
                  <Typography
                    variant='h3'
                    family='poppins'
                    color='inherit'
                    style={{
                      marginBottom: '8px',
                    }}
                  >
                    {landerData?.heroTitle
                      ? landerData?.heroTitle
                      : `${headerText} for a personalized view of your benefits`}
                  </Typography>
                  {landerData?.heroSubText && (
                    <Typography
                      variant='body1'
                      weight='medium'
                      style={{
                        marginBottom: '8px',
                      }}
                    >
                      {landerData?.heroSubText}
                    </Typography>
                  )}
                  {landerData?.heroTitle && (
                    <Typography variant='body1' weight='light'>
                      {`${headerText} for a personalized view of your benefits`}
                    </Typography>
                  )}
                </Box>
                {reason === 'not-loggedIn' && (
                  <Box mt={1}>
                    <Box mb={3}>
                      <Divider />
                    </Box>
                    <Box display='flex' alignItems='center' justifyContent='space-around'>
                      <Button
                        variant='unstyled'
                        size='medium'
                        family='poppins'
                        onClick={() => handleOpen('signIn')}
                        style={{
                          width: '40%',
                          backgroundColor: planColorPrimary,
                          color: 'white',
                        }}
                      >
                        Sign In
                      </Button>
                      {(loginMethods?.length === 0 || loginMethods?.includes('preEnrollment')) && (
                        <Button
                          size='medium'
                          variant='unstyled'
                          family='poppins'
                          onClick={() => setEnrollmentOpen(true)}
                          style={{
                            border: `1px solid ${planColorPrimary}`,
                            backgroundColor: 'white',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          Activate Benefits
                        </Button>
                      )}
                    </Box>
                  </Box>
                )}
              </Box>
              {/* iPhone Box */}
              <Box paddingTop='64px'>
                <HalfPhoneBackground color={planColorPrimary}>
                  {cardData.map((data, index) => (
                    <HalfPhoneCard
                      key={index}
                      title={data.title}
                      content={data.content}
                      color={planColorPrimary}
                      bubble={data.bubble}
                      setSectionSelected={setSectionSelected}
                      sectionSelected={sectionSelected}
                    />
                  ))}
                </HalfPhoneBackground>
              </Box>
              {/* End of iPhone Box */}
            </Box>
          </Box>
          {/* Next Section */}
          <Box
            sx={{
              display: {
                xs: 'none',
                md: 'flex',
              },
            }}
            width='100%'
            bgcolor={planColorPrimary || 'primary'}
            padding='64px'
            flexDirection='column'
            justifyContent='flex-start'
            alignItems='center'
            minHeight='625px'
          >
            <Box
              marginBottom='32px'
              display='flex'
              justifyContent='space-between'
              alignItems='center'
              gap='16px'
              color='white'
              width='550px'
            >
              <Button
                variant='unstyled'
                size='large'
                onClick={() => cycleTitle('backwards')}
                style={{
                  width: '72px',
                  height: '72px',
                  borderRadius: '50%',
                }}
              >
                <Icon name='chevronLeft' color='inherit' />
              </Button>
              <Box display='flex' justifyContent='center' alignItems='center' gap='16px'>
                <Box width='72px' height='72px'>
                  <BubbleBuilder color={planColorPrimary} bubbleName={sectionSelected} />
                </Box>
                <Typography variant='h3' color='white' align='center' style={{ margin: '0px' }}>
                  {cardData.find((data) => data.bubble === sectionSelected).title}
                </Typography>
              </Box>
              <Button
                variant='unstyled'
                size='large'
                onClick={() => cycleTitle('forwards')}
                style={{
                  width: '72px',
                  height: '72px',
                  borderRadius: '50%',
                }}
              >
                <Icon name='chevronRight' color='inherit' />
              </Button>
            </Box>
            <Box display='flex' width='100%' gap='16px' justifyContent='center'>
              {cardData
                .find((data) => data.bubble === sectionSelected)
                .infomationCards.map((card, index) => (
                  <BubbleCard
                    key={index}
                    title={card.title}
                    content={card.content}
                    bubble={card.bubble}
                  />
                ))}
            </Box>
          </Box>
          {/* Mobile */}
          <Box
            sx={{
              display: {
                xs: 'flex',
                md: 'none',
              },
            }}
            position='relative'
            overflow='hidden'
            flexDirection='column'
            height='calc(100vh - 160px)'
            alignItems='center'
            justifyContent='center'
          >
            <Box
              id='loginBackgroundMobile'
              position='absolute'
              top='0px'
              left='0px'
              width='100%'
              height='100vh'
              bgcolor={lightBackgroundColor}
              zIndex='-1'
              overflow='hidden'
            >
              {loginBackgroundDynamic}
            </Box>
            <Box
              padding='0px 16px'
              display='flex'
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
            >
              <Box
                boxShadow='0px 0px 10px 0px rgba(0,0,0,0.3)'
                borderRadius='5px'
                backgroundColor='rgba(255,255,255,0.8)'
                padding='25px'
                color={planColorPrimary || 'primary'}
                maxWidth='400px'
              >
                <Box>
                  {landerData?.heroTitle && (
                    <Typography variant='h3' color='inherit' align='center'>
                      {landerData?.heroTitle
                        ? landerData?.heroTitle
                        : `${headerText} for a personalized view of your benefits`}
                    </Typography>
                  )}
                  {landerData?.heroSubText && (
                    <Typography variant='body1' weight='light' align='center' mb={1}>
                      {landerData?.heroSubText}
                    </Typography>
                  )}
                </Box>
                {reason === 'not-loggedIn' && (
                  <Box mt={2}>
                    <Box mb={2}>
                      <Divider />
                    </Box>
                    <Box
                      display='flex'
                      flexDirection='column'
                      alignItems='center'
                      justifyContent='space-around'
                      gap='16px'
                    >
                      <Button
                        fullWidth
                        variant='unstyled'
                        size='medium'
                        family='poppins'
                        onClick={() => handleOpen('signIn')}
                        style={{
                          width: '100%',
                          backgroundColor: planColorPrimary || 'primary',
                          color: 'white',
                        }}
                      >
                        Sign In
                      </Button>
                      {(loginMethods?.length === 0 || loginMethods?.includes('preEnrollment')) && (
                        <Button
                          fullWidth
                          size='medium'
                          variant='unstyled'
                          family='poppins'
                          onClick={() => setEnrollmentOpen(true)}
                          style={{
                            width: '100%',
                            border: `1px solid ${planColorPrimary || 'primary'}`,
                            backgroundColor: 'white',
                            marginLeft: '0px',
                          }}
                        >
                          Activate Benefits
                        </Button>
                      )}
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

NetworkHeroLogin.propTypes = {
  reason: PropTypes.string,
}

export default NetworkHeroLogin
