import { useState } from 'react'
import Box from '@mui/material/Box'
import AppBar from '../stories/AppBar'
import NavLink from '../stories/NavLink'
import DropDownMenu from './DropDownMenu'
import Logo from '../stories/LogoWhite'
import TopCorner from './TopCorner'
import EnrollmentModal from './EnrollmentModal'
import { useLocation } from 'react-router-dom'

import { useWhiteLabelingContext } from '@app/context/WhiteLabelingContext'

const TopBar = () => {
  const location = useLocation()
  const isPlan = location.pathname.includes('/plans')
  const {
    logoSource,
    planColorSecondary,
    planColorPrimary,
    planSlug,
    planPhoneNumber,
    planSMSNumber,
    loginMethods,
  } = useWhiteLabelingContext()

  const [enrollmentOpen, setEnrollmentOpen] = useState(false)

  return (
    <AppBar primaryColor={planColorPrimary} secondaryColor={planColorSecondary}>
      <EnrollmentModal open={enrollmentOpen} handleClose={() => setEnrollmentOpen(false)} />
      <Box display='flex' alignItems='center' flexGrow={1}>
        <NavLink to={planSlug ? `/plans/${planSlug}` : '/'}>
          <Logo size='semiLarge' source={logoSource} />
        </NavLink>
      </Box>
      <Box
        sx={{
          display: {
            xs: 'none',
            md: 'block',
          },
        }}
      >
        <Box display='flex' alignItems='center' flexGrow={1} color={planColorSecondary}>
          <DropDownMenu
            text='24/7 Support'
            primaryColor={planColorPrimary}
            secondaryColor={planColorSecondary}
          >
            {planPhoneNumber && (
              <Box color={planColorSecondary} width='100%'>
                <NavLink
                  className='planPhoneButton'
                  to={`tel:${planPhoneNumber}`}
                  align='left'
                  width='full'
                  external
                >
                  Call
                </NavLink>
              </Box>
            )}
            <Box color={planColorSecondary} width='100%'>
              <NavLink
                className='planChatButton'
                align='left'
                width='full'
                onClick={() => window?.Intercom('show')}
              >
                Chat
              </NavLink>
            </Box>
            {planSMSNumber && (
              <Box color={planColorSecondary} width='100%'>
                <NavLink
                  className='planSMSButton'
                  to={`sms:${planSMSNumber}`}
                  align='left'
                  width='full'
                  external
                  style={{ borderRadius: '0 0 21.125px 21.125px' }}
                >
                  SMS
                </NavLink>
              </Box>
            )}
          </DropDownMenu>
        </Box>
      </Box>
      <Box display='flex' alignItems='center'>
        <TopCorner
          primaryColor={planColorPrimary}
          secondaryColor={planColorSecondary}
          loginMethods={loginMethods}
          isPlan={isPlan}
          setEnrollmentOpen={setEnrollmentOpen}
        />
      </Box>
    </AppBar>
  )
}

export default TopBar
