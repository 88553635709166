import DateRangeIcon from '@mui/icons-material/DateRange'
import { DatePicker } from '@mui/x-date-pickers'
import { formatDateForDatePickerUI } from '@app/libs/formatters'
import { useWhiteLabelingContext } from '@app/context/WhiteLabelingContext'

const DobPicker = ({ dob, setDob }) => {
  const formattedDOB = formatDateForDatePickerUI(dob)
  const { planColorPrimary } = useWhiteLabelingContext()

  return (
    <DatePicker
      fullWidth
      inputVariant='outlined'
      format='MM-dd-yyyy'
      margin='normal'
      id='date-picker-inline'
      label='Birthday'
      name='dob'
      timezone='UTC'
      maxDate={new Date()}
      value={formattedDOB ? formattedDOB : null}
      onChange={setDob}
      InputLabelProps={{ shrink: true }}
      keyboardIcon={<DateRangeIcon />}
      KeyboardButtonProps={{ 'aria-label': 'change date' }}
      slotProps={{ textField: { fullWidth: true } }}
      sx={{
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: planColorPrimary,
          },
          '&:hover fieldset': {
            borderColor: planColorPrimary,
          },
          '&.Mui-focused fieldset': {
            borderColor: planColorPrimary,
          },
        },
        '& .MuiInputLabel-root': {
          color: planColorPrimary,
        },
        '& .MuiInputLabel-root.Mui-focused': {
          color: planColorPrimary,
        },
      }}
    />
  )
}

export default DobPicker
