import { Page, Text, View, Document, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer'
import { formatDateForUI } from '@app/libs/formatters'

const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontSize: 12,
    fontFamily: 'Helvetica',
    backgroundColor: '#f9f9f9',
  },
  header: {
    backgroundColor: '#652D91',
    padding: 15,
    textAlign: 'center',
    color: '#fff',
    marginBottom: 20,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#fff',
  },
  section: {
    marginBottom: 15,
  },
  sectionTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 5,
    color: '#652D91',
  },
  paragraph: {
    marginBottom: 10,
    lineHeight: 1.5,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#652D91',
    marginBottom: 20,
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableCell: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#652D91',
    padding: 8,
    flex: 1,
  },
  headerCell: {
    backgroundColor: '#652D91',
    color: '#fff',
    fontWeight: 'bold',
  },
})

const CertificateOfCoverage = ({
  certificateDate,
  groupHealthPlanName,
  planType,
  participantName,
  participantID,
  participantAddress,
  dependents,
}) => (
  <Document>
    <Page size='A4' style={styles.page}>
      <View style={styles.header}>
        <Text style={styles.title}>Certificate of Coverage</Text>
      </View>

      <View style={styles.section}>
        <Text style={styles.paragraph}>Dear {participantName},</Text>
        <Text style={styles.paragraph}>
          This document certifies your participation in {groupHealthPlanName}. Below, you will find
          your personal details, plan information, and any dependents covered under the plan.
        </Text>
      </View>

      <View style={styles.section}>
        <Text style={styles.sectionTitle}>Participant Information</Text>
        <Text>Certificate Date: {certificateDate}</Text>
        <Text>Participant Name: {participantName}</Text>
        <Text>Participant Address:</Text>
        <Text> Street: {participantAddress?.street}</Text>
        {participantAddress?.street2 && <Text> #: {participantAddress?.street2}</Text>}
        <Text> City: {participantAddress?.city}</Text>
        <Text> State: {participantAddress?.state}</Text>
        <Text> ZIP: {participantAddress?.zip}</Text>
        <Text>Identification Number: {participantID}</Text>
      </View>

      <View style={styles.section}>
        <Text style={styles.sectionTitle}>Dependents</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <Text style={[styles.tableCell, styles.headerCell]}>Name</Text>
            <Text style={[styles.tableCell, styles.headerCell]}>Type</Text>
            <Text style={[styles.tableCell, styles.headerCell]}>Date Joined</Text>
            <Text style={[styles.tableCell, styles.headerCell]}>Care Expires</Text>
            <Text style={[styles.tableCell, styles.headerCell]}>Plan Type</Text>
          </View>
          {dependents.map((dependent, index) => (
            <View style={styles.tableRow} key={index}>
              <Text style={styles.tableCell}>{dependent?.name || ''}</Text>
              <Text style={styles.tableCell}>{dependent?.type || ''}</Text>
              <Text style={styles.tableCell}>{dependent?.dateJoined || ''}</Text>
              <Text style={styles.tableCell}>{dependent?.careExpires || ''}</Text>
              <Text style={styles.tableCell}>{planType}</Text>
            </View>
          ))}
        </View>
      </View>
    </Page>
  </Document>
)

const PDFGenerator = ({
  certificateDate,
  groupHealthPlanName,
  planType,
  participantName,
  participantID,
  participantAddress,
  dependents,
}) => (
  <div>
    <PDFDownloadLink
      document={
        <CertificateOfCoverage
          certificateDate={certificateDate}
          groupHealthPlanName={groupHealthPlanName}
          planType={planType}
          participantName={participantName}
          participantID={participantID}
          participantAddress={participantAddress}
          dependents={dependents.map((dep) => ({
            name: dep.name || '',
            type: dep.typeReadable || '',
            dateJoined: dep.dateJoined ? formatDateForUI(dep.dateJoined) : certificateDate,
            careExpires: dep.careExpires ? formatDateForUI(dep.careExpires) : '---',
          }))}
        />
      }
      fileName='certificate.pdf'
    >
      {({ loading }) => (loading ? 'Generating PDF...' : 'Download Certificate of Coverage')}
    </PDFDownloadLink>
  </div>
)

export default PDFGenerator
