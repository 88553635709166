import Box from '@mui/material/Box'
import { TableCell, TableRow, Collapse } from '@mui/material'
import Icon from '../stories/Icons'
import Button from '../stories/Button'
import Typography from '../stories/Typography'
import VoucherDetailsEntry from '@app/containers/VoucherDetailsEntry'

const ExternalClaimRow = ({ open = false, voucher = {}, headers }) => {
  return (
    <TableRow style={{ backgroundColor: '#faf5ff', border: 0, padding: 0 }}>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} />
      <TableCell style={{ padding: '0px' }} colSpan={headers.length - 1}>
        <Collapse in={open} timeout='auto' unmountOnExit>
          <Box display='flex' alignItems='center' my={2}>
            <Icon name='edit' fontSize='small' color='primary' />
            <Box ml={2}>
              <VoucherDetailsEntry voucher={voucher}>
                <Button size='small' variant='outlined'>
                  <Typography variant='body1' family='poppins'>
                    Details
                  </Typography>
                </Button>
              </VoucherDetailsEntry>
            </Box>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  )
}

export default ExternalClaimRow
