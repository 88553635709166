import { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Fade from '@mui/material/Fade'
import Typography from '../stories/Typography'
import PhoneImagesShifter from './PhoneImagesShifter'
import MuiTypography from '@mui/material/Typography'
import longevityBundle from '../assets/images/phoneImages/longevityBundle.mp4'
import homepageCycle from '../assets/images/phoneImages/homepageCycle.mp4'

const HomePageLanderHoverInfoMobile = ({ inputImages, inputText }) => {
  const [testDisplay, setTestDisplay] = useState(1)
  const [hover, setHover] = useState('1')
  const [imageFade, setImageFade] = useState(true)
  const timeoutRef = useRef(null)

  const handleChange = (number) => {
    if (testDisplay === number) return
    setHover(number)
    setImageFade(false)
    clearTimeout(timeoutRef.current)
    timeoutRef.current = setTimeout(() => {
      setTestDisplay(number)
      setImageFade(true)
    }, 200)
  }

  const HoverListItem = ({ text }) => {
    return (
      <Box height='20px'>
        <Typography
          variant='body1'
          align='center'
          weight='bold'
          style={{
            color: '#652d92',
          }}
        >
          {text}
        </Typography>
      </Box>
    )
  }

  HoverListItem.propTypes = {
    text: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired,
  }

  return (
    <>
      <Box>
        <Box
          width='100%'
          sx={{
            padding: {
              xs: '16px 16px 0 16px',
            },
            marginTop: 4,
          }}
        >
          <Fade
            in={true}
            timeout={2500}
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Box>
              <MuiTypography
                margin='0'
                color='#652d92'
                sx={{
                  textAlign: {
                    xs: 'center',
                    md: 'left',
                  },
                  fontSize: {
                    xs: '1.2rem',
                    sm: '1.25rem',
                  },
                  marginBottom: 1,
                  textTransform: 'uppercase',
                  letterSpacing: '0.1em',
                  width: '100%',
                  fontFamily: 'Poppins',
                  fontWeight: 500,
                }}
              >
                Healthcare&apos;s Unified Transaction Protocol
              </MuiTypography>
              <Box marginBottom='20px'>
                <MuiTypography
                  color='#652d92'
                  sx={{
                    textAlign: {
                      xs: 'center',
                      md: 'left',
                    },
                    fontSize: {
                      xs: '2.2rem',
                      sm: '3rem',
                      md: '4rem',
                    },
                    lineHeight: {
                      xs: '1.25',
                    },
                    fontFamily: 'Poppins',
                    fontWeight: 700,
                  }}
                >
                  Integrating the supply chain to improve quality
                </MuiTypography>
              </Box>
              <MuiTypography
                margin='0'
                color='#652d92'
                sx={{
                  textAlign: {
                    xs: 'center',
                    md: 'left',
                  },
                  fontSize: {
                    xs: '1.35rem',
                    sm: '1.5rem',
                    md: '1.75rem',
                  },
                  lineHeight: {
                    xs: '1.2',
                  },
                  marginBottom: {
                    xs: 1,
                    md: 3,
                  },
                  fontFamily: 'Poppins',
                  fontWeight: 300,
                }}
              >
                Enabling real-time value based care.
              </MuiTypography>
            </Box>
          </Fade>
        </Box>
      </Box>
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        width='100%'
        height='600px'
        flexDirection='column'
      >
        <Box
          position='relative'
          display='flex'
          justifyContent='center'
          alignItems='flex-start'
          height='min-content'
          width='100%'
          maxWidth='400px'
        >
          <Box zIndex='0'>
            <img src={inputImages[0]} alt='Mishe Network' width='100%' />
          </Box>
          <Box position='absolute' top='0' left='0' zIndex='1'>
            {testDisplay === 1 && (
              <video
                autoPlay
                loop
                muted
                playsInline
                src={homepageCycle}
                alt='Mishe Network'
                width='100%'
              />
            )}
            {testDisplay === 3 && (
              <video
                autoPlay
                loop
                muted
                playsInline
                src={longevityBundle}
                alt='Mishe Network'
                width='100%'
              />
            )}
            {testDisplay !== 1 && testDisplay !== 3 && (
              <img src={inputImages[testDisplay]} alt='Mishe Network' width='100%' />
            )}
          </Box>
        </Box>
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='space-around'
          alignItems='center'
          marginTop='-50px'
          width='100%'
          zIndex='10'
        >
          <PhoneImagesShifter
            cycleLength={3000}
            buttonColor='#652d92'
            testDisplay={testDisplay}
            handleChange={handleChange}
          >
            {inputText.map((item, index) => (
              <HoverListItem key={item.key} text={item.text} number={(index + 1).toString()} />
            ))}
          </PhoneImagesShifter>
        </Box>
      </Box>
    </>
  )
}

HomePageLanderHoverInfoMobile.propTypes = {
  inputImages: PropTypes.array.isRequired,
  inputText: PropTypes.array.isRequired,
}

export default HomePageLanderHoverInfoMobile
