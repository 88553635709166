import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import MuiBox from '@mui/material/Box'

const StyledSection = styled(MuiBox)`
  position: relative;
  ${(props) =>
    props.variant !== 'noPadding' &&
    css`
      &:first-child {
        padding: ${props.theme.spacing(8)} 0px ${props.theme.spacing(5)};
        ${props.theme.breakpoints.up('md')} {
          padding: ${props.theme.spacing(8)} 0px ${props.theme.spacing(10)};
        }
      }
    `}
  
  ${(props) =>
    props.variant === 'about' &&
    css`
      ${props.theme.breakpoints.up('md')} {
        margin-bottom: 80px;
      }
    `}
  ${(props) =>
    props.variant === 'landing' &&
    css`
      padding: ${props.theme.spacing(8)} px ${props.theme.spacing(8)} !important;
      ${props.theme.breakpoints.up('md')} {
        padding: ${props.theme.spacing(10)} 0px ${props.theme.spacing(10)};
      }
    `}
  ${(props) =>
    props.variant === 'search' &&
    css`
      &:first-child {
        padding: 300px 0px 300px;
        ${props.theme.breakpoints.up('md')} {
          padding: 200px 0px 200px;
        }
      }
    `}
}
`

const Section = ({ children, bgcolor = 'default', variant = 'default', ...props }) => (
  <StyledSection
    bgcolor={bgcolor}
    pt={{ xs: 2, md: 8 }}
    pb={{ xs: 8, md: 10 }}
    variant={variant}
    {...props}
  >
    {children}
  </StyledSection>
)

Section.propTypes = {
  children: PropTypes.node,
  bgcolor: PropTypes.string,
  variant: PropTypes.string,
}

export default Section
