import { useCallback } from 'react'
import { collection } from '../firebase/firestore'

const useNetworks = () => {
  const networkCollection = collection('networks')
  const organizationCollection = collection('organizations')
  // const { uid } = useCurrentProfile()

  const removeFTKeys = useCallback((obj) => {
    const fieldsList = ['createdAt', 'updatedAt', 'updatedBy', '_ft_updatedAt', '_ft_updatedBy']
    fieldsList.forEach((e) => delete obj[e])
    return obj
  }, [])

  const checkIfMember = useCallback(
    async ({ userID, clinicID, networkID }) => {
      if (!networkID || (!userID && !clinicID)) {
        console.log('  ** Check If Member - Missing params')
        return false
      }

      const memberDoc = await networkCollection
        .doc(networkID)
        .collection('members')
        .doc(clinicID || userID)
        .get()
        .catch((err) => {
          console.log(err)
          return { error: err, code: 'error-checking' }
        })

      if (memberDoc.exists) {
        console.log('  -- member found')
        return true
      }

      console.log('  -- member not found')
      return false
    },
    [networkCollection],
  )

  // This was built around the Maps usecase primarily for networkServiceInfoLanders
  const getClinicLocations = useCallback(
    async (clinicID) => {
      const locationsResponse = {
        id: clinicID,
        locations: [],
        error: false,
      }

      if (!clinicID) {
        locationsResponse.error = true
        locationsResponse.code = 'missing-params'
        locationsResponse.message = 'Missing clinic ID'
        return locationsResponse
      }

      // Only used if clinics (organizations) are not fetched at the service level
      // const fetchClinicData = [
      //   organizationCollection
      //     .doc(clinicID)
      //     .get()
      //     .catch(err => {
      //       console.log(err)
      //       locationsResponse.error = true
      //       locationsResponse.code = 'error-fetching-clinic'
      //       locationsResponse.message = err?.message
      //       return locationsResponse
      //     }),
      //   organizationCollection
      //     .doc(clinicID)
      //     .collection('locations')
      //     .get()
      //     .catch(err => {
      //       console.log(err)
      //       locationsResponse.error = true
      //       locationsResponse.code = 'error-fetching-locations'
      //       locationsResponse.message = err?.message
      //       return locationsResponse
      //     })
      // ]

      // const [clinicDoc, locationsDocs] = await Promise.all(fetchClinicData)

      // if (clinicDoc.error) {
      //   console.log('Clinic not found')
      //   return clinicDoc
      // }

      const locationsDocs = await organizationCollection
        .doc(clinicID)
        .collection('locations')
        .get()
        .catch((err) => {
          console.log(err)
          locationsResponse.error = true
          locationsResponse.code = 'error-fetching-locations'
          locationsResponse.message = err?.message
          return locationsResponse
        })

      if (locationsDocs.empty) {
        console.log('locations not found')
        locationsResponse.error = true
        locationsResponse.code = 'not-found'
        locationsResponse.message = 'Locations not found'
        return locationsResponse
      }
      const locations = await locationsDocs.docs.map((locationDoc) => {
        const locationDocData = locationDoc.data()
        return { ...locationDocData, id: locationDoc.id }
      })

      locationsResponse.locations = locations
      // locationsResponse.clinic = { ...clinicDoc.data(), id: clinicDoc.id }

      return locationsResponse
    },
    [organizationCollection],
  )

  const getService = useCallback(
    async ({ networkId, serviceId }) => {
      if (!serviceId || !networkId) {
        return { code: 'missing-params' }
      }
      const serviceDoc = await networkCollection
        .doc(networkId)
        .collection('services')
        .doc(serviceId)
        .get()
        .catch((err) => {
          console.log(err)
          return { error: err, code: 'error-fetching' }
        })
      if (serviceDoc !== undefined && serviceDoc.exists) {
        const docData = serviceDoc.data()
        return { ...docData, uid: serviceDoc.id }
      }
      return { code: 'not-found' }
    },
    [networkCollection],
  )

  const getServicesBySpecialtyTag = useCallback(
    async ({ networkId, specialtyTag }) => {
      if (!specialtyTag || !networkId) {
        return { code: 'missing-params' }
      }
      const servicesDocs = await networkCollection
        .doc(networkId)
        .collection('services')
        .where('specialtyTags', '==', specialtyTag)
        .get()
        .catch((err) => {
          console.log(err)
          return { error: err, code: 'error-fetching' }
        })
      if (servicesDocs.empty) {
        console.log('services not found')
        return { code: 'not-found' }
      }
      const services = servicesDocs.docs.map((serviceDoc) => {
        const serviceDocData = serviceDoc.data()
        return { ...serviceDocData, uid: serviceDoc.id }
      })
      return services
    },
    [networkCollection],
  )

  const getServicesByMisheCode = useCallback(
    async ({ netId, misheCode }) => {
      if (!misheCode || !netId) {
        return { code: 'missing-params' }
      }

      // Get all services with the misheCode
      const servicesDocs = await networkCollection
        .doc(netId)
        .collection('services')
        .where('misheCode', '==', misheCode)
        .get()
        .catch((err) => {
          console.log(err)
          return { error: err, code: 'error-fetching' }
        })

      if (servicesDocs.empty) {
        console.log('services not found')
        return { error: true, code: 'not-found' }
      }

      const services = await Promise.all(
        servicesDocs.docs.map(async (serviceDoc) => {
          const serviceDocData = serviceDoc.data()

          if (serviceDocData?.bundleComponent) {
            return null
          }

          // Check if the service is part of a bundle
          // If it is a bundle then get all the bundle components and the organization data for each
          if (serviceDocData?.bundle) {
            console.log('--- Bundle Service')
            const bundleComponentDocs = await serviceDoc.ref
              .collection('bundleComponents')
              .get()
              .catch((err) => {
                console.log('  ** Error fetching bundle components')
                console.log(err)
                return null
              })

            if (bundleComponentDocs?.empty || !bundleComponentDocs) {
              console.warn('  ** bundle components not found for: ', serviceDoc?.id)
              return { error: true, code: 'not-found' }
            }

            const bundleComponentIDs = bundleComponentDocs.docs.map((doc) => doc.data().serviceID)

            console.log('--- Bundle Component IDs: ', bundleComponentIDs)

            // Get all bundle components from the bundleComponentIDs
            const bundleComponents = await Promise.all(
              bundleComponentIDs.map(async (id) => {
                // Get the bundle component data from the database
                const bundleComponentDoc = await networkCollection
                  .doc(netId)
                  .collection('services')
                  .doc(id)
                  .get()
                  .catch((err) => {
                    console.log('  ** Error fetching bundle component')
                    console.log(err)
                    return { error: err, code: 'error-fetching' }
                  })

                if (bundleComponentDoc.exists) {
                  const bundleComponentDocData = bundleComponentDoc.data()
                  return {
                    ...bundleComponentDocData,
                    uid: bundleComponentDoc.id,
                  }
                }
                return null
              }),
            )

            const filteredBundleComponents = bundleComponents.filter((service) => service)

            // Get all organization data for each bundle component from the database based on the organizationId
            const bundleComponentsWithOrg = await Promise.all(
              filteredBundleComponents.map(async (service) => {
                if (service?.organizationId) {
                  // Get the organization data from the database
                  const organizationDoc = await organizationCollection
                    .doc(service.organizationId)
                    .get()
                    .catch((err) => {
                      console.log('  ** Error fetching organization')
                      console.log(err)
                      return { error: err, code: 'error-fetching' }
                    })

                  const organizationDocData = {
                    ...organizationDoc.data(),
                    uid: organizationDoc.id,
                  }

                  return {
                    ...service,
                    organization: organizationDocData,
                  }
                }
                return null
              }),
            )

            const filteredBundleComponentsWithOrg = bundleComponentsWithOrg.filter(
              (service) => service,
            )

            return {
              ...serviceDocData,
              uid: serviceDoc.id,
              bundleComponents: filteredBundleComponentsWithOrg,
            }
          }
          const organizationDoc = await organizationCollection
            .doc(serviceDocData.organizationId)
            .get()
            .catch((err) => {
              console.log('  ** Error fetching organization')
              console.log(err)
              return { error: err, code: 'error-fetching' }
            })

          const organizationDocData = {
            ...organizationDoc.data(),
            uid: organizationDoc.id,
          }

          return {
            ...serviceDocData,
            uid: serviceDoc.id,
            organization: organizationDocData,
          }
        }),
      ).then((serviceDocs) => serviceDocs.filter((service) => service))

      await services.sort((a) => {
        if (a.notOnNetwork) {
          return 1
        }
        return -1
      })
      return services
    },
    [networkCollection, organizationCollection],
  )

  const getLanderData = useCallback(
    async (networkId) => {
      if (!networkId) {
        return { code: 'missing-params' }
      }
      const landerDoc = await networkCollection
        .doc(networkId)
        .collection('landerInfo')
        .get()
        .catch((err) => {
          console.log(err)
          return { error: err, code: 'error-fetching' }
        })
      if (landerDoc.empty) {
        console.log('lander not found')
        return { code: 'not-found' }
      }
      const landerDocData = landerDoc.docs[0].data()
      // removes fields such as updatedAt, createdAt, etc
      // removeFTKeys(landerDocData)
      const specialtyLanderDoc = await landerDoc.docs[0].ref
        .collection('specialtyData')
        .orderBy('priority', 'desc')
        .get()
        .catch((err) => {
          console.log(err)
          return { error: err, code: 'error-fetching' }
        })
      // Need to go through each specialty and get the services & accumulate the featured services
      const featuredServices = []
      const specialtyLanderData = await Promise.all(
        specialtyLanderDoc.docs.map(async (specialtyDoc) => {
          const specialtyDocData = specialtyDoc.data()
          // removes fields such as updatedAt, createdAt, etc
          removeFTKeys(specialtyDocData)

          const serviceDocs = await specialtyDoc.ref
            .collection('servicesToDisplay')
            .orderBy('priority', 'desc')
            .get()
            .catch((err) => {
              console.log(err)
              return { error: err, code: 'error-fetching' }
            })

          const services = serviceDocs.docs.map((serviceDoc) => {
            const serviceDocData = serviceDoc.data()
            // removes fields such as updatedAt, createdAt, etc
            // removeFTKeys(serviceDocData)
            if (serviceDocData.featured) {
              featuredServices.push({ ...serviceDocData, uid: serviceDoc.id })
            }
            return { ...serviceDocData, uid: serviceDoc.id }
          })

          return { ...specialtyDocData, uid: specialtyDoc.id, services }
        }),
      )
      return {
        ...landerDocData,
        uid: landerDoc.docs[0].id,
        specialtyLanderData,
        featuredServices,
      }
    },
    [networkCollection, removeFTKeys],
  )

  const getNetworkBySlug = useCallback(
    async (networkSlug) => {
      if (!networkSlug) {
        return { code: 'missing-params' }
      }
      const networkDocs = await networkCollection
        .where('slug', '==', networkSlug)
        .get()
        .catch((err) => {
          console.warn('  ** Error fetching network by slug')
          console.log(err)
          if (err?.code === 'permission-denied') {
            return { error: err, code: 'permission-denied' }
          }
          return { error: err, code: 'error-fetching' }
        })

      if (networkDocs?.error || networkDocs?.code) {
        return networkDocs
      }

      if (networkDocs?.empty) {
        if (window.Intercom) {
          window.Intercom('trackEvent', 'frontend-error', {
            errorMessage: 'Network not found',
          })
        }
        return { code: 'not-found' }
      }

      const docData = networkDocs?.docs[0]?.data()

      return {
        ...docData,
        uid: networkDocs.docs[0].id,
      }
    },
    [networkCollection],
  )

  const getNetworkByName = useCallback(
    async (networkName) => {
      if (!networkName) {
        return { code: 'missing-params' }
      }
      const networkDocs = await networkCollection
        .where('name', '==', networkName)
        .get()
        .catch((err) => {
          console.log(err)
          return { error: err, code: 'error-fetching' }
        })
      if (networkDocs.empty) {
        console.log('network not found')
        return { code: 'not-found' }
      }
      const docData = networkDocs.docs[0].data()
      return { ...docData, uid: networkDocs.docs[0].id }
    },
    [networkCollection],
  )

  const getNetworkByID = useCallback(
    async (networkId) => {
      if (!networkId) {
        return { code: 'missing-params' }
      }
      const networkDoc = await networkCollection
        .doc(networkId)
        .get()
        .catch((err) => {
          console.log(err)
          return { error: err, code: 'error-fetching' }
        })
      if (!networkDoc.exists) {
        console.log('network not found')
        return { code: 'not-found' }
      }
      const data = networkDoc.data()
      return { ...data, uid: networkDoc.id }
    },
    [networkCollection],
  )

  return {
    getService,
    getNetworkByName,
    getNetworkBySlug,
    getNetworkByID,
    getServicesBySpecialtyTag,
    getServicesByMisheCode,
    getClinicLocations,
    getLanderData,
    checkIfMember,
  }
}

export default useNetworks
