import { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import Snackbar from '@mui/material/Snackbar'
import SnackbarContent from '@mui/material/SnackbarContent'
import Box from '@mui/material/Box'

const Buttons = ({ handleAgree }) => {
  return (
    <ButtonGroup
      color='primary'
      variant='contained'
      aria-label='contained primary button group'
      style={{
        width: '100%',
      }}
    >
      <Button
        style={{
          width: '50%',
        }}
      >
        <a
          href='/privacy-policy'
          style={{
            textDecoration: 'none',
            color: 'white',
          }}
        >
          More Info
        </a>
      </Button>
      <Button
        onClick={handleAgree}
        style={{
          width: '50%',
        }}
      >
        Agree
      </Button>
    </ButtonGroup>
  )
}

const CookiesNotice = () => {
  const [open, setOpen] = useState(true)

  useEffect(() => {
    const getCookie = () => {
      try {
        return !(
          document.cookie
            .split(';')
            .filter((row) => row.startsWith(' cookies_consented='))
            .map((c) => c.split('=')[1])[0] === 'true'
        )
      } catch (e) {
        return true
      }
    }
    setOpen(getCookie())
  }, [])

  const handleAgree = (_, reason) => {
    if (reason === 'clickaway') {
      return
    }

    const date = new Date()
    date.setFullYear(date.getFullYear() + 1) // Add 1 year to the current date
    document.cookie = `cookies_consented=true; expires=${date.toGMTString()}; path=/`

    setOpen(false)
  }

  const Message = () => {
    return (
      <>
        <Box
          style={{
            maxWidth: '250px',
          }}
        >
          Mishe uses cookies to improve your experience. Please note that by continuing to use this
          site you consent to the use of cookies. To close this prompt please manually agree.
        </Box>
        <Box mt={2}>
          <Buttons handleAgree={handleAgree} />
        </Box>
      </>
    )
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      onClose={handleAgree}
      style={{
        maxWidth: '300px',
      }}
    >
      <SnackbarContent
        style={{
          backgroundColor: 'white',
          color: 'black',
          boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',
        }}
        message={<Message />}
      />
    </Snackbar>
  )
}

export default CookiesNotice
