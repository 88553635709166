import { useState, useEffect } from 'react'
import { collection } from '../firebase/firestore'
import { requestClaimsShare, acceptClaimsShare, getSharedClaims } from '../firebase/functions'
import { convertToJSDate } from '@app/libs/formatters'

export const useEmployerPlan = (memberId, planId) => {
  const [planData, setPlanData] = useState(null)
  const [spendType, setSpendType] = useState(null)
  const [familyVouchers, setFamilyVouchers] = useState(null)
  const [memberData, setMemberData] = useState({})
  const [spendRules, setSpendRules] = useState(null)
  const [loaded, setLoaded] = useState(false)
  const [empty, setEmpty] = useState(false)

  const getMemberType = (type) => {
    switch (type) {
      case 1:
        return 'Subscriber'
      case 2:
        return 'Spouse'
      case 3:
        return 'Child'
      default:
        return 'Subscriber'
    }
  }

  useEffect(() => {
    let isMounted = true // To handle potential component unmounts during async calls.

    const fetchData = async () => {
      try {
        setLoaded(false)
        let relevantPlanMembers = await collection('networks')
          .doc(planId)
          .collection('members')
          .where('memberId', '==', memberId)
          .get()
          .then((snapshot) => {
            if (snapshot.empty) {
              return collection('networks')
                .doc(planId)
                .collection('members')
                .doc(memberId)
                .get()
                .then((doc) => {
                  if (doc.exists) {
                    return {
                      empty: false,
                      docs: [doc],
                    }
                  }
                  return {
                    empty: true,
                  }
                })
            }
            return snapshot
          })

        if (relevantPlanMembers?.empty) {
          setEmpty(true)
          setLoaded(true)
        } else {
          const currentMemberDoc = relevantPlanMembers.docs.find((doc) => doc.id === memberId)
          const currentMemberData = currentMemberDoc
            ? currentMemberDoc.data()
            : relevantPlanMembers.docs[0].data()

          if (!currentMemberData) {
            console.log('Member data not found.')
            setEmpty(true)
            setLoaded(true)
          }

          // TODO - Create a migration script to add type to all members
          // Need to account for old members that are dependents
          // Support old members that did not have a type
          if (!currentMemberData.type) {
            currentMemberData.type = 1
          }

          if (currentMemberData.type === 1 && currentMemberData?.familyPlanId) {
            relevantPlanMembers = await collection('networks')
              .doc(planId)
              .collection('members')
              .where('familyPlanId', '==', currentMemberData?.familyPlanId || '')
              .get()
              .catch((error) => {
                console.error('Failed to fetch family members:', error)
                // return previous data if fetching family members fails
                return relevantPlanMembers
              })
          }

          // TODO ADD FAMILY ID TO VOUCHERS
          const vouchersPerPerson = relevantPlanMembers.docs.map((doc) => ({
            memberId: doc.id,
            type: doc.data()?.type || 1,
            typeReadable: getMemberType(doc.data()?.type || 1),
            external_id: doc.data()?.external_id || '',
            group_id: doc.data()?.group_id || '',
            shareStatus: doc.data()?.shareStatus || 'not-shared',
            age: getMemberAge(doc.data()?.dob),
            dateJoined: doc.data()?.dateJoined || '',
            careExpires: doc.data()?.careExpires || '',
            name:
              doc.data()?.name ||
              `${doc.data()?.firstname || ''} ${doc.data()?.lastname || ''}`.trim(),
            vouchers: doc.data()?.vouchersSpentOn || [],
          }))

          const planRef = await collection('networks').doc(planId).get()
          const fetchedPlanData = planRef.data()

          if (!fetchedPlanData) {
            console.log('Plan data not found.')
            throw new Error('Plan data not found.')
          }

          const spendType = fetchedPlanData?.planDesign?.designType?.spend || null

          // Set Spend Information
          let spendDownRules = null
          if (spendType === 'down') {
            // if a family exists apply spend rules from the family
            const familyPlanId = currentMemberData?.familyPlanId

            let familyData = null

            if (familyPlanId) {
              familyData = await collection('networks')
                .doc(planId)
                .collection('families')
                .doc(familyPlanId)
                .get()
            }

            if (familyData?.exists && familyData?.data()?.spendRules?.sharedPool) {
              const { spendRules } = familyData.data()
              spendDownRules = {
                maxSpend: spendRules?.maxSpend || spendRules?.limit || 0,
                spent: spendRules?.spent || 0,
              }
            } else {
              const { spendRules } = currentMemberData
              spendDownRules = {
                maxSpend: spendRules?.maxSpend || spendRules?.limit || 0,
                spent: spendRules?.spent || 0,
              }
            }
          }

          if (isMounted) {
            setPlanData(fetchedPlanData)
            setSpendType(spendType)
            setMemberData(currentMemberData)

            setSpendRules(spendDownRules)
            setFamilyVouchers(vouchersPerPerson)
            setEmpty(false)
            setLoaded(true)
          }
        }
      } catch (error) {
        setEmpty(true)
        setLoaded(true)
        console.error('Failed to fetch plan data:', error)
      }
    }

    if (planId) {
      fetchData()
    }

    // Cleanup function
    return () => {
      isMounted = false // Prevent state updates if the component unmounts.
    }
  }, [memberId, planId])

  const familyBreakdown = () => {
    const output = []
    if (memberData.type === 1) {
      familyVouchers?.forEach((familyMember) => {
        if (familyMember.type !== 1) {
          const vouchers = familyMember.vouchers.map((voucher) => ({
            name: familyMember.name,
            type: familyMember.type,
            voucher,
          }))
          output.push(...vouchers)
        }
      })
    }
    return output
  }

  const totalFamilySpend = () => {
    return (
      familyVouchers?.reduce((total, member) => {
        const vouchersSpend = member.vouchers.reduce((sum, service) => sum + service.amountPaid, 0)
        return total + vouchersSpend
      }, 0) || 0
    )
  }

  const deductible = () => {
    const totalPaidTowardDeductible =
      familyVouchers?.reduce((total, member) => {
        return total + member.vouchers.reduce((sum, voucher) => sum + voucher.amountPaid, 0)
      }, 0) || 0

    const totalDeductible = planData?.planDesign?.deductible?.family || 0
    const remainingDeductible = totalDeductible - totalPaidTowardDeductible

    return {
      remaining: remainingDeductible,
      total: totalDeductible,
      spent: totalPaidTowardDeductible,
    }
  }

  const oop = () => {
    const totalPaidTowardOOP =
      familyVouchers?.reduce((total, member) => {
        return total + member.vouchers.reduce((sum, voucher) => sum + voucher.amountPaid, 0)
      }, 0) || 0

    const totalOOPMax = planData?.planDesign?.outOfPocketMax?.family || 0
    const remainingOOP = Math.max(0, totalOOPMax - totalPaidTowardOOP)

    return { remaining: remainingOOP, total: totalOOPMax, spent: totalPaidTowardOOP }
  }

  const spend = () => {
    const totalSpent =
      spendRules?.spent ||
      familyVouchers?.reduce((total, member) => {
        return total + member.vouchers.reduce((sum, voucher) => sum + voucher.amountPaid, 0)
      }, 0) ||
      0

    const remainingBalance = spendRules?.maxSpend - (spendRules?.spent || totalSpent || 0) || 0

    return { remaining: remainingBalance, max: spendRules.maxSpend, spent: totalSpent }
  }

  const handleRequestClaimsShare = async (targetMemberId) => {
    console.log('Requesting claims share from:', targetMemberId)
    const response = await requestClaimsShare({
      planId,
      requestedMemberId: targetMemberId,
    })
    console.log('Response:', response)
    return response?.status
  }

  const handleGrantClaimsShare = async () => {
    console.log('Granting claims share to:', memberId)
    const response = await acceptClaimsShare({
      planId,
      requestedMemberId: memberId,
    })
    console.log('Response:', response)
    return response
  }

  /**
   * Fetches shared claims for a specific member.
   * @param {string} memberId - The ID of the member whose shared claims are being fetched.
   * @returns {Promise<Object>} - A promise that resolves to an object containing the shared claims data or an error message.
   * If successful, the object will have the structure:
   * {
   *  status: 'success',
   *  claims: Array<Object>,
   *  error: null
   * }
   */
  const getMemberSharedClaims = async (memberId) => {
    console.log('Granting claims share to:', memberId)
    const response = await getSharedClaims({
      planId,
      requestedMemberId: memberId,
    }).catch((error) => {
      console.error('Failed to fetch shared claims:', error)
      return {
        error: 'Failed to fetch shared claims.',
      }
    })
    console.log('Response:', response)
    return response
  }

  // Utility functions
  const getMemberAge = (dob) => {
    const jsDob = convertToJSDate(dob)

    if (!jsDob) {
      console.warn('Invalid date of birth:', dob)
      return null
    }

    const ageDifMs = Date.now() - jsDob.getTime()
    const ageDate = new Date(ageDifMs)
    const age = Math.abs(ageDate.getUTCFullYear() - 1970)

    return age
  }

  return {
    loaded,
    empty,
    planData,
    spendType,
    familyVouchers,
    memberType: memberData.type,
    memberTypeReadable: getMemberType(memberData.type),
    external_id: memberData.external_id,
    group_id: memberData.group_id,
    familyBreakdown,
    totalFamilySpend,
    deductible,
    oop,
    spend,
    handleRequestClaimsShare,
    handleGrantClaimsShare,
    getMemberSharedClaims,
  }
}
