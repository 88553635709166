import LocationMarker from '@app/components/LocationMarker'

const filterOutNullLocations = (locs) =>
  locs.filter((location) => {
    const { geopoint, _geoloc } = location || {}
    const isValidGeoPoint = geopoint && geopoint._lat !== 0 && geopoint._long !== 0
    const isValidGeoloc =
      _geoloc &&
      _geoloc.lat !== 0 &&
      _geoloc.lng !== 0 &&
      _geoloc.lat !== undefined &&
      _geoloc.lng !== undefined

    return isValidGeoPoint || isValidGeoloc
  })

export const buildStandardMarkers = (clinicsArr, center) => {
  const uniqueLocationMarkers = {}

  clinicsArr.forEach((clinic, index) => {
    const { locations, services, error } = clinic

    if (error) {
      return
    }

    filterOutNullLocations(locations).forEach((location, secondIndex) => {
      if (!location) return // Ensure location is not null

      const locationID = clinic?.clinicID2 || location?.id || location?.uid || location?.objectID

      const lat = location?.geopoint?._lat ?? location?._geoloc?.lat ?? center?.lat
      const lng = location?.geopoint?._long ?? location?._geoloc?.lng ?? center?.lng

      // skip if this lat long is already in uniqueLocationMarkers
      if (uniqueLocationMarkers[`${lat}-${lng}`]) {
        return
      }

      const getLocationName =
        clinic?.locations?.find((clinicInfo) => location?.id === clinicInfo?.id) || null

      // Create the marker and store it in the uniqueLocationMarkers object
      uniqueLocationMarkers[`${lat}-${lng}`] = (
        <LocationMarker
          data={clinic}
          fromBundle={location.fromBundle}
          locationID={locationID}
          variant='clinicPage'
          locationName={
            clinic?.clinic?.name ||
            clinic?.clinicName ||
            getLocationName?.name ||
            clinic?.name ||
            ''
          }
          key={`${lat}-${lng}`} // use key as lat long
          lat={lat}
          lng={lng}
          text={location?.geopoint ? index + 1 : secondIndex + 1}
          address={location?.address || ''}
          city={location?.city || ''}
          state={location?.state || ''}
          hours={location?.operatingHours || ''}
          services={services || ['']}
          outOfNetwork={clinic?.notOnNetwork || false}
        />
      )
    })
  })

  return Object.values(uniqueLocationMarkers)
}

export const buildPharmacyMarkers = (
  pharmacies,
  center,
  handleSelectLocation,
  handleSwap,
  activeStep,
) => {
  const allLocations = []

  pharmacies.forEach((pharmacy) => {
    const { _geoloc, name, error, address, city, state, operatingHours } = pharmacy

    if (error) {
      return
    }

    const builtMarkers = _geoloc.map((location, index) => (
      <LocationMarker
        data={pharmacy}
        locationID={pharmacy?.id}
        locationName={name.toLowerCase()}
        variant='pharmacyPage'
        address={address?.toLowerCase() || ''}
        city={city?.toLowerCase() || ''}
        state={state || ''}
        hours={operatingHours || ''}
        key={index} // Unique key for React rendering
        lat={location?.lat || center?.lat}
        lng={location?.lng || center?.lng}
        handleSelectLocation={handleSelectLocation}
        handleSwap={handleSwap}
        activeStep={activeStep}
      />
    ))

    allLocations.push(...builtMarkers)
  })

  return allLocations
}

export const calculateBounds = (markers) => {
  const lats = markers.map((marker) => marker.lat)
  const lngs = markers.map((marker) => marker.lng)

  return {
    north: Math.max(...lats),
    south: Math.min(...lats),
    east: Math.max(...lngs),
    west: Math.min(...lngs),
  }
}

export const calculateCenter = (bounds) => {
  return {
    lat: (bounds.north + bounds.south) / 2,
    lng: (bounds.east + bounds.west) / 2,
  }
}

export const calculateZoom = (bounds) => {
  const MAP_DIMENSIONS = { width: 600, height: 800 }
  const WORLD_DIM = { width: 256, height: 256 }
  const ZOOM_MAX = 16

  const latDiff = bounds.north - bounds.south
  const lngDiff = bounds.east - bounds.west

  //  zoom level of one dimension
  const zoomLevel = (worldSize, diff, mapSize) => {
    return Math.floor(Math.log2((mapSize * 360) / (diff * worldSize)))
  }

  const zoomLat = zoomLevel(WORLD_DIM.height, latDiff, MAP_DIMENSIONS.height)
  const zoomLng = zoomLevel(WORLD_DIM.width, lngDiff, MAP_DIMENSIONS.width)

  // Return the smaller of the two zoom levels (to fit both dimensions)
  return Math.min(zoomLat, zoomLng, ZOOM_MAX)
}
