import { Button } from '@mui/material'
import Icon from '@app/stories/Icons'
import Typography from '@app/stories/Typography'
import GeneralModal from '@app/containers/GeneralModal'
import { Box } from '@mui/material'

// WIP
const ShareClaimsModal = ({
  buttonText = 'Share Claims',
  headerText = 'Viewing shared claims',
  children,
}) => {
  return (
    <GeneralModal
      className='view-shared-claims-modal'
      headerText={headerText}
      maxWidth={'lg'}
      customButton={
        <Button
          variant='outlined'
          size='small'
          style={{ textTransform: 'none', display: 'flex', alignItems: 'center' }}
        >
          <Icon name='assignmentOutlined' />
          <Typography variant='body2' style={{ marginLeft: '8px' }}>
            {buttonText}
          </Typography>
        </Button>
      }
    >
      <Box p={2}>{children}</Box>
    </GeneralModal>
  )
}

export default ShareClaimsModal
