import styled, { css } from 'styled-components'
import MuiBox from '@mui/material/Box'
import MuiTypography from '@mui/material/Typography'

const ArrowFoot = '80px'
const ArrowPeak = '56px'

const FeatureBox = styled(MuiBox)`
  position: relative;
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  ${(props) => props.theme.breakpoints.up('sm')} {
    ${(props) =>
      props.variant === 'maxi' &&
      css`
        width: calc(${ArrowFoot}*3);
      `}
  }
`

export const FeatureBoxText = styled(MuiTypography)`
  height: ${ArrowPeak};
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.palette.auxDark.dark};
  text-align: center;
  font-weight: 500;
  font-size: 1.125rem;
  ${(props) => props.theme.breakpoints.up('sm')} {
    font-size: 1.125rem;
  }
`

export const FeatureBoxImg = styled(MuiBox)`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  &:before {
    content: '';
    position: absolute;
    background: ${(props) => props.theme.palette.auxLight.dark};
  }
  ${(props) =>
    props.rowpos === 'top' &&
    css`
      padding-bottom: calc(${ArrowPeak} / 2);
    `}
  ${(props) =>
    props.rowpos === 'bottom' &&
    css`
      padding-top: calc(${ArrowPeak} / 2);
    `}
  ${(props) =>
    props.arrowed.xs === 'right' &&
    css`
      &:before {
        top: calc(50% - ${ArrowPeak} / 2);
        left: 50%;
        width: 50%;
        height: ${props.theme.spacing(5)};
        transform: translateY(-50%);
      }
    `}
  ${(props) =>
    props.arrowed.xs === 'bottom' &&
    css`
      &:before {
        top: 50%;
        left: 50%;
        transform: translateX(-50%);
        width: ${props.theme.spacing(5)};
        height: 50%;
      }
    `}
  ${(props) =>
    props.arrowed.xs === 'left' &&
    css`
      &:before {
        top: calc(50% + ${ArrowPeak} / 2);
        left: 0%;
        transform: translateY(-50%);
        width: 50%;
        height: ${props.theme.spacing(5)};
      }
    `}
  ${(props) =>
    props.arrowed.xs === 'top' &&
    css`
      &:before {
        top: 0%;
        left: 50%;
        transform: translateX(-50%);
        width: ${props.theme.spacing(5)};
        height: 50%;
      }
    `}
  ${(props) => props.theme.breakpoints.up('sm')} {
    ${(props) =>
      props.variant === 'maxi' &&
      css`
        padding: 0px;
      `}
    ${(props) =>
      props.arrowed.sm === 'right' &&
      css`
        &:before {
          top: 50%;
          left: 50%;
          width: 50%;
          transform: translateY(-50%);
          height: ${props.theme.spacing(5)};
        }
      `}
    ${(props) =>
      props.arrowed.sm === 'none' &&
      css`
        padding: 0px;
        &:before {
          display: none;
        }
      `}
  }
`

export default FeatureBox
