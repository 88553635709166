import axios from 'axios'
import { collection } from '@app/firebase/firestore'

const GOOGLE_API_KEY = import.meta.env.VITE_GOOGLE_API_KEY

const METER_TO_MILES = 0.000621371

// list all 50 states
export const STATE_OPTIONS = [
  {
    key: 'invalid',
    value: 'invalid',
    text: 'Select State',
    label: 'Select State',
  },
  { key: 'AL', value: 'AL', text: 'Alabama', label: 'Alabama' },
  { key: 'AK', value: 'AK', text: 'Alaska', label: 'Alaska' },
  { key: 'AS', value: 'AS', text: 'American Samoa', label: 'American Samoa' },
  { key: 'AZ', value: 'AZ', text: 'Arizona', label: 'Arizona' },
  { key: 'AR', value: 'AR', text: 'Arkansas', label: 'Arkansas' },
  { key: 'CA', value: 'CA', text: 'California', label: 'California' },
  { key: 'CO', value: 'CO', text: 'Colorado', label: 'Colorado' },
  { key: 'CT', value: 'CT', text: 'Connecticut', label: 'Connecticut' },
  { key: 'DE', value: 'DE', text: 'Delaware', label: 'Delaware' },
  {
    key: 'DC',
    value: 'DC',
    text: 'District Of Columbia',
    label: 'District Of Columbia',
  },
  {
    key: 'FM',
    value: 'FM',
    text: 'Federated States Of Micronesia',
    label: 'Federated States Of Micronesia',
  },
  { key: 'FL', value: 'FL', text: 'Florida', label: 'Florida' },
  { key: 'GA', value: 'GA', text: 'Georgia', label: 'Georgia' },
  { key: 'GU', value: 'GU', text: 'Guam', label: 'Guam' },
  { key: 'HI', value: 'HI', text: 'Hawaii', label: 'Hawaii' },
  { key: 'ID', value: 'ID', text: 'Idaho', label: 'Idaho' },
  { key: 'IL', value: 'IL', text: 'Illinois', label: 'Illinois' },
  { key: 'IN', value: 'IN', text: 'Indiana', label: 'Indiana' },
  { key: 'IA', value: 'IA', text: 'Iowa', label: 'Iowa' },
  { key: 'KS', value: 'KS', text: 'Kansas', label: 'Kansas' },
  { key: 'KY', value: 'KY', text: 'Kentucky', label: 'Kentucky' },
  { key: 'LA', value: 'LA', text: 'Louisiana', label: 'Louisiana' },
  { key: 'ME', value: 'ME', text: 'Maine', label: 'Maine' },
  {
    key: 'MH',
    value: 'MH',
    text: 'Marshall Islands',
    label: 'Marshall Islands',
  },
  { key: 'MD', value: 'MD', text: 'Maryland', label: 'Maryland' },
  { key: 'MA', value: 'MA', text: 'Massachusetts', label: 'Massachusetts' },
  { key: 'MI', value: 'MI', text: 'Michigan', label: 'Michigan' },
  { key: 'MN', value: 'MN', text: 'Minnesota', label: 'Minnesota' },
  { key: 'MS', value: 'MS', text: 'Mississippi', label: 'Mississippi' },
  { key: 'MO', value: 'MO', text: 'Missouri', label: 'Missouri' },
  { key: 'MT', value: 'MT', text: 'Montana', label: 'Montana' },
  { key: 'NE', value: 'NE', text: 'Nebraska', label: 'Nebraska' },
  { key: 'NV', value: 'NV', text: 'Nevada', label: 'Nevada' },
  { key: 'NH', value: 'NH', text: 'New Hampshire', label: 'New Hampshire' },
  { key: 'NJ', value: 'NJ', text: 'New Jersey', label: 'New Jersey' },
  { key: 'NM', value: 'NM', text: 'New Mexico', label: 'New Mexico' },
  { key: 'NY', value: 'NY', text: 'New York', label: 'New York' },
  { key: 'NC', value: 'NC', text: 'North Carolina', label: 'North Carolina' },
  { key: 'ND', value: 'ND', text: 'North Dakota', label: 'North Dakota' },
  {
    key: 'MP',
    value: 'MP',
    text: 'Northern Mariana Islands',
    label: 'Northern Mariana Islands',
  },
  { key: 'OH', value: 'OH', text: 'Ohio', label: 'Ohio' },
  { key: 'OK', value: 'OK', text: 'Oklahoma', label: 'Oklahoma' },
  { key: 'OR', value: 'OR', text: 'Oregon', label: 'Oregon' },
  { key: 'PW', value: 'PW', text: 'Palau', label: 'Palau' },
  { key: 'PA', value: 'PA', text: 'Pennsylvania', label: 'Pennsylvania' },
  { key: 'PR', value: 'PR', text: 'Puerto Rico', label: 'Puerto Rico' },
  { key: 'RI', value: 'RI', text: 'Rhode Island', label: 'Rhode Island' },
  { key: 'SC', value: 'SC', text: 'South Carolina', label: 'South Carolina' },
  { key: 'SD', value: 'SD', text: 'South Dakota', label: 'South Dakota' },
  { key: 'TN', value: 'TN', text: 'Tennessee', label: 'Tennessee' },
  { key: 'TX', value: 'TX', text: 'Texas', label: 'Texas' },
  { key: 'UT', value: 'UT', text: 'Utah', label: 'Utah' },
  { key: 'VT', value: 'VT', text: 'Vermont', label: 'Vermont' },
  { key: 'VI', value: 'VI', text: 'Virgin Islands', label: 'Virgin Islands' },
  { key: 'VA', value: 'VA', text: 'Virginia', label: 'Virginia' },
  { key: 'WA', value: 'WA', text: 'Washington', label: 'Washington' },
  { key: 'WV', value: 'WV', text: 'West Virginia', label: 'West Virginia' },
  { key: 'WI', value: 'WI', text: 'Wisconsin', label: 'Wisconsin' },
  { key: 'WY', value: 'WY', text: 'Wyoming', label: 'Wyoming' },
]

export const distanceToMiles = (result, zipcode) => {
  if (result._rankingInfo) {
    const info = result._rankingInfo
    if (info) {
      let miles
      if (zipcode) {
        miles = info.geoDistance * METER_TO_MILES
      } else if (info.matchedGeoLocation) {
        miles = info.matchedGeoLocation.distance * METER_TO_MILES
      } else {
        return null
      }
      return miles.toFixed(1)
    }
  }
  return null
}

export const getStateFromCoords = async (lat, lng) => {
  const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GOOGLE_API_KEY}`
  return axios
    .get(url)
    .then((res) => {
      const { data } = res
      if (data.results.length > 0) {
        const { address_components } = data.results[0]
        const state = address_components.find((component) =>
          component.types.includes('administrative_area_level_1'),
        )
        if (state) {
          return state.short_name
        }
      }
      return null
    })
    .catch((err) => {
      console.log(err)
      return null
    })
}

export const getCoordsFromAddress = async (address) => {
  const doc = await collection('zipcode-geolocations').doc(address).get()

  if (!doc.exists) {
    return null
  }

  const { lat, lng } = doc.data()

  return {
    lat,
    lng,
  }
}

// export const getCurrentPosition = (options = { timeout: 10000 }) => {
//   if (navigator.geolocation) {
//     return new Promise((resolve, reject) =>
//       navigator.geolocation.getCurrentPosition(resolve, reject, options),
//     )
//   }
//   console.log('Geolocation not supported')
//   return {
//     code: 1004,
//     message: 'Geolocation not supported',
//     error: null,
//   }
// }
