import PropTypes from 'prop-types'
import styled from 'styled-components'
import MuiBox from '@mui/material/Box'

const StyledBox = styled(MuiBox)`
  min-height: calc(100vh - ${(props) => props.theme.spacing(13)});
  padding-top: ${(props) => props.theme.spacing(8)};
  ${(props) => props.theme.breakpoints.up('md')} {
    top: ${(props) => props.theme.spacing(8)};
    min-height: calc(100vh - ${(props) => props.theme.spacing(10)});
  }
  @media (max-width: 768px) {
    padding-top: ${(props) => props.theme.spacing(7)};
  }
`

const Main = ({ children, ...props }) => {
  return <StyledBox {...props}>{children}</StyledBox>
}

Main.propTypes = {
  children: PropTypes.node,
}

export default Main
