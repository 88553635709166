import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { collection } from '../firebase/firestore'
import { useVouchers } from '@app/context/Vouchers'
import Vouchers from '@app/components/PatientVouchers'
import EmptyTab from '@app/components/EmptyTab'
import Modal from '@app/components/ClaimVoucherModal'
import { useCurrentProfile } from '@app/context/CurrentUser'

const PatientVouchers = () => {
  const { vouchers, changeVouchers, externalClaims, changeExternalClaims } = useVouchers()
  const { uid } = useCurrentProfile()

  const [allClaimsAndVouchers, setAllClaimsAndVouchers] = useState([])

  const { search } = useLocation()
  const slug = new URLSearchParams(search).get('claim')
  const flag = true

  useEffect(() => {
    let unsubscribeVouchers, unsubscribeClaims
    if (uid) {
      unsubscribeVouchers = collection('vouchers')
        .where('user.id', '==', uid)
        .onSnapshot((docs) => {
          return changeVouchers(docs, true)
        })

      unsubscribeClaims = collection('external-claims')
        .where('patient_info.user_id', '==', uid)
        // We have to query all because we need to show all financials on the main claims dashboard
        // .where('BILL_ENTRY_NUM', '==', '0001')
        .onSnapshot((docs) => {
          return changeExternalClaims(docs, true)
        })
    }
    return () => {
      if (unsubscribeVouchers) unsubscribeVouchers()
      if (unsubscribeClaims) unsubscribeClaims()
    }
  }, [uid, changeVouchers, changeExternalClaims])

  useEffect(() => {
    const allData = [...vouchers, ...externalClaims]
    console.log('allData', allData)
    setAllClaimsAndVouchers(allData)
  }, [vouchers, externalClaims])

  if (allClaimsAndVouchers.length === 0)
    return <EmptyTab>You haven&apos;t purchased any vouchers yet.</EmptyTab>
  return (
    <>
      {slug && <Modal vouchers={null} flag={flag || false} />}
      <Vouchers rows={allClaimsAndVouchers} />
    </>
  )
}

export default PatientVouchers
