import { useState, useCallback } from 'react'

const useSignInUpModals = () => {
  const [signUpOpen, setSignUpOpen] = useState(false)
  const [signInOpen, setSignInOpen] = useState(false)

  const handleOpen = useCallback(
    (type) => {
      if (type === 'signUp') {
        setSignUpOpen((previous) => !previous)
      } else {
        setSignInOpen((previous) => !previous)
      }
    },
    [setSignInOpen, setSignUpOpen],
  )

  return {
    handleOpen,
    signUpOpen,
    signInOpen,
  }
}

export default useSignInUpModals
