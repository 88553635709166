import { createContext, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import useToggleOpen from '@app/hooks/useToggleOpen'

const AddOrderModal = createContext()

export const useOrderModal = () => useContext(AddOrderModal)

const AddOrderModalProvider = ({ children }) => {
  const [orderType, setOrderType] = useState(null)
  const [anchorel, handleClick, handleClose] = useToggleOpen(null)
  const open = Boolean(anchorel)

  const value = { open, orderType, handleClick, handleClose, setOrderType }
  return <AddOrderModal.Provider value={value}>{children}</AddOrderModal.Provider>
}

AddOrderModalProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AddOrderModalProvider
