import { FC, useEffect } from 'react'
import { useLocation, Outlet, useParams } from 'react-router-dom'
import Main from '@app/stories/Main'
import MissingProfileInfoContainer from '@app/containers/MissingProfileInfoContainer'
import CookiesNotice from '@app/stories/CookiesNotice'
import { scrollToTop } from '@app/libs/scroll'
import TopBar from './TopBar'
import Footer from './Footer'
import ExternalFooter from './ExternalFooter'

const Layout: FC = () => {
  const { pathname } = useLocation()
  const { network } = useParams() // hacky solution to rely on url and not a context or some state
  // todo: app to be plan aware through context

  const isPlansPage = pathname.includes('/plans')
  const useExternalFooter = isPlansPage && network !== 'mishehealthplan'

  network
  useEffect(() => {
    scrollToTop({ behavior: 'instant' })
  }, [pathname])

  return (
    <>
      <TopBar />
      <MissingProfileInfoContainer />
      <Main>
        <Outlet />
      </Main>
      <CookiesNotice />
      {useExternalFooter ? <ExternalFooter /> : <Footer />}
    </>
  )
}

export default Layout
