import firebase, { getFirestoreDB } from './app'
import 'firebase/compat/firestore'
import { collection as collectionModule, doc, updateDoc } from 'firebase/firestore'

// Connect Firestore emulator if enabled
const firestoreEmulator = import.meta.env.VITE_FIREBASE_EMULATOR_FIRESTORE
if (firestoreEmulator) {
  const [host, port] = firestoreEmulator.split(':')
  // Apply the emulator on the compat firestore instance
  firebase.firestore().useEmulator(host, Number(port))
}

export const collection = (name) => firebase.firestore().collection(name)
export const collectionModular = (name) => collectionModule(getFirestoreDB, name)

export const subCollectionModular = ({ docName, docId, subDocName }) =>
  collectionModule(getFirestoreDB, docName, docId, subDocName)

export const collectionGroup = (name) => firebase.firestore().collectionGroup(name)

const getData = (doc) =>
  doc.get().then((value) => {
    if (value.exists)
      return {
        ...value.data(),
        id: value.id,
      }
    throw Error('NotFound')
  })

export const getDocument = (name, id) => getData(collection(name).doc(id))

export const createDocument = (name, id, data = {}) => collection(name).doc(id).set(data)

export const updateDocumentModular = ({ collectionName, documentName, data = {} }) => {
  return updateDoc(doc(getFirestoreDB, collectionName, documentName), data)
}
export const updateDocument = (name, id, data = {}) => {
  console.log('updateDocument', name, id, data)
  return collection(name).doc(id).update(data)
}

export const setMergeDocument = (name, id, data = {}) => {
  console.log('setMergeDocument', name, id, data)
  return collection(name).doc(id).set(data, { merge: true })
}

export const setMergeOrCreateDocument = async (name, id, data = {}) => {
  console.log('setMergeOrCreateDocument', name, id, data)
  return setMergeDocument(name, id, data).catch(() => {
    console.warn('there was an error')
    return createDocument(name, id, data)
  })
}

export const updateOrCreateDocument = async (name, id, data = {}) => {
  console.log('updateOrCreateDocument', name, id, data)
  return updateDocument(name, id, data).catch(() => {
    console.warn('there was an error')
    return createDocument(name, id, data)
  })
}

export const serverTimestamp = () => firebase.firestore.FieldValue.serverTimestamp()
