import { useState } from 'react'
import Proptypes from 'prop-types'
import List from '@mui/material/List'
import Typography from '../Typography'
import CollapsableListItem from '@app/components/CollapsableListItem'
import FormattedText from '../FormatFromDB'

const FAQ = ({ data }) => {
  const [currentIndex, setCurrentIndex] = useState('')

  const onChangeCollapse = (e) => {
    setCurrentIndex(e)
  }

  return (
    <List disablePadding>
      {data &&
        data.map((each) => (
          <CollapsableListItem
            key={each[0]}
            icon='question'
            currentIndex={currentIndex}
            onChangeCollapse={onChangeCollapse}
            title={<span>{each[1].q || 'Oops this question seems to be missing!'}</span>}
          >
            {each[1]?.a ? (
              <FormattedText variant='body1' key={each[1].a} text={each[1].a} />
            ) : (
              <Typography variant='body1'>Oops this answer seems to be missing!</Typography>
            )}
          </CollapsableListItem>
        ))}
    </List>
  )
}

FAQ.propTypes = {
  data: Proptypes.arrayOf(Proptypes.array).isRequired,
}

export default FAQ
