import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Checkbox from '@mui/material/Checkbox'
import { GENDER_OPTIONS } from '../libs/validators'
import PhoneField from '../stories/PhoneInput'
import SelectField from '../stories/Select'
import TextField from '../stories/TextField'
import Button from '../stories/Button'
import Link from '../stories/Link'
import Icon from '../stories/Icons'
import DobPicker from './DobPicker'
import Collapse from '@mui/material/Collapse'

import { useWhiteLabelingContext } from '@app/context/WhiteLabelingContext'

const SignUpForm = ({ onSubmit, errors = {}, selectedDate, setSelectedDate }) => {
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false)

  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')

  const [validPassword, setValidPassword] = useState(false)
  const [validPasswordLength, setValidPasswordLength] = useState(false)
  const [validPasswordNumber, setValidPasswordNumber] = useState(false)
  const [validPasswordUppercase, setValidPasswordUppercase] = useState(false)
  const [validPasswordLowercase, setValidPasswordLowercase] = useState(false)
  const [validPasswordSpecial, setValidPasswordSpecial] = useState(false)
  const [passwordMatch, setPasswordMatch] = useState(false)

  const [showPasswordRules, setShowPasswordRules] = useState(false)
  const [showPasswordError, setShowPasswordError] = useState(false)
  const [showPasswordMatch, setShowPasswordMatch] = useState(false)
  const [showPasswordMatchError, setShowPasswordMatchError] = useState(false)

  const { planColorPrimary } = useWhiteLabelingContext()

  useEffect(() => {
    if (password.length >= 8) {
      setValidPasswordLength(true)
    } else {
      setValidPasswordLength(false)
    }
    if (password.match(/[0-9]/)) {
      setValidPasswordNumber(true)
    } else {
      setValidPasswordNumber(false)
    }
    if (password.match(/[A-Z]/)) {
      setValidPasswordUppercase(true)
    } else {
      setValidPasswordUppercase(false)
    }
    if (password.match(/[a-z]/)) {
      setValidPasswordLowercase(true)
    } else {
      setValidPasswordLowercase(false)
    }
    if (/^(?=.*[!@#$%^&*_+?=-])/.test(password)) {
      setValidPasswordSpecial(true)
    } else {
      setValidPasswordSpecial(false)
    }
    if (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*_+?=-]).{8,}$/.test(password)) {
      setValidPassword(true)
    } else {
      setValidPassword(false)
    }
  }, [password])

  useEffect(() => {
    if (password === passwordConfirmation) {
      setPasswordMatch(true)
    } else {
      setPasswordMatch(false)
    }
  }, [password, passwordConfirmation])

  return (
    <form title='sign-up-form' onSubmit={onSubmit}>
      <TextField
        required
        name='firstname'
        label='First name'
        display='standard'
        inputProps={{
          title: 'firstname',
        }}
        helperText={errors.firstname}
      />
      <TextField
        required
        name='lastname'
        label='Last name'
        display='standard'
        inputProps={{
          title: 'lastname',
        }}
        helperText={errors.lastname}
      />
      <TextField
        required
        name='email'
        type='email'
        label='Email address'
        display='standard'
        inputProps={{
          title: 'email',
        }}
        helperText={errors.email}
      />
      <PhoneField
        required
        name='phone'
        label='Phone number'
        display='standard'
        inputProps={{
          title: 'phone',
        }}
        helperText={errors.phone}
      />
      <Box position='relative'>
        <TextField
          required
          name='password'
          type={passwordVisible ? 'text' : 'password'}
          label='Password'
          display='standard'
          inputProps={{
            title: 'password',
          }}
          onChange={(e) => setPassword(e.target.value)}
          onFocus={() => setShowPasswordRules(true)}
          onBlur={() => setShowPasswordError(true)}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: planColorPrimary,
              },
              '&:hover fieldset': {
                borderColor: planColorPrimary,
              },
              '&.Mui-focused fieldset': {
                borderColor: planColorPrimary,
              },
            },
            '& .MuiInputLabel-root': {
              color: planColorPrimary,
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: planColorPrimary,
            },
          }}
        />
        <Box
          position='absolute'
          right={0}
          top={0}
          bottom={0}
          mt={2}
          mb={2}
          color={planColorPrimary}
        >
          <Button variant='icon' onClick={() => setPasswordVisible(!passwordVisible)}>
            <Icon color='inherit' name={passwordVisible ? 'visibility' : 'visibilityOff'} />
          </Button>
        </Box>
      </Box>
      <Collapse in={showPasswordRules}>
        <Box>
          <Collapse in={!validPassword}>
            <Typography variant='body2' color='black'>
              Password must:
            </Typography>
          </Collapse>
          <Box ml={1}>
            <Collapse in={!validPasswordLength}>
              <Typography
                variant='body2'
                color={validPasswordLength ? 'green' : showPasswordError ? 'error' : 'black'}
              >
                Be at least 8 characters
              </Typography>
            </Collapse>
            <Collapse in={!validPasswordNumber}>
              <Typography
                variant='body2'
                color={validPasswordNumber ? 'green' : showPasswordError ? 'error' : 'black'}
              >
                Contain at least one number
              </Typography>
            </Collapse>
            <Collapse in={!validPasswordUppercase}>
              <Typography
                variant='body2'
                color={validPasswordUppercase ? 'green' : showPasswordError ? 'error' : 'black'}
              >
                Contain at least one uppercase letter
              </Typography>
            </Collapse>
            <Collapse in={!validPasswordLowercase}>
              <Typography
                variant='body2'
                color={validPasswordLowercase ? 'green' : showPasswordError ? 'error' : 'black'}
              >
                Contain at least one lowercase letter
              </Typography>
            </Collapse>
            <Collapse in={!validPasswordSpecial}>
              <Typography
                variant='body2'
                color={validPasswordSpecial ? 'green' : showPasswordError ? 'error' : 'black'}
              >
                Contain at least one special character
              </Typography>
            </Collapse>
          </Box>
          <Collapse in={validPassword}>
            <Typography variant='body2' color='green'>
              Password meets all requirements
            </Typography>
          </Collapse>
        </Box>
      </Collapse>
      <Box position='relative'>
        <TextField
          required
          name='passwordConfirmation'
          type={confirmPasswordVisible ? 'text' : 'password'}
          label='Password Confirmation'
          display='standard'
          inputProps={{
            title: 'password-confirmation',
          }}
          onChange={(e) => setPasswordConfirmation(e.target.value)}
          onFocus={() => setShowPasswordMatch(true)}
          onBlur={() => setShowPasswordMatchError(true)}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: planColorPrimary,
              },
              '&:hover fieldset': {
                borderColor: planColorPrimary,
              },
              '&.Mui-focused fieldset': {
                borderColor: planColorPrimary,
              },
            },
            '& .MuiInputLabel-root': {
              color: planColorPrimary,
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: planColorPrimary,
            },
          }}
        />
        <Box
          position='absolute'
          right={0}
          top={0}
          bottom={0}
          mt={2}
          mb={2}
          color={planColorPrimary}
        >
          <Button variant='icon' onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}>
            <Icon color='inherit' name={confirmPasswordVisible ? 'visibility' : 'visibilityOff'} />
          </Button>
        </Box>
      </Box>
      {showPasswordMatch && (
        <Box>
          <Collapse in={!passwordMatch}>
            <Typography variant='body2' color={showPasswordMatchError ? 'error' : 'black'}>
              Passwords do not match
            </Typography>
          </Collapse>
          <Collapse in={passwordMatch}>
            <Typography variant='body2' color='green'>
              Passwords match
            </Typography>
          </Collapse>
        </Box>
      )}
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid item xs={12} sm={6} style={{ marginTop: '16px' }}>
          <DobPicker dob={selectedDate} setDob={setSelectedDate} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            required
            label='Gender'
            name='gender'
            options={GENDER_OPTIONS}
            defaultValue=''
            helperText={errors.gender}
          />
        </Grid>
      </Grid>
      <Box mt={2}>
        <Typography variant='body2'>
          To use this account please review and agree to the following:
        </Typography>
      </Box>
      <Box display='flex' alignItems='center'>
        <Checkbox name='terms' color='primary' inputProps={{ 'aria-label': 'terms' }} />
        <Typography variant='body2' color={errors.terms ? 'error' : 'initial'}>
          <Link
            display='standard'
            size='small'
            target='_blank'
            to='/privacy-policy'
            style={{ fontWeight: '300' }}
          >
            Privacy Policy
          </Link>{' '}
          ,{' '}
          <Link
            display='standard'
            size='small'
            target='_blank'
            to='/terms'
            style={{ fontWeight: '300' }}
          >
            Terms of Use
          </Link>{' '}
          and{' '}
          <Link
            display='standard'
            size='small'
            target='_blank'
            to='/eula-consumer'
            style={{ fontWeight: '300' }}
          >
            EULA
          </Link>
          .
        </Typography>
      </Box>
      <Box display='flex' alignItems='center'>
        <Checkbox name='hipaa' color='primary' inputProps={{ 'aria-label': 'hipaa' }} />
        <Typography variant='body2' color={errors.hipaa ? 'error' : 'initial'}>
          <Link
            display='standard'
            size='small'
            target='_blank'
            to='/hipaa'
            style={{ fontWeight: '300' }}
          >
            HIPAA Authorization
          </Link>
        </Typography>
        .
      </Box>
      <Box display='flex' alignItems='center'>
        <Checkbox name='smsOptIn' color='primary' inputProps={{ 'aria-label': 'Text Opt In' }} />
        <Typography variant='body2' color='initial'>
          Agree to receive SMS about your account and orders. Message and data rates may apply.
        </Typography>
      </Box>
      <Box mt={2}>
        <Button type='submit' fullWidth>
          Create my account
        </Button>
      </Box>
    </form>
  )
}

SignUpForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    dob: PropTypes.string,
    email: PropTypes.string,
    firstname: PropTypes.string,
    gender: PropTypes.string,
    lastname: PropTypes.string,
    password: PropTypes.string,
    passwordConfirmation: PropTypes.string,
    terms: PropTypes.string,
    hipaa: PropTypes.string,
    phone: PropTypes.string,
  }),
}

export default SignUpForm
