import { useState, useEffect } from 'react'

const useQuery = (fetchFunction, dependencies = []) => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    let isMounted = true
    setLoading(true)
    setError(null)

    fetchFunction()
      .then((result) => {
        if (isMounted) {
          setData(result)
          setLoading(false)
        }
      })
      .catch((err) => {
        if (isMounted) {
          console.error('Error:', err)
          setError(err.message || 'An error occurred')
          setLoading(false)
        }
      })

    return () => {
      isMounted = false
    }
  }, dependencies)

  return { data, loading, error }
}

export default useQuery
