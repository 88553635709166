import Box from '@mui/material/Box'
import Button from '@app/stories/Button'
import Icon from '@app/stories/Icons'
import { usePagination as usePaginationAlgolia } from 'react-instantsearch'
import { useWhiteLabelingContext } from '@app/context/WhiteLabelingContext'

const CustomPagination = (props) => {
  const { pages, currentRefinement, isFirstPage, isLastPage, refine } = usePaginationAlgolia(props)

  const { planColorPrimary } = useWhiteLabelingContext()

  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      borderRadius='4px'
      marginBottom='8px'
    >
      <Button
        variant='unstyled'
        onClick={() => refine(currentRefinement - 1)}
        disabled={isFirstPage}
        style={{
          minWidth: '32px',
          maxWidth: '64px',
          margin: '0px',
          minHeight: '40px',
          height: '40px',
          borderRadius: '4px 0px 0px 4px',
          backgroundColor: 'white',
          color: isFirstPage ? '#00000030' : planColorPrimary,
          borderTop: `1px solid ${planColorPrimary}`,
          borderBottom: `1px solid ${planColorPrimary}`,
          borderLeft: `1px solid ${planColorPrimary}`,
        }}
        sx={{
          width: {
            xs: '32px',
            md: '64px',
          },
        }}
      >
        <Icon name='chevronLeft' />
      </Button>
      {pages.map((page) => (
        <Button
          variant='unstyled'
          key={page}
          onClick={() => refine(page)}
          disabled={currentRefinement === page}
          style={{
            minWidth: '32px',
            maxWidth: '64px',
            margin: '0px',
            minHeight: '40px',
            height: '40px',
            backgroundColor: currentRefinement === page ? planColorPrimary : 'white',
            color: currentRefinement === page ? 'white' : planColorPrimary,
            borderRadius: '0px',
            borderTop: `1px solid ${planColorPrimary}`,
            borderBottom: `1px solid ${planColorPrimary}`,
            borderLeft: `1px solid ${planColorPrimary}`,
          }}
          sx={{
            width: {
              xs: '32px',
              md: '64px',
            },
          }}
        >
          {page + 1}
        </Button>
      ))}
      <Button
        variant='unstyled'
        onClick={() => refine(currentRefinement + 1)}
        disabled={isLastPage}
        style={{
          minWidth: '32px',
          maxWidth: '64px',
          margin: '0px',
          minHeight: '40px',
          height: '40px',
          borderRadius: '0px 4px 4px 0px',
          color: isLastPage ? '#00000030' : planColorPrimary,
          backgroundColor: 'white',
          border: `1px solid ${planColorPrimary}`,
        }}
        sx={{
          width: {
            xs: '32px',
            md: '64px',
          },
        }}
      >
        <Icon name='chevronRight' />
      </Button>
    </Box>
  )
}

export default CustomPagination
