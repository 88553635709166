import { createRoot } from 'react-dom/client'
import Intercom from '@intercom/messenger-js-sdk'
import App from './App'

// Initialize Intercom Messenger SDK
Intercom({
  region: 'us',
  app_id: 'kgysbru8',
  custom_launcher_selector: '.open-live-chat',
  session_duration: 60 * 60 * 1000,
})

createRoot(document.getElementById('root')).render(<App />)
