import { useCallback, useState } from 'react'
import { Button, CircularProgress } from '@mui/material'
import Icon from '@app/stories/Icons'
import Typography from '@app/stories/Typography'
import GeneralModal from '@app/containers/GeneralModal'
import Box from '@mui/material/Box'
import { formatDateAndTimeForUI, formatCurrency } from '@app/libs/formatters'

const ViewClaimsModal = ({
  buttonText = 'View Claims',
  headerText = 'Viewing shared claims',
  plan = {},
  memberInfo = {},
}) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [claims, setClaims] = useState([])

  const handleRequest = async () => {
    setError(null)
    setLoading(true)
    const claimsResponse = await plan.getMemberSharedClaims(memberInfo.memberId)
    setClaims(claimsResponse.claims)
    setError(claimsResponse.error)
    setLoading(false)
  }

  const buildClaims = useCallback(() => {
    return claims.map((claim, index) => {
      return (
        <Box
          key={index}
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='flex-start'
          border={1}
          borderRadius={4}
          padding={2}
          margin={1}
          width='100%'
          maxWidth={400}
        >
          <Typography variant='h6'>Provider Name: {claim.providerName || '---'}</Typography>
          <Typography variant='body1'>Service: {claim.service || '---'}</Typography>
          <Typography variant='body1'>
            Rendered Date: {formatDateAndTimeForUI(claim.date) || '---'}
          </Typography>
          <Typography variant='body1'>Amount: {formatCurrency(claim.amount) || '---'}</Typography>
        </Box>
      )
    })
  }, [claims])

  return (
    <GeneralModal
      className='view-claims-modal'
      headerText={headerText}
      maxWidth='lg'
      customButton={
        <Button
          variant='outlined'
          size='small'
          style={{ textTransform: 'none', display: 'flex', alignItems: 'center' }}
        >
          <Icon name='assignmentOutlined' />
          <Typography variant='body2' style={{ marginLeft: '8px' }}>
            {buttonText}
          </Typography>
        </Button>
      }
      onOpen={handleRequest}
    >
      <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
        {error && <Typography variant='body1'>{error}</Typography>}
        {loading && <CircularProgress />}
        {claims.length > 0 && buildClaims()}
      </Box>
    </GeneralModal>
  )
}

export default ViewClaimsModal
