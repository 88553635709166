import PropTypes from 'prop-types'
import { Box, Grid } from '@mui/material'
import InstantSearchContainer from '../../containers/InstantSearchContainer'
import ServiceSearchCard from '../ServiceSearchCard'

const ServiceSelection = ({ values, setters }) => {
  const { serviceSelected, algoliaFilters } = values
  const { setServiceSelected } = setters

  let config = {
    hitsPerPage: 5,
  }

  if (algoliaFilters) {
    config = {
      ...config,
      filters: algoliaFilters,
    }
  }

  return (
    <Grid container spacing={3}>
      {!serviceSelected && (
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <InstantSearchContainer
            indexName='network_services'
            HitComponent={ServiceSearchCard}
            hitClick={(data) => {
              if (window.Intercom) {
                window.Intercom('trackEvent', 'Service Selected', {
                  serviceName: data?.name,
                  cptCode: data?.cptCode,
                  clinicName: data?.clinicName,
                })
              }
              setServiceSelected(data)
            }}
            configuration={config}
            searchBoxText='Search for a service'
            noResultsMessage='No results'
          />
        </Grid>
      )}
      {serviceSelected && (
        <Box
          width='100%'
          paddingLeft='24px'
          sx={{
            paddingTop: {
              xs: '24px',
              md: '0px',
            },
          }}
        >
          <ServiceSearchCard
            data={serviceSelected}
            clearSelection
            buttonText='Clear Selection'
            onClick={() => {
              if (window.Intercom) {
                window.Intercom('trackEvent', 'Service Cleared', {})
              }
              setServiceSelected(null)
            }}
          />
        </Box>
      )}
    </Grid>
  )
}

ServiceSelection.propTypes = {
  values: PropTypes.shape({
    serviceSelected: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    algoliaFilters: PropTypes.string,
  }).isRequired,
  setters: PropTypes.shape({
    setServiceSelected: PropTypes.func.isRequired,
  }).isRequired,
}

export default ServiceSelection
