// DateTimePickerCell.js
import { useState } from 'react'
import PropTypes from 'prop-types'
import { Snackbar } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'
import styled from '@mui/system/styled'
import { formatDateForUI } from '../libs/formatters'
import { collection } from '../firebase/firestore'

const DateTimePickerStyled = styled(DateTimePicker)(() => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': { borderWidth: 0 },
    '&:hover fieldset': { borderWidth: 0 },
    '&.Mui-focused fieldset': { borderWidth: '1px' },
  },
}))

const DateTimePickerCell = ({ voucher }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false)

  const onAcceptTriggerAppDate = async (date) => {
    if (voucher.id) {
      try {
        // workaround given db struct for now. this code should be handle by voucher context, and there should not be branching logic in client to update a voucher or subvouhcer. TODO
        if (voucher.parentUid) {
          await collection('vouchers')
            .doc(parentUid)
            .collection('subVouchers')
            .doc(voucher.id)
            .set({ appointmentDate: date }, { merge: true })
        } else {
          await collection('vouchers')
            .doc(voucher.id)
            .set({ appointmentDate: date }, { merge: true })
        }

        setSnackbarOpen(true)
      } catch (error) {
        console.error('Failed to update appointment date:', error)
      }
    }
  }

  const handleCloseSnackbar = () => setSnackbarOpen(false)

  return (
    <>
      <DateTimePickerStyled
        slotProps={{ actionBar: { actions: ['cancel', 'accept'] } }}
        onAccept={onAcceptTriggerAppDate}
        defaultValue={
          voucher.appointmentDate ? new Date(formatDateForUI(voucher.appointmentDate, true)) : null
        }
        label={voucher.appointmentDate ? '' : 'Add Date'}
      />
      <Snackbar
        autoHideDuration={1500}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        message='Appointment Date Updated'
      />
    </>
  )
}

DateTimePickerCell.propTypes = {
  voucher: PropTypes.object.isRequired,
}

export default DateTimePickerCell
