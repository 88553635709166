import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Collapse from '@mui/material/Collapse'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import MuiMarkdown from 'mui-markdown'
import { formatMarkdown } from '@app/libs/formatters'
import NotesAttributes from '@app/components/NotesAttributes'
import Pagination from '@mui/material/Pagination'

const PAGINATION_ROWS = 5

const renderContent = (note) => {
  if (note.noteType === 'text') {
    return (
      <Box sx={{ padding: '10px' }}>
        <MuiMarkdown>{formatMarkdown(note?.content || '')}</MuiMarkdown>
        <Divider sx={{ margin: '1rem 0' }} />
        <NotesAttributes {...note} showShare={false} />
      </Box>
    )
  }
  if (note.noteType === 'image') {
    return (
      <Box sx={{ padding: '10px' }}>
        <img
          src={note.content}
          alt='note'
          width='100%'
          style={{ borderRadius: '5px', boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' }}
        />
      </Box>
    )
  }
  if (note.noteType === 'file') {
    return (
      <Box sx={{ padding: '10px' }}>
        <a href={note.content} download={note.fileName} target='_blank' rel='noopener noreferrer'>
          <Button variant='outlined' size='small' color='primary'>
            Download {note.fileName || 'File'}
          </Button>
        </a>
      </Box>
    )
  }
  return 'N/A'
}

const PatientDataTable = ({ notes }) => {
  const [openRows, setOpenRows] = useState({})
  const [page, setPage] = useState(1)

  const toggleRow = (index) => {
    setOpenRows((prev) => ({ ...prev, [index]: !prev[index] }))
  }

  const paginatedNotes = notes.slice((page - 1) * PAGINATION_ROWS, page * PAGINATION_ROWS)

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant='h5' gutterBottom sx={{ color: '#652d92' }}>
        Patient Data ({notes.length} Notes)
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Note Type</TableCell>
              <TableCell>Service Name</TableCell>
              <TableCell>Code</TableCell>
              <TableCell>Provider</TableCell>
              <TableCell>Organization</TableCell>
              <TableCell>Timestamp</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedNotes.map((note, index) => (
              <React.Fragment key={index}>
                <TableRow>
                  <TableCell>
                    <IconButton
                      aria-label='expand row'
                      size='small'
                      onClick={() => toggleRow(index)}
                    >
                      {openRows[index] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                  </TableCell>
                  <TableCell>{note.noteType || 'N/A'}</TableCell>
                  <TableCell>{note.voucher?.service?.name || 'N/A'}</TableCell>
                  <TableCell>{note.voucher?.code || 'N/A'}</TableCell>
                  <TableCell>{note.organization?.provider || 'N/A'}</TableCell>
                  <TableCell>{note.organization?.name || 'N/A'}</TableCell>
                  <TableCell>
                    {note.timestamp ? new Date(note.timestamp.toDate()).toLocaleString() : 'N/A'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={7} style={{ paddingBottom: 0, paddingTop: 0 }}>
                    <Collapse in={openRows[index]} timeout='auto' unmountOnExit>
                      {renderContent(note)}
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
        <Pagination
          count={Math.ceil(notes.length / PAGINATION_ROWS)}
          page={page}
          onChange={(event, value) => {
            setPage(value)
            setOpenRows({})
          }}
          color='primary'
        />
      </Box>
    </Box>
  )
}

export default PatientDataTable
