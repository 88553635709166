import { useCallback, useState, useEffect } from 'react'
import { useVouchers } from '@app/context/Vouchers'
import RedeemVoucherModal from '@app/components/RedeemVoucherModal'
import { formatDateForDatePickerUI, convertFirestoreToDate } from '../libs/formatters'

const RedeemVoucherModalContainer = ({ open, voucher, handleClose }) => {
  const { redeemVoucher } = useVouchers()
  const [errors, setErrors] = useState({})

  useEffect(() => {
    if (open) {
      setErrors({})
    }
  }, [open])

  const onRedeem = useCallback(
    async (voucher) => {
      let redeemVoucherResult
      if (voucher.parentUid) {
        redeemVoucherResult = await redeemVoucher(voucher.parentUid, voucher.id)
      } else {
        redeemVoucherResult = await redeemVoucher(voucher.id)
      }
      if (redeemVoucherResult) {
        handleClose()
      } else {
        setErrors({ redeem: 'There was an error redeeming the voucher' })
      }
    },
    [redeemVoucher, handleClose],
  )

  return (
    // change this to directly render the modal. no need for this second layer component
    <RedeemVoucherModal
      open={true}
      voucherCode={voucher?.code}
      errors={errors}
      customerFirstName={voucher?.customer?.firstname}
      customerLastName={voucher?.customer?.lastname}
      customerDOB={
        formatDateForDatePickerUI(voucher?.customer?.dob) ||
        formatDateForDatePickerUI(voucher?.customer?.birthday)
      }
      service={voucher?.service?.name}
      handleClose={handleClose}
      onRedeem={(e) => {
        e.preventDefault()
        onRedeem(voucher)
      }}
    />
  )
}

export default RedeemVoucherModalContainer
