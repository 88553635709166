// remove the question mark from the query string
const parseQueryString = (queryString) => {
  const params = {}
  const queries = queryString.replace('?', '').split('&')
  queries.forEach((query) => {
    const [key, value] = query.split('=')
    params[key] = value
  })
  return params
}

export default parseQueryString
