import { formatDateForUI } from '@app/libs/formatters'

export const formatDollarAmount = (amount) => {
  return amount ? `$${amount?.toLocaleString()}` : ''
}

export const formatBenefit = (benefit) => {
  const { copay, coinsurance, deductible } = benefit || {}
  if (!copay && !deductible && !coinsurance) return '---'

  const parts = [
    copay && `${formatDollarAmount(copay)} Copay`,
    deductible && 'Deductible',
    coinsurance && `${coinsurance}%`,
  ].filter(Boolean)

  return parts.join(' + ')
}

const drawElem = (ctx, text, x, y, fill = 'white', fontWeight = 'normal', fontSize = 24) => {
  ctx.font = `${fontWeight} ${fontSize}px Arial`
  ctx.fillStyle = fill
  ctx.fillText(text, x, y)
}

// Bad code to figure out is its MCC health plan. we shouldn't be doing anything particular here
// correct way is to checkPlanData and check the image url to use
const isMCCHealthPlan = (cardImageName) => {
  return cardImageName === 'MCC_member_card.svg'
}

export const drawCard = (ctx, data) => {
  if (isMCCHealthPlan(data.cardImageName)) {
    drawMCCCard(ctx, data)
  } else {
    drawDefaultCard(ctx, data)
  }
}

const drawMCCCard = (ctx, data) => {
  const {
    firstName,
    lastName,
    memberNumber,
    groupNumber, // bad code, needs to be fixed when we normalize member data in network
    pcpCopay,
    specialistCopay,
    emergencyRoomCopay,
    memberServiceNumber,
    planType,
    effectiveDate,
    prescriptionGroup,
    prescriptionCopay,
    width,
    height,
  } = data

  const fontSize = width * 0.025
  let Y = height * 0.335
  let X = width * 0.22
  const incY = fontSize * 2.05

  drawElem(ctx, `Mark Cuban Companies`, X, Y, '#000')
  drawElem(ctx, `${groupNumber || '---'}`, X, (Y += incY))
  drawElem(ctx, `${firstName || '---'} ${lastName || '---'}`, X, (Y += incY), '#000')
  drawElem(ctx, `${memberNumber || '---'}`, X, (Y += incY))
  drawElem(ctx, `Rx Bin`, X, (Y += incY), '#000')
  drawElem(ctx, `PCN`, X, (Y += incY), '#000')
  drawElem(ctx, `${memberServiceNumber}`, X, (Y += incY), '#000')
  drawElem(ctx, `www.affirmedrx.com`, X, (Y += incY), '#000')

  X = width * 0.55
  Y = height * 0.52
  const newIncY = fontSize * 1.4

  drawElem(ctx, `Deductibles:`, X, Y, 'white', 'bold')
  drawElem(ctx, `PCP Copay: ${pcpCopay}`, X, (Y += newIncY))
  drawElem(ctx, `Specialist Copay: ${specialistCopay || '---'}`, X, (Y += newIncY))
  drawElem(ctx, `ER Copay: ${emergencyRoomCopay || '---'}`, X, (Y += newIncY))
  drawElem(ctx, `Plan Type: ${planType}`, X, (Y += newIncY))
  drawElem(ctx, `Effec. Date: ${formatDateForUI(effectiveDate) || '---'}`, X, (Y += newIncY))
  drawElem(ctx, `RX Group: ${prescriptionGroup}`, X, (Y += newIncY))
  drawElem(ctx, `RX Copay: ${prescriptionCopay}`, X, (Y += newIncY))
}

const drawDefaultCard = (ctx, data) => {
  const {
    firstName,
    lastName,
    memberNumber,
    groupNumber, // bad code, needs to be fixed when we normalize member data in network
    pcpCopay,
    specialistCopay,
    emergencyRoomCopay,
    memberServiceNumber,
    planType,
    effectiveDate,
    prescriptionGroup,
    prescriptionCopay,
    width,
    planID, // bad code, needs to be fixed when we normalize member data in network
    height,
  } = data

  const fontSize = width * 0.025
  let Y = height * 0.2
  let X = width * 0.1
  const incY = fontSize * 1.2
  const incYNewLine = fontSize * 2.5

  // Left Section
  drawElem(ctx, 'Member Name', X, Y, 'white', 'bold')
  drawElem(ctx, `${firstName} ${lastName}`, X, (Y += incY))
  drawElem(ctx, 'Plan Type', X, (Y += incYNewLine), 'white', 'bold')
  drawElem(ctx, planType, X, (Y += incY))
  drawElem(ctx, 'Copay', X, (Y += incYNewLine), 'white', 'bold')
  drawElem(ctx, `PCP Copay: ${pcpCopay}`, X, (Y += incY))
  drawElem(ctx, `Specialist Copay: ${specialistCopay}`, X, (Y += incY))
  drawElem(ctx, `ER Copay: ${emergencyRoomCopay}`, X, (Y += incY))
  drawElem(ctx, `Prescription: ${prescriptionCopay}`, X, (Y += incY))

  // Center Section
  X = width / 2.5
  Y = height * 0.2
  drawElem(ctx, 'Member ID', X, Y, 'white', 'bold')
  drawElem(ctx, memberNumber, X, (Y += incY))
  drawElem(ctx, 'Group ID', X, (Y += incYNewLine), 'white', 'bold')
  drawElem(ctx, groupNumber || planID, X, (Y += incY))
  drawElem(ctx, 'Prescription Group', X, (Y += incYNewLine), 'white', 'bold')
  drawElem(ctx, prescriptionGroup, X, (Y += incY))

  // Bottom-Right Section
  X = width / 1.5
  Y = height * 0.6

  drawElem(ctx, 'Effective Date', X, (Y += incY), 'white', 'bold')
  drawElem(ctx, formatDateForUI(effectiveDate) || '---', X, (Y += incY))

  drawElem(ctx, 'Member Service', X, (Y += incYNewLine), 'white', 'bold')
  drawElem(ctx, memberServiceNumber, X, (Y += incY))
}
