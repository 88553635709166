import { useState } from 'react'
import { Grid, Checkbox, FormControlLabel } from '@mui/material'
import Box from '@mui/material/Box'
import TextField from '../stories/TextField'
import Typography from '../stories/Typography'
import SelectField from '../stories/Select'
import Logo from '../stories/Logo'
import Button from '../stories/Button'
import { collection, serverTimestamp } from '../firebase/firestore'
import { sendProviderEnrollmentNotification } from '../firebase/functions'

const JoinMishe = () => {
  const [submitted, setSubmitted] = useState(false)
  const [error, setError] = useState(false)

  // controlled form states
  const [practiceName, setPracticeName] = useState('')
  const [individualProviderName, setIndividualProviderName] = useState('')
  const [practiceAddress, setPracticeAddress] = useState('')
  const [practiceAddress2, setPracticeAddress2] = useState('')
  const [practiceCity, setPracticeCity] = useState('')
  const [practiceState, setPracticeState] = useState('')
  const [practiceZip, setPracticeZip] = useState('')
  const [practicePhone, setPracticePhone] = useState('')
  const [administratorName, setAdministratorName] = useState('')
  const [administratorTitle, setAdministratorTitle] = useState('')
  const [administratorPhone, setAdministratorPhone] = useState('')
  const [administratorEmail, setAdministratorEmail] = useState('')
  const [administratorFax, setAdministratorFax] = useState('')
  const [groupNPI, setGroupNPI] = useState('')
  const [mainPracticeTaxID, setMainPracticeTaxID] = useState('')
  const [singleProvider, setSingleProvider] = useState(false)
  const [isMultiProvider, setIsMultiProvider] = useState(false)
  const [healthSystems, setHealthSystems] = useState('')
  const [hospitalNames, setHospitalNames] = useState('')
  const [mainReason, setMainReason] = useState('')
  const [hearAboutMishe, setHearAboutMishe] = useState('')

  const STATE_OPTIONS = [
    { value: '', label: 'Select a State' },
    { value: 'AL', label: 'Alabama' },
    { value: 'AK', label: 'Alaska' },
    { value: 'AZ', label: 'Arizona' },
    { value: 'AR', label: 'Arkansas' },
    { value: 'CA', label: 'California' },
    { value: 'CO', label: 'Colorado' },
    { value: 'CT', label: 'Connecticut' },
    { value: 'DE', label: 'Delaware' },
    { value: 'DC', label: 'District Of Columbia' },
    { value: 'FL', label: 'Florida' },
    { value: 'GA', label: 'Georgia' },
    { value: 'HI', label: 'Hawaii' },
    { value: 'ID', label: 'Idaho' },
    { value: 'IL', label: 'Illinois' },
    { value: 'IN', label: 'Indiana' },
    { value: 'IA', label: 'Iowa' },
    { value: 'KS', label: 'Kansas' },
    { value: 'KY', label: 'Kentucky' },
    { value: 'LA', label: 'Louisiana' },
    { value: 'ME', label: 'Maine' },
    { value: 'MD', label: 'Maryland' },
    { value: 'MA', label: 'Massachusetts' },
    { value: 'MI', label: 'Michigan' },
    { value: 'MN', label: 'Minnesota' },
    { value: 'MS', label: 'Mississippi' },
    { value: 'MO', label: 'Missouri' },
    { value: 'MT', label: 'Montana' },
    { value: 'NE', label: 'Nebraska' },
    { value: 'NV', label: 'Nevada' },
    { value: 'NH', label: 'New Hampshire' },
    { value: 'NJ', label: 'New Jersey' },
    { value: 'NM', label: 'New Mexico' },
    { value: 'NY', label: 'New York' },
    { value: 'NC', label: 'North Carolina' },
    { value: 'ND', label: 'North Dakota' },
    { value: 'OH', label: 'Ohio' },
    { value: 'OK', label: 'Oklahoma' },
    { value: 'OR', label: 'Oregon' },
    { value: 'PA', label: 'Pennsylvania' },
    { value: 'PR', label: 'Puerto Rico' },
    { value: 'RI', label: 'Rhode Island' },
    { value: 'SC', label: 'South Carolina' },
    { value: 'SD', label: 'South Dakota' },
    { value: 'TN', label: 'Tennessee' },
    { value: 'TX', label: 'Texas' },
    { value: 'UT', label: 'Utah' },
    { value: 'VT', label: 'Vermont' },
    { value: 'VA', label: 'Virginia' },
    { value: 'WA', label: 'Washington' },
    { value: 'WV', label: 'West Virginia' },
    { value: 'WI', label: 'Wisconsin' },
    { value: 'WY', label: 'Wyoming' },
  ]

  const boxStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f5effe',
    paddingTop: '90px',
    paddingBottom: '90px',
  }

  return (
    <>
      {!submitted && (
        <form
          onSubmit={(event) => {
            event.preventDefault()
            event.stopPropagation()
            const payload = {
              lead: true,
              leadSource: 'joinMishe',
              practiceName,
              individualProviderName,
              practiceAddress: {
                street: practiceAddress,
                apt: practiceAddress2,
                city: practiceCity,
                state: practiceState,
                zip: practiceZip,
              },
              practicePhone,
              administratorName,
              administratorTitle,
              administratorPhone,
              administratorEmail,
              administratorFax,
              groupNPI,
              mainPracticeTaxID,
              singleProvider,
              isMultiProvider,
              healthSystems,
              hospitalNames,
              mainReason,
              hearAboutMishe,
              createdAt: serverTimestamp(),
            }

            sendProviderEnrollmentNotification({
              providerName: individualProviderName,
              clinicName: practiceName,
            })

            collection('adminClinics')
              .add(payload)
              .then(() => {
                if (window.Intercom) {
                  const { practiceAddress: address, ...rest } = payload
                  window.Intercom('trackEvent', 'Enrollment Form Submitted', {
                    ...rest,
                    createdAt: new Date().toLocaleString(),
                    practiceAddress: JSON.stringify(address),
                  })
                }
                setSubmitted(true)
              })
              .catch((err) => {
                console.error('Error writing document: ', err)
                window.Intercom('trackEvent', 'Enrollment Form Error', {
                  error: err,
                })
                setError(true)
                setSubmitted(true)
              })
          }}
        >
          <Box
            style={{ ...boxStyle }}
            sx={{
              textAlign: {
                xs: 'center',
                sm: 'center',
                md: 'auto',
                lg: 'auto',
              },
              padding: {
                xs: '8px',
                md: '0px',
              },
            }}
          >
            <Box
              bgcolor='#FFF'
              borderRadius='8px'
              boxShadow='0px 4px 4px rgba(0, 0, 0, 0.25)'
              maxWidth='800px'
              sx={{
                padding: {
                  xs: '0px',
                  md: '32px',
                },
              }}
            >
              <Box
                display='flex'
                flexDirection='column'
                alignItems='center'
                justifyContent='center'
                fullWidth
                padding='16px 32px'
              >
                <Box margin='16px'>
                  <Logo size='large' />
                </Box>
                <Typography
                  variant='h1'
                  color='primary'
                  family='poppins'
                  weight='light'
                  align='inherit'
                >
                  Join the Mishe Network
                </Typography>
                <Typography variant='body1' family='poppins' gutterBottom align='inherit'>
                  Thanks for your interest in joining Mishe&apos;s direct care network.
                </Typography>
                <Typography variant='body1' family='poppins' gutterBottom align='inherit'>
                  We look forward to working you!
                </Typography>
                <Box
                  width='100%'
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                  marginTop='32px'
                  marginBottom='16px'
                  sx={{
                    padding: {
                      xs: '0px',
                      md: '16px',
                    },
                  }}
                >
                  <Box
                    style={{
                      position: 'relative',
                      width: '100%',
                      height: 0,
                      paddingTop: '129.4118%',
                      paddingBottom: 0,
                      boxShadow: '0 2px 8px 0 rgba(63,69,81,0.16)',
                      overflow: 'hidden',
                      borderRadius: '8px',
                      willChange: 'transform',
                    }}
                  >
                    <iframe
                      loading='lazy'
                      style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        top: 0,
                        left: 0,
                        border: 'none',
                        padding: 0,
                        margin: 0,
                      }}
                      src='https://www.canva.com/design/DAGcvo-qU14/AXIIQs22he-vlvDFQq4eMQ/view?embed'
                      allowFullScreen
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  padding: {
                    xs: '16px',
                    md: '0px 16px',
                  },
                }}
              >
                <Typography variant='h5' family='poppins' gutterBottom align='inherit'>
                  Please submit the form below to begin network enrollment. Provider Relations will
                  share a network agreement for review and execution.
                </Typography>
              </Box>
              <Box
                sx={{
                  padding: {
                    xs: '16px',
                    md: '0px',
                  },
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      id='practice-name'
                      label='Organization Name'
                      variant='outlined'
                      display='standard'
                      required
                      fullWidth
                      value={practiceName}
                      onChange={(e) => setPracticeName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      id='individual-provider-name'
                      label='Individual Provider Name'
                      variant='outlined'
                      display='standard'
                      fullWidth
                      value={individualProviderName}
                      onChange={(e) => setIndividualProviderName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={8}>
                    <TextField
                      id='practice-address'
                      label='Street'
                      variant='outlined'
                      display='standard'
                      fullWidth
                      value={practiceAddress}
                      onChange={(e) => setPracticeAddress(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <TextField
                      id='practice-address-2'
                      label='Apt / Unit'
                      variant='outlined'
                      display='standard'
                      fullWidth
                      value={practiceAddress2}
                      onChange={(e) => setPracticeAddress2(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      id='practice-city'
                      label='City'
                      variant='outlined'
                      display='standard'
                      fullWidth
                      value={practiceCity}
                      onChange={(e) => setPracticeCity(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={2}>
                    <SelectField
                      id='practice-state'
                      label='State'
                      variant='outlined'
                      display='standard'
                      options={STATE_OPTIONS}
                      fullWidth
                      value={practiceState || ''}
                      onChange={(e) => setPracticeState(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <TextField
                      id='practice-zip'
                      label='Zip'
                      variant='outlined'
                      display='standard'
                      fullWidth
                      value={practiceZip}
                      onChange={(e) => setPracticeZip(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      id='practice-phone'
                      label='Practice Phone'
                      variant='outlined'
                      display='standard'
                      fullWidth
                      value={practicePhone}
                      onChange={(e) => setPracticePhone(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      id='administrator-name'
                      label='Administrator Name'
                      variant='outlined'
                      display='standard'
                      required
                      fullWidth
                      value={administratorName}
                      onChange={(e) => setAdministratorName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      id='administrator-title'
                      label='Administrator Title'
                      variant='outlined'
                      display='standard'
                      required
                      fullWidth
                      value={administratorTitle}
                      onChange={(e) => setAdministratorTitle(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      id='administrator-phone'
                      label='Administrator Phone'
                      variant='outlined'
                      display='standard'
                      required
                      fullWidth
                      value={administratorPhone}
                      onChange={(e) => setAdministratorPhone(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      id='administrator-email'
                      label='Administrator Email'
                      variant='outlined'
                      display='standard'
                      required
                      fullWidth
                      value={administratorEmail}
                      onChange={(e) => setAdministratorEmail(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      id='administrator-fax'
                      label='Administrator Fax'
                      variant='outlined'
                      display='standard'
                      fullWidth
                      value={administratorFax}
                      onChange={(e) => setAdministratorFax(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      id='group-npi'
                      label='Group NPI'
                      variant='outlined'
                      display='standard'
                      fullWidth
                      value={groupNPI}
                      onChange={(e) => setGroupNPI(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      id='main-practice-tax-id'
                      label='Main Practice Tax ID'
                      variant='outlined'
                      display='standard'
                      required
                      fullWidth
                      value={mainPracticeTaxID}
                      onChange={(e) => setMainPracticeTaxID(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Box display='flex' flexDirection='column'>
                      <Typography variant='h5' align='inherit'>
                        Are you a single provider practice or a multi-provider practice?
                      </Typography>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id='single-provider'
                            color='primary'
                            checked={singleProvider}
                            onChange={(e) => setSingleProvider(e.target.checked)}
                          />
                        }
                        label='Single Provider'
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            id='multi-provider'
                            color='primary'
                            checked={isMultiProvider}
                            onChange={(e) => setIsMultiProvider(e.target.checked)}
                          />
                        }
                        label='Multi-Provider'
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Typography variant='h5' align='inherit'>
                      Please indicate any and all health systems you are a part of, seperated with
                      commas.
                    </Typography>
                    <TextField
                      id='health-systems'
                      label='Health Systems'
                      variant='outlined'
                      display='standard'
                      fullWidth
                      value={healthSystems}
                      onChange={(e) => setHealthSystems(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Typography variant='h5' align='inherit'>
                      Do you have admitting privileges at any hospitals? If so, please indicate
                      which ones.
                    </Typography>
                    <TextField
                      id='hospital-names'
                      label='Hospital Names'
                      variant='outlined'
                      display='standard'
                      fullWidth
                      value={hospitalNames}
                      onChange={(e) => setHospitalNames(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Typography variant='h5' align='inherit'>
                      What&apos;s your main reason for requesting a contractual agreement with
                      Mishe?
                    </Typography>
                    <TextField
                      id='main-reason'
                      label='Main Reason'
                      variant='outlined'
                      display='standard'
                      fullWidth
                      value={mainReason}
                      onChange={(e) => setMainReason(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      id='hear-about-mishe'
                      label='How did you hear about Mishe?'
                      variant='outlined'
                      display='standard'
                      fullWidth
                      value={hearAboutMishe}
                      onChange={(e) => setHearAboutMishe(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Box
                      display='flex'
                      justifyContent='center'
                      alignItems='center'
                      marginTop='32px'
                    >
                      <Button variant='contained' color='primary' type='submit' fullWidth>
                        Apply
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </form>
      )}
      {submitted && !error && (
        <Box style={{ ...boxStyle }}>
          <Box
            style={{
              backgroundColor: '#FFF',
              borderRadius: '8px',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
              maxWidth: '800px',
              padding: '32px',
            }}
          >
            <Box
              display='flex'
              flexDirection='column'
              alignItems='center'
              justifyContent='center'
              fullWidth
              marginBottom='32px'
            >
              <Box margin='16px'>
                <Logo size='large' />
              </Box>
              <Typography
                variant='h1'
                color='primary'
                family='poppins'
                weight='light'
                align='inherit'
                style={{
                  fontSize: '24px',
                  marginBottom: '16px',
                }}
              >
                Thank you for your submission!
              </Typography>
              <Typography
                variant='body1'
                family='poppins'
                weight='light'
                align='inherit'
                style={{
                  fontSize: '16px',
                  marginBottom: '32px',
                }}
              >
                We will be in touch shortly.
              </Typography>
              {/* Go to landing page */}
              <Button
                variant='contained'
                color='primary'
                type='submit'
                fullWidth
                onClick={() => {
                  window.location.href = '/'
                }}
              >
                Go to Home
              </Button>
            </Box>
          </Box>
        </Box>
      )}
      {submitted && error && (
        <Box style={{ ...boxStyle }}>
          <Box
            style={{
              backgroundColor: '#FFF',
              borderRadius: '8px',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
              maxWidth: '800px',
              padding: '32px',
            }}
          >
            <Box
              display='flex'
              flexDirection='column'
              alignItems='center'
              justifyContent='center'
              fullWidth
              marginBottom='32px'
            >
              <Box margin='16px'>
                <Logo size='large' />
              </Box>
              <Typography
                variant='h1'
                family='poppins'
                weight='light'
                style={{
                  fontSize: '24px',
                  marginBottom: '16px',
                }}
              >
                We apologize, there was an error submitting your request. Please try again later.
              </Typography>
              <Button
                variant='contained'
                color='primary'
                type='submit'
                fullWidth
                onClick={() => {
                  window.location.reload()
                }}
              >
                Refresh
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </>
  )
}

export default JoinMishe
