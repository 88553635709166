import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import PlanMedicationLander from '@app/components/PlanMedicationLander'
import LiveChat from '../stories/LiveChat'
import MapEffectsProvider from '@app/context/MapEffects'
import { collection } from '../firebase/firestore'

const PlanMedicationLanderPage = () => {
  const { misheCode } = useParams()

  const [medicationData, setMedicationData] = useState(null)

  // START - Fetch page contents
  useEffect(() => {
    const getMedicationData = async () => {
      if (!misheCode) {
        return undefined
      }
      const meds = await collection('medications').where('misheCode', '==', misheCode).get()
      if (meds.empty) {
        console.log('No matching meds.')
        return undefined
      }
      const medsData = meds.docs.map((doc) => {
        return { ...doc.data(), id: doc.id }
      })
      setMedicationData(medsData)
    }
    getMedicationData()
  }, [misheCode])

  return (
    <>
      <MapEffectsProvider>
        <PlanMedicationLander misheCode={misheCode} medicationData={medicationData} />
      </MapEffectsProvider>
      <LiveChat sectionId='concierge' />
    </>
  )
}

export default PlanMedicationLanderPage
