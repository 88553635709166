import Box from '@mui/material/Box'
import { useState, useEffect } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import PaymentMethod from '../EFTPaymentMethod/PaymentMethod'
import { createCustomerAttachPayment } from '@app/firebase/functions'
import { useCurrentProfile } from '@app/context/CurrentUser'
import { collection } from '@app/firebase/firestore'
import { CircularProgress } from '@mui/material'

const VITE_STRIPE_KEY = import.meta.env.VITE_STRIPE_KEY
const stripePromise = loadStripe(VITE_STRIPE_KEY)

const EFTEnrollment = () => {
  const [clientSecret, setClientSecret] = useState(null)
  const { email, id: planAdminUID } = useCurrentProfile()
  const [pageDataLoading, setPageDataLoading] = useState(false)
  const [existingCustomerId, setExistingCustomerId] = useState(null)
  const [existingCustomerDetails, setExistingCustomerDetails] = useState(null)
  const [existingSetupIntentInfo, setExistingSetupIntentInfo] = useState(null)

  useEffect(() => {
    setPageDataLoading(true)
    if (planAdminUID) {
      getPlanAdminData()
    }
  }, [planAdminUID, email])

  const getPlanAdminData = async () => {
    collection('planAdmins')
      .where('adminIDs', 'array-contains', planAdminUID)
      .limit(1)
      .onSnapshot((doc) => {
        doc.forEach(async (doc) => {
          if (!doc.exists) {
            setPageDataLoading(false)
            console.error('** No planAdmin document!')
            return null
          }
          const { stripe } = doc.data()
          const fetchExistingCustomerId = stripe?.customerId || null
          setExistingCustomerId(fetchExistingCustomerId)
          setExistingCustomerDetails({ email: stripe?.email || '', name: stripe?.name || '' })
          setExistingSetupIntentInfo(stripe?.setupIntentInfo || null)
          return await createCustomerAttachPayment({
            email,
            customerId: fetchExistingCustomerId,
          })
            .then(async (data) => {
              setClientSecret(data.clientSecret)
              setPageDataLoading(false)
            })
            .catch((err) => {
              setPageDataLoading(false)
              console.log('err', err)
            })
        })
      })
  }

  const appearance = {
    theme: 'stripe',
    // theme: 'flat',
    // variables: { colorPrimaryText: '#652d92', colorSecondaryText: '#652d92' },
  }
  const loader = 'auto'

  console.log('existingSetupIntentInfo', existingSetupIntentInfo)

  return (
    <>
      {clientSecret && (
        <Elements
          options={{
            clientSecret,
            appearance,
            loader,
          }}
          stripe={stripePromise}
        >
          <PaymentMethod
            existingSetupIntentInfo={existingSetupIntentInfo}
            existingCustomerId={existingCustomerId}
            setExistingSetupIntentInfo={setExistingSetupIntentInfo}
            // clientSecret={clientSecret}
            // stripePromise={stripePromise}
            existingCustomerDetails={existingCustomerDetails}
          />
        </Elements>
      )}
      {pageDataLoading && (
        <Box display='flex' justifyContent='center' alignItems='center' minHeight='200px'>
          <CircularProgress />
        </Box>
      )}
    </>
  )
}

export default EFTEnrollment
