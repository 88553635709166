import PropTypes from 'prop-types'
import { Button, Typography } from '@mui/material'
import VoucherDetailsEntry from '@app/containers/VoucherDetailsEntry'
import IconButton from '@app/stories/IconButton'
import Icon from '@app/stories/Icons'
import RedeemVoucherButton from '@app/containers/RedeemVoucherButton'
import VoucherChip from '@app/components/VoucherChip'
import { formatDateForUI, formatDateForDatePickerUI } from '@app/libs/formatters'
import AddToCalender from '@app/components/AddToCalender'
import Link from '@app/stories/Link'
import { checkIfDate } from '@app/libs/validators'
import DateTimePickerCell from '@app/components/DateTimePickerCell'
import CopyVoucher from '@app/components/CopyVoucher'
import { getVoucherLink } from '@app/utils/getVoucherLink'
import React from 'react'

class TableItemRenderer extends React.Component {
  IconOpenClose = ({ isActive, ctaActions }) => (
    <IconButton
      aria-label={isActive ? 'close row' : 'expand row'}
      size='small'
      onClick={isActive ? ctaActions.deactivate : ctaActions.activate}
    >
      <Icon name={isActive ? 'closeArrow' : 'openArrow'} />
    </IconButton>
  )

  CopyLink = ({ voucher }) => {
    return <CopyVoucher voucher={voucher} label='' />
  }

  MemberInfo = ({ voucher }) => {
    const { customer } = voucher
    return (
      <>
        <Typography variant='body2'>
          {[customer?.firstname, customer?.lastname].join(' ').trim() || '---'}
        </Typography>
        <Typography variant='body2' color='textSecondary'>
          {customer?.email || '---'}
        </Typography>
      </>
    )
  }

  Text = ({ id, voucher }) => {
    return `${voucher[id]?.name || voucher[id]} ${voucher.bundle ? '(bundle)' : ''}`
  }

  DatePicker = ({ voucher }) => {
    return <DateTimePickerCell voucher={voucher} />
  }

  Detail = ({ voucher }) => {
    return (
      <VoucherDetailsEntry voucher={voucher}>
        <Button size='small' variant='outlined'>
          <Typography variant='body1' family='poppins'>
            Details
          </Typography>
        </Button>
      </VoucherDetailsEntry>
    )
  }

  StatusChip = ({ voucher }) => <VoucherChip voucher={voucher} />

  Link = ({ id, voucher }) =>
    voucher[id] ? (
      <Link
        to={voucher[id]?.slug ? `/clinics/${voucher[id]?.slug}` : ''}
        display='inline'
        variant='unstyled'
        size='small'
      >
        {voucher[id]?.name}
      </Link>
    ) : null

  Calendar = ({ voucher }) =>
    checkIfDate(voucher.appointmentDate) && (
      <AddToCalender
        buttonLabel={voucher.appointmentDate.toDate().toLocaleString()}
        calenderEvent={{
          title: voucher.service?.name,
          description: `Voucher Link: ${getVoucherLink(voucher)}`,
          start: formatDateForDatePickerUI(voucher?.appointmentDate)?.toISOString(),
          appointmentDate: voucher?.appointmentDate,
          duration: [1, 'hour'],
        }}
      />
    )

  Date = ({ id, voucher }) => formatDateForUI(voucher[id], true) || '---'

  RedeemAction = ({ voucher }) => {
    if (voucher.status === 'purchased') {
      if (!voucher.bundle) {
        return <RedeemVoucherButton voucher={voucher} />
      }
    }
    return null
  }

  render() {
    const { type, params } = this.props

    // Dynamically call the appropriate rendering function
    if (this[type]) {
      return this[type](params)
    }

    return null
  }
}

TableItemRenderer.propTypes = {
  type: PropTypes.string.isRequired, // Type of element to render (e.g., 'IconOpenClose', 'CopyLink')
  params: PropTypes.object.isRequired, // Parameters passed to the rendering function
}

export default TableItemRenderer
