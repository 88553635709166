const scrollWithOffset = (el) => {
  // copied from https://github.com/rafrex/react-router-hash-link/issues/25
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset
  window.scrollTo({ top: yCoordinate - 111, behavior: 'smooth' })
}

export const scrollToTop = (options) => {
  if (options === null) {
    window.scrollTo(0, 0)
  } else {
    window.scrollTo({ top: 0, behavior: 'smooth', ...options })
  }
}

export const scrollToHash = (hash) => {
  if (hash) {
    const el = document.getElementById(hash.slice(1))
    scrollWithOffset(el)
  } else {
    scrollToTop()
  }
}
