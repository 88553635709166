import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import MuiTypography from '@mui/material/Typography'

const StyledTypography = styled(MuiTypography)`
  ${(props) =>
    props.weight === 'bold' &&
    css`
      font-weight: 500;
    `}
  ${(props) =>
    props.transform === 'uppercase' &&
    css`
      text-transform: uppercase;
    `}
  ${(props) =>
    props.display === 'block' &&
    css`
      margin-bottom: ${props.theme.spacing(2)};
    `}
`

const Text = ({ children, weight = '', transform = '', display = 'block', ...props }) => (
  <StyledTypography
    variant='body1'
    weight={weight}
    transform={transform}
    component='p'
    display={display}
    {...props}
  >
    {children}
  </StyledTypography>
)

Text.propTypes = {
  children: PropTypes.node.isRequired,
  weight: PropTypes.string,
  transform: PropTypes.string,
  display: PropTypes.string,
}

export default Text
