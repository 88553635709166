import PropTypes from 'prop-types'
import Avatar from '../stories/Avatar'

const OrganizationAvatar = ({ alt, size = 'small', ...props }) => {
  return (
    <Avatar size={size} coloring='negative' position='left' src='' alt={alt} {...props}>
      {alt
        ? alt
            .split(' ')
            .map((item) => item[0])
            .join('')
        : ''}
    </Avatar>
  )
}

OrganizationAvatar.propTypes = {
  alt: PropTypes.string.isRequired,
  size: PropTypes.string,
}

export default OrganizationAvatar
