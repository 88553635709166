import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Select, MenuItem, InputLabel, FormControl } from '@mui/material'

const acceptedValues = [
  '',
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
]

const StateSelectInput = ({ id = null, state, onChange, defaultValue = '', ...props }) => {
  const [stateValue, setStateValue] = useState('')

  useEffect(() => {
    const formattedState = (state || defaultValue).toUpperCase()
    if (acceptedValues.includes(formattedState)) {
      setStateValue(formattedState)
    }
  }, [state, defaultValue])

  const handleChange = (value) => {
    setStateValue(value)
    onChange(value)
  }

  return (
    <FormControl fullWidth>
      <InputLabel id='select-label'>State</InputLabel>
      <Select
        labelId='select-label'
        label='State'
        id={id || 'state'}
        name={id || 'state'}
        value={stateValue}
        onChange={(e) => handleChange(e.target.value)}
        style={{ width: '100%' }}
        {...props}
      >
        <MenuItem value=''>State</MenuItem>
        <MenuItem value='AL'>Alabama</MenuItem>
        <MenuItem value='AK'>Alaska</MenuItem>
        <MenuItem value='AZ'>Arizona</MenuItem>
        <MenuItem value='AR'>Arkansas</MenuItem>
        <MenuItem value='CA'>California</MenuItem>
        <MenuItem value='CO'>Colorado</MenuItem>
        <MenuItem value='CT'>Connecticut</MenuItem>
        <MenuItem value='DE'>Delaware</MenuItem>
        <MenuItem value='DC'>District Of Columbia</MenuItem>
        <MenuItem value='FL'>Florida</MenuItem>
        <MenuItem value='GA'>Georgia</MenuItem>
        <MenuItem value='HI'>Hawaii</MenuItem>
        <MenuItem value='ID'>Idaho</MenuItem>
        <MenuItem value='IL'>Illinois</MenuItem>
        <MenuItem value='IN'>Indiana</MenuItem>
        <MenuItem value='IA'>Iowa</MenuItem>
        <MenuItem value='KS'>Kansas</MenuItem>
        <MenuItem value='KY'>Kentucky</MenuItem>
        <MenuItem value='LA'>Louisiana</MenuItem>
        <MenuItem value='ME'>Maine</MenuItem>
        <MenuItem value='MD'>Maryland</MenuItem>
        <MenuItem value='MA'>Massachusetts</MenuItem>
        <MenuItem value='MI'>Michigan</MenuItem>
        <MenuItem value='MN'>Minnesota</MenuItem>
        <MenuItem value='MS'>Mississippi</MenuItem>
        <MenuItem value='MO'>Missouri</MenuItem>
        <MenuItem value='MT'>Montana</MenuItem>
        <MenuItem value='NE'>Nebraska</MenuItem>
        <MenuItem value='NV'>Nevada</MenuItem>
        <MenuItem value='NH'>New Hampshire</MenuItem>
        <MenuItem value='NJ'>New Jersey</MenuItem>
        <MenuItem value='NM'>New Mexico</MenuItem>
        <MenuItem value='NY'>New York</MenuItem>
        <MenuItem value='NC'>North Carolina</MenuItem>
        <MenuItem value='ND'>North Dakota</MenuItem>
        <MenuItem value='OH'>Ohio</MenuItem>
        <MenuItem value='OK'>Oklahoma</MenuItem>
        <MenuItem value='OR'>Oregon</MenuItem>
        <MenuItem value='PA'>Pennsylvania</MenuItem>
        <MenuItem value='RI'>Rhode Island</MenuItem>
        <MenuItem value='SC'>South Carolina</MenuItem>
        <MenuItem value='SD'>South Dakota</MenuItem>
        <MenuItem value='TN'>Tennessee</MenuItem>
        <MenuItem value='TX'>Texas</MenuItem>
        <MenuItem value='UT'>Utah</MenuItem>
        <MenuItem value='VT'>Vermont</MenuItem>
        <MenuItem value='VA'>Virginia</MenuItem>
        <MenuItem value='WA'>Washington</MenuItem>
        <MenuItem value='WV'>West Virginia</MenuItem>
        <MenuItem value='WI'>Wisconsin</MenuItem>
        <MenuItem value='WY'>Wyoming</MenuItem>
      </Select>
    </FormControl>
  )
}

StateSelectInput.propTypes = {
  state: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  defaultValue: PropTypes.string,
}

export default StateSelectInput
