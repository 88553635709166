import { useCallback, useEffect, useRef, useState } from 'react'
// temporary, will be removed when we add memberSource to plans
// adding ?url instructs vite not the build with the asset loaded as a datauri
import frontCard from '@app/assets/images/memberCards/DEFAULT_member_card.svg?no-inline'
import IconButton from '@mui/material/IconButton'
import DownloadIcon from '@mui/icons-material/Download'
import { Box } from '@mui/material'
import { drawCard } from '@app/utils/planUtil'
import { retrieveFiretableImageURL } from '@app/libs/formatters'
import CircularProgress from '@mui/material/CircularProgress'

const width = 1024
const height = 638

const PatientMemberCardRenderer = ({
  firstName,
  lastName,
  memberNumber,
  groupNumber,
  pcpCopay,
  specialistCopay,
  emergencyRoomCopay,
  memberServiceNumber,
  planType,
  effectiveDate,
  planID,
  prescriptionGroup,
  prescriptionCopay,
  cardSource,
}) => {
  const canvasRef = useRef(null)

  const [hover, setHover] = useState(false)
  const [cardBackground, setCardBackground] = useState(null)
  const [imageLoaded, setImageLoaded] = useState(false)

  const fetchImage = useCallback(async () => {
    // remove frontCard is the goal here, by adding all card sources or ids to the db
    const cardImage =
      cardSource && cardSource.length ? retrieveFiretableImageURL(cardSource) : frontCard

    setCardBackground(cardImage)
  }, [cardSource])

  useEffect(() => {
    fetchImage()
  }, [cardSource])

  useEffect(() => {
    if (!cardBackground) return

    const img = new Image()
    img.crossOrigin = 'anonymous' // Set crossOrigin attribute
    img.onload = () => {
      setImageLoaded(true)
    }
    img.src = cardBackground
  }, [cardBackground])

  useEffect(() => {
    if (!imageLoaded) return

    const canvas = canvasRef.current
    const ctx = canvas.getContext('2d')

    const img = new Image()
    img.crossOrigin = 'anonymous' // Set crossOrigin attribute
    img.onload = () => {
      ctx.drawImage(img, 0, 0, width, height)
      drawCard(ctx, {
        firstName,
        lastName,
        memberNumber,
        groupNumber,
        pcpCopay,
        specialistCopay,
        emergencyRoomCopay,
        memberServiceNumber,
        planType,
        effectiveDate,
        prescriptionGroup,
        prescriptionCopay,
        width,
        height,
        planID,
        cardImageName: cardSource && cardSource[0] ? cardSource[0].name : null,
      })
    }
    img.src = cardBackground
  }, [
    imageLoaded,
    firstName,
    lastName,
    memberNumber,
    groupNumber,
    pcpCopay,
    specialistCopay,
    emergencyRoomCopay,
    memberServiceNumber,
    planType,
    effectiveDate,
    prescriptionGroup,
    prescriptionCopay,
    planID,
  ])

  const handleDownload = async () => {
    const canvas = canvasRef.current

    if (!canvas) {
      console.error('Canvas reference is not available.')
      return
    }

    try {
      const dataUrl = canvas.toDataURL('image/png')

      // Create a download link for the generated image
      const link = document.createElement('a')
      const uniqueId = Date.now().toString(36).slice(-5)

      link.href = dataUrl
      link.download = `${firstName}_${lastName}_MemberCard${uniqueId}.png`

      document.body.appendChild(link) // Append link to the DOM for Safari compatibility
      link.click() // Trigger the download
      document.body.removeChild(link) // Remove the link from the DOM
    } catch (error) {
      console.error('Error generating or downloading the image:', error)
    }
  }

  return (
    <Box
      position='relative'
      display='inline-block'
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {!imageLoaded && (
        <Box position='absolute' top='50%' left='50%' transform='translate(-50%, -50%)' zIndex={1}>
          <CircularProgress />
        </Box>
      )}
      <canvas
        ref={canvasRef}
        width={width}
        height={height}
        style={{ width: '100%', height: 'auto', display: 'block' }}
      />
      {hover && imageLoaded && (
        <Box
          position='absolute'
          top={0}
          left={0}
          width='100%'
          height='100%'
          display='flex'
          alignItems='center'
          justifyContent='center'
          bgcolor='rgba(0, 0, 0, 0.3)'
        >
          <IconButton onClick={handleDownload} color='primary' size='large'>
            <DownloadIcon size='large' fontSize='inherit' />
          </IconButton>
        </Box>
      )}
    </Box>
  )
}

export default PatientMemberCardRenderer
