import { useState } from 'react'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Icon from '../stories/Icons'
import { useOrderModal } from '@app/context/AddOrderModal'

const VoucherNewOrderDropdown = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const { setOrderType, handleClick } = useOrderModal()

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const orderOptions = {
    medication: 'medication',
    referral: 'referral',
  }

  const handleClose = (e) => {
    if (orderOptions[e.target.id] !== undefined) {
      setOrderType(e.target.id)
      handleClick(e)
    }
    setAnchorEl(null)
  }

  return (
    <div>
      <Button
        aria-controls='add-note-menu'
        aria-haspopup='true'
        onClick={handleMenuClick}
        style={{
          width: '100%',
        }}
      >
        <Icon name='add' fontSize='small' color='purple' />
        Add Order
      </Button>
      <Menu
        id='add-note-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem id='medication' onClick={handleClose}>
          Medication
        </MenuItem>
        <MenuItem id='referral' onClick={handleClose}>
          Referral
        </MenuItem>
      </Menu>
    </div>
  )
}

export default VoucherNewOrderDropdown
