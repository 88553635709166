import { useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Button from '../stories/Button'
import StepperBubbleComponent from '@app/components/StepperBubbleComponent'

// Children MUST contain keys as a prop!

const StepperContainer = ({
  bubbleInfo = null,
  disableSubmit = false,
  stepControlDetails = { show: false, handleFinished: null },
  children,
}) => {
  const [activeStep, setActiveStep] = useState(0)

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const StepperComponents = (components) => {
    if (!components) {
      console.log('No components provided to StepperContainer')
      return null
    }

    if (!components?.length) {
      console.log('Only one component provided to StepperContainer')
      return <>{components}</>
    }

    return components.map((component, index) => {
      return <Fragment key={component.key || index}>{activeStep === index && component}</Fragment>
    })
  }

  return (
    <>
      <Box padding='0px 16px'>
        <StepperBubbleComponent
          bubbles={bubbleInfo || (children?.length ? [...children] : [children])}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        />
      </Box>
      {StepperComponents(children)}
      {stepControlDetails.show && (
        <Box
          width='100%'
          display='flex'
          justifyContent='space-around'
          padding='16px'
          alignItems='center'
          mt={2}
        >
          <Button
            variant='outlined'
            onClick={handleBack}
            disabled={activeStep === 0}
            style={{
              borderRadius: '28px',
            }}
          >
            Back
          </Button>
          {!children?.length ||
            (activeStep !== children.length - 1 && (
              <Button
                variant='outlined'
                onClick={handleNext}
                disabled={!children?.length || activeStep === children.length - 1}
                style={{
                  borderRadius: '28px',
                }}
              >
                Next
              </Button>
            ))}
          {(!children?.length || activeStep === children.length - 1) && (
            <Button
              onClick={stepControlDetails?.handleFinished}
              disabled={disableSubmit}
              style={{
                borderRadius: '28px',
              }}
            >
              Finish
            </Button>
          )}
        </Box>
      )}
    </>
  )
}

StepperContainer.propTypes = {
  bubbleInfo: PropTypes.array,
  disableSubmit: PropTypes.bool,
  stepControlDetails: PropTypes.shape({
    show: PropTypes.bool,
    handleFinished: PropTypes.func,
    handleClose: PropTypes.func,
  }),
  children: PropTypes.array.isRequired,
}

export default StepperContainer
