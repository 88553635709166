import { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Fade from '@mui/material/Fade'
import Typography from '../stories/Typography'

const WordShifterFade = ({ inputText }) => {
  const [fadeIn, setFadeIn] = useState(true)
  const [textShift, setTextShift] = useState(0)
  const timeoutRef = useRef(null)

  useEffect(() => {
    // Start the timer
    timeoutRef.current = setInterval(() => {
      setFadeIn(false)
      setTimeout(() => {
        setTextShift((prevShift) => (prevShift + 1) % inputText.length)
        setFadeIn(true)
      }, 2000)
    }, 4000)

    // Clean up the timer when the component unmounts
    return () => {
      clearInterval(timeoutRef.current)
    }
  }, [inputText.length])

  return (
    <Fade in={fadeIn} timeout={{ enter: 2000, exit: 2000 }}>
      <Box padding='0 2rem' width='100%'>
        <Typography
          variant='h2'
          family='poppins'
          weight='light'
          align='center'
          style={{
            color: '#fff',
            margin: '0',
          }}
        >
          {inputText[textShift]}
        </Typography>
      </Box>
    </Fade>
  )
}

WordShifterFade.propTypes = {
  inputText: PropTypes.array.isRequired,
}

export default WordShifterFade
