import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import MuiTypography from '@mui/material/Typography'

const StyledTypography = styled(MuiTypography)`
  text-transform: uppercase;
  ${(props) =>
    props.decoration === 'underline' &&
    css`
      text-decoration: underline;
    `}
`

const CapsLock = ({ children, decoration = '', ...props }) => (
  <StyledTypography decoration={decoration} component='span' {...props}>
    {children}
  </StyledTypography>
)

CapsLock.propTypes = {
  children: PropTypes.node.isRequired,
  decoration: PropTypes.string,
}

export default CapsLock
