import { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { InstantSearch, useConfigure, SortBy, useConnector } from 'react-instantsearch'

import HitMap from '@app/components/MarkerMap/HitMap'
import CustomSearchBox from '@app/components/MarkerMap/CustomSearchBox'
import CustomRefinementList from '@app/components/MarkerMap/CustomRefinementList'
import CustomRefinementToggle from '@app/components/MarkerMap/CustomRefinementToggle'
import CustomHits from '@app/components/MarkerMap/CustomHits'
import CustomPagination from '@app/components/MarkerMap/CustomPagination'
import connectHits from 'instantsearch.js/es/connectors/hits/connectHits'
import Box from '@mui/material/Box'
import { Collapse, Divider } from '@mui/material'
import Typography from '@app/stories/Typography'
import TextField from '@app/stories/TextField'
import searchClient from '@app/libs/algolia'
import { getCoordsFromAddress } from '@app/libs/geo'
import Grid from '@mui/material/Grid'
import CollapsableListItem from '@app/components/CollapsableListItem'
import MapEffectsProvider from '@app/context/MapEffects'
import SelectField from '@app/stories/Select'
import { useWhiteLabelingContext } from '@app/context/WhiteLabelingContext'

// Custom debounce function
const debounce = (func, delay) => {
  let timeoutId
  return (...args) => {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    timeoutId = setTimeout(() => {
      func(...args)
    }, delay)
  }
}

const CustomConfigure = ({ configuration }) => {
  const { refine } = useConfigure(configuration)

  useEffect(() => {
    refine(configuration)
  }, [configuration, refine])

  return null
}

CustomConfigure.propTypes = {
  configuration: PropTypes.shape({
    hitsPerPage: PropTypes.number,
  }).isRequired,
}

const ExportHits = ({ exportFunction }) => {
  const { hits } = useConnector(connectHits)

  useEffect(() => {
    if (exportFunction) {
      exportFunction(hits)
    }
  }, [hits])

  return <></>
}

ExportHits.propTypes = {
  exportFunction: PropTypes.func.isRequired,
}

const InstantSearchContainerCustom = ({
  HitContainer = null,
  HitComponent,
  variant = '',
  hitClick = null,
  indexName,
  configuration = { hitsPerPage: 5 },
  searchBoxText,
  noResultsMessage,
  showInitialHits = false,
  usePagination = false,
  clearSearchButton = false,
  backgroundCollapse = false,
  exportFunction = null,
  showZipcode = false,
  showZipcodeMobile,
  smallZipcode = false,
  geoSearch = false,
  address = '',
  customZIndex = 2000,
  homePageSearch,
  networkLanderSearch,
  noResultsColor = '#fff',
  setSearchLimit,
  searchLimit,
  sortByItems,
  preventBooking,
  noResultsLink,
  showServiceSearchResponsibilityBreakdown = false,
}) => {
  const [displayHits, setDisplayHits] = useState(showInitialHits)
  const [zipcode, setZipcode] = useState('')
  const [coordinates, setCoordinates] = useState(null)
  const [config, setConfig] = useState({
    analytics: false,
    getRankingInfo: true,
    ...configuration,
  })

  const { planColorPrimary } = useWhiteLabelingContext()

  const [currentIndex, setCurrentIndex] = useState('')

  const onChangeCollapse = (e) => {
    setCurrentIndex(e)
  }

  const debouncedFetchCoordinates = useCallback(
    debounce(async (zipcode, address) => {
      if (!zipcode) {
        setCoordinates(null)
        return
      }

      const cleanedZipcode = zipcode
        ?.toString()
        ?.replace(/[^0-9]/g, '')
        ?.trim()
      const coords = await getCoordsFromAddress(cleanedZipcode || address)

      if (coords) {
        setCoordinates(coords)
      }
    }, 500),
    [],
  )

  useEffect(() => {
    debouncedFetchCoordinates(zipcode, address)
  }, [zipcode, address, debouncedFetchCoordinates])

  useEffect(() => {
    const configBuilder = {
      analytics: false,
      getRankingInfo: true,
      ...configuration,
    }

    if (coordinates) {
      configBuilder.aroundLatLng = `${coordinates.lat}, ${coordinates.lng}`
      configBuilder.aroundRadius = 'all'
    }

    if (geoSearch && !coordinates) {
      configBuilder.aroundLatLngViaIP = true
      configBuilder.aroundRadius = 'all'
    }

    setConfig(configBuilder)
  }, [coordinates, geoSearch])

  const searchOnMap = (searchMapCordinates) => {
    if (searchMapCordinates) {
      const configBuilder = {
        analytics: false,
        getRankingInfo: true,
        ...configuration,
      }

      // console.log('searchMapCordinates----', searchMapCordinates)
      configBuilder.aroundLatLng = `${searchMapCordinates.lat}, ${searchMapCordinates.lng}`
      configBuilder.aroundRadius = 'all'

      // console.log('configBuilder--searchOnMap------', configBuilder)
      setConfig(configBuilder)
    }
  }

  if (!config) {
    return null
  }

  return (
    <MapEffectsProvider>
      <InstantSearch
        style={{
          width: '100%',
        }}
        searchClient={searchClient}
        indexName={indexName}
        future={{
          preserveSharedStateOnUnmount: true,
        }}
        initialUiState={{
          [indexName]: {
            query: '',
          },
        }}
      >
        <CustomConfigure configuration={config} />
        <Box
          display='flex'
          sx={{
            flexDirection: {
              xs: 'column',
              sm: 'column',
              md: 'row',
              lg: 'row',
            },
          }}
        >
          {networkLanderSearch && (
            <Box
              sx={{
                width: {
                  xs: '100%',
                  md: '20%',
                },
              }}
            >
              <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <Grid item xs={12} sm={12}>
                  <CollapsableListItem
                    key='filter_collapsable'
                    value='1'
                    icon='filter'
                    currentIndex={currentIndex}
                    onChangeCollapse={onChangeCollapse}
                    title={<span>Refine your search</span>}
                    fullWidth
                  >
                    <TextField
                      id='zipCode'
                      variant='outlined'
                      sx={{
                        marginTop: '0px',
                        marginBottom: '0px',
                        width: '100%',
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: planColorPrimary,
                          },
                          '&:hover fieldset': {
                            borderColor: planColorPrimary,
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: planColorPrimary,
                          },
                        },
                        '& .MuiInputLabel-root': {
                          color: planColorPrimary,
                        },
                        '& .MuiInputLabel-root.Mui-focused': {
                          color: planColorPrimary,
                        },
                      }}
                      display='standard'
                      autoComplete='off'
                      autoCorrect='off'
                      autoCapitalize='off'
                      spellCheck={false}
                      maxLength={10}
                      type='search'
                      value={zipcode}
                      onChange={(event) => {
                        setZipcode(event.currentTarget.value)
                      }}
                      autoFocus
                      label={smallZipcode ? 'Zip' : 'Zip Code'}
                    />
                    {sortByItems && (
                      <Box>
                        <Typography
                          family='poppins'
                          variant='h5'
                          style={{
                            marginTop: '16px',
                            marginBottom: '0px',
                          }}
                        >
                          Sort By
                        </Typography>
                        <SortBy
                          items={sortByItems}
                          style={{
                            marginTop: '8px',
                            width: '100%',
                          }}
                        />
                        <Divider />
                      </Box>
                    )}
                    <CustomRefinementList
                      attribute='clinicName'
                      customTitle='Clinic Name'
                      searchable={true}
                      limit={5}
                      searchText='Find a clinic'
                    />
                    <CustomRefinementToggle
                      attribute='zeroCostProvider'
                      label='Zero Cost Providers'
                      title='Providers'
                    />
                    <CustomRefinementList
                      attribute='specialty'
                      customTitle='Specialty'
                      searchable={true}
                      limit={5}
                      searchText='Find a specialty'
                    />
                  </CollapsableListItem>
                </Grid>
              </Box>
              <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                <Box mt={2} marginRight={2}>
                  <TextField
                    id='zipCode'
                    variant='outlined'
                    sx={{
                      marginTop: '0px',
                      marginBottom: '0px',
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: planColorPrimary,
                        },
                        '&:hover fieldset': {
                          borderColor: planColorPrimary,
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: planColorPrimary,
                        },
                      },
                      '& .MuiInputLabel-root': {
                        color: planColorPrimary,
                      },
                      '& .MuiInputLabel-root.Mui-focused': {
                        color: planColorPrimary,
                      },
                    }}
                    display='standard'
                    autoComplete='off'
                    autoCorrect='off'
                    autoCapitalize='off'
                    spellCheck={false}
                    maxLength={10}
                    type='search'
                    value={zipcode}
                    onChange={(event) => {
                      setZipcode(event.currentTarget.value)
                    }}
                    autoFocus
                    label={smallZipcode ? 'Zip' : 'Zip Code'}
                  />
                  <Box
                    maxHeight='70vh'
                    overflow='auto'
                    border={`1px solid ${planColorPrimary}`}
                    padding='16px'
                    borderRadius='4px'
                    marginTop='16px'
                  >
                    {sortByItems && (
                      <Box>
                        <Typography family='poppins' variant='h5'>
                          Sort By
                        </Typography>
                        <SortBy
                          items={sortByItems}
                          style={{
                            marginTop: '10px',
                            boxShadow: 'none',
                            marginBottom: '12px',
                          }}
                        />
                        <Divider />
                      </Box>
                    )}
                    <CustomRefinementList
                      attribute='clinicName'
                      customTitle='Clinic Name'
                      searchable={true}
                      limit={5}
                      searchText='Find a clinic'
                    />
                    <CustomRefinementToggle
                      attribute='zeroCostProvider'
                      label='Zero Cost Providers'
                      title='Providers'
                    />
                    <CustomRefinementList
                      attribute='specialty'
                      customTitle='Specialty'
                      searchable={true}
                      limit={5}
                      searchText='Find a specialty'
                    />
                    <Box>
                      <Typography
                        family='poppins'
                        variant='h5'
                        style={{
                          marginTop: '16px',
                          marginBottom: '0px',
                        }}
                      >
                        Limit
                      </Typography>
                      <SelectField
                        label='Limit'
                        value={searchLimit}
                        onChange={(e) => setSearchLimit(e.target.value)}
                        options={[
                          { value: '5', label: '5' },
                          { value: '10', label: '10' },
                          { value: '25', label: '25' },
                          { value: '50', label: '50' },
                        ]}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
          {networkLanderSearch && (
            <Box
              sx={{
                display: {
                  xs: 'block',
                  md: 'none',
                },
              }}
            >
              <HitMap
                noResultsMessage={noResultsMessage}
                noResultsColor={noResultsColor}
                noResultsLink={noResultsLink}
              />
            </Box>
          )}
          <Box
            position='relative'
            height='min-content'
            sx={{
              width: {
                xs: '100%',
                md: networkLanderSearch ? '40%' : '100%',
              },
            }}
          >
            <CustomSearchBox
              searchBoxText={searchBoxText}
              displayHits={setDisplayHits}
              showInitialHits={showInitialHits}
              showZipcode={showZipcode}
              showZipcodeMobile={showZipcodeMobile}
              zipcode={zipcode}
              setZipcode={setZipcode}
              smallZipcode={smallZipcode}
              clearSearchButton={clearSearchButton}
              homePageSearch={homePageSearch}
              searchOnMap={searchOnMap}
            />
            {backgroundCollapse && (
              <Collapse in={displayHits} timeout={1000} unmountOnExit>
                <Box bgcolor='background.smoke' height='550px' width='100%' />
              </Collapse>
            )}
            {displayHits && (
              <>
                {exportFunction && <ExportHits exportFunction={exportFunction} />}
                {!exportFunction && (
                  <Box
                    mb={3}
                    width='100%'
                    display='block'
                    style={{
                      position: 'absolute',
                      height: '42rem',
                      top: backgroundCollapse ? '88px' : 'calc(100% + 1rem)',
                      zIndex: customZIndex || 2000,
                      width: '100%',
                    }}
                  >
                    {usePagination && (
                      <Box
                        mb={1}
                        width='100%'
                        display='flex'
                        flexDirection='column'
                        justifyContent='center'
                        style={{
                          overflow: 'auto',
                        }}
                      >
                        <CustomPagination />
                      </Box>
                    )}
                    <CustomHits
                      HitContainer={HitContainer}
                      HitComponent={HitComponent}
                      hitClick={hitClick}
                      variant={variant}
                      noResultsMessage={noResultsMessage}
                      homePageSearch={homePageSearch}
                      noResultsColor={noResultsColor}
                      preventBooking={preventBooking}
                      noResultsLink={noResultsLink}
                      showServiceSearchResponsibilityBreakdown={
                        showServiceSearchResponsibilityBreakdown
                      }
                    />
                  </Box>
                )}
              </>
            )}
          </Box>
          <Box
            sx={{
              width: {
                xs: '100%',
                md: '40%',
              },
              padding: {
                xs: '0px',
                md: '16px 0px 16px 16px',
              },
              display: {
                xs: 'none',
                md: networkLanderSearch ? 'block' : 'none',
              },
            }}
          >
            {networkLanderSearch && (
              <HitMap
                noResultsMessage={noResultsMessage}
                displayHeight='75vh'
                noResultsColor={noResultsColor}
                coordinates={coordinates}
                noResultsLink={noResultsLink}
              />
            )}
          </Box>
        </Box>
      </InstantSearch>
    </MapEffectsProvider>
  )
}

InstantSearchContainerCustom.propTypes = {
  HitContainer: PropTypes.func,
  HitComponent: PropTypes.func.isRequired,
  hitClick: PropTypes.func,
  indexName: PropTypes.string.isRequired,
  searchBoxText: PropTypes.string.isRequired,
  noResultsMessage: PropTypes.string.isRequired,
  variant: PropTypes.string,
  showInitialHits: PropTypes.bool,
  usePagination: PropTypes.bool,
  showZipcode: PropTypes.bool,
  smallZipcode: PropTypes.bool,
  clearSearchButton: PropTypes.bool,
  backgroundCollapse: PropTypes.bool,
  exportFunction: PropTypes.func,
  geoSearch: PropTypes.bool,
  address: PropTypes.string,
  configuration: PropTypes.shape({
    hitsPerPage: PropTypes.number,
  }),
  customZIndex: PropTypes.number,
  noResultsColor: PropTypes.string,
}

export default InstantSearchContainerCustom
