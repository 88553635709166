import { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { formatDateForUI } from '@app/libs/formatters'
import { CircularProgress } from '@mui/material'
import Link from '@app/stories/Link'

const PlanAdminInvoiceRow = ({ row, fetchEmrOrderById }) => {
  const [rowExpand, setRowExpand] = useState({ open: false, invoiceId: null })
  const [emrOrderDetails, setEmrOrderDetails] = useState([])
  const [rowLoading, setRowLoading] = useState(false)

  useEffect(() => {
    if (rowExpand.open && row.emrOrderId) {
      setRowLoading(true)
      fetchEmrOrderById({ emrOrderId: row.emrOrderId }).then((emrOrderData) => {
        setEmrOrderDetails(emrOrderData)
        setRowLoading(false)
      })
    }
  }, [rowExpand])

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={() => setRowExpand({ open: !rowExpand.open, invoiceId: row.id })}
          >
            {rowExpand.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component='th' scope='row'>
          ${row.amount.toLocaleString()}
        </TableCell>
        <TableCell align='center'>{row?.invoice.id || '---'}</TableCell>
        <TableCell align='center'>
          {
            <div>
              {row?.status || '---'}

              <p>
                {row.status !== 'paid' && (
                  <>
                    <p>Invoice Status: {row?.invoice.status} </p>
                    <p> {row?.failureBalanceTransaction} </p>
                    <p>{row?.failureMessage}</p>
                  </>
                )}{' '}
              </p>
            </div>
          }
        </TableCell>

        <TableCell align='center'>
          {formatDateForUI(row?.invoice?.status_transitions?.paid_at) || '---'}
        </TableCell>
        <TableCell align='center'>
          {formatDateForUI(row?.invoice?.status_transitions?.finalized_at) || '---'}
        </TableCell>
        <TableCell align='center'>
          {row.invoice?.hosted_invoice_url ? (
            <Link to={row.invoice?.hosted_invoice_url} type='internal' target='_blank'>
              {`Invoice Link`}
            </Link>
          ) : (
            '---'
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={rowExpand.open} timeout='auto' unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant='h6' gutterBottom component='div'>
                EmrOrder Details
              </Typography>
              <Table size='small' aria-label='purchases'>
                <TableHead>
                  <TableRow>
                    <TableCell align='left'>EmrOrderId </TableCell>
                    <TableCell align='left'>Plan Name </TableCell>
                    <TableCell align='left'>Service Name</TableCell>
                    <TableCell align='left'>OrderType </TableCell>
                    <TableCell align='left'>Status </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {emrOrderDetails &&
                    emrOrderDetails.map(({ id, details, orderType, status }, index) => (
                      <TableRow key={index}>
                        <TableCell align='left'>{id}</TableCell>
                        <TableCell align='left'>{details?.planName}</TableCell>
                        <TableCell align='left'>{details?.serviceName}</TableCell>
                        <TableCell align='left'>{orderType}</TableCell>
                        <TableCell align='left'>{status}</TableCell>
                      </TableRow>
                    ))}
                  {rowLoading && (
                    <TableRow>
                      <TableCell colSpan={4} align='center'>
                        <CircularProgress size={24} />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export default PlanAdminInvoiceRow
