import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Box from '@mui/material/Box'
import Hero from '@app/components/Hero'
import PageSection from '@app/components/PageSection'
import HomePageLanderHoverInfo from '@app/components/HomePageLanderHoverInfo'
import HomePageLanderHoverInfoMobile from '@app/components/HomePageLanderHoverInfoMobile'
import PhoneOutline from '../assets/images/phoneImages/PhoneOutline.png'
import PhoneOutlineMaps from '../assets/images/phoneImages/PhoneOutlineMaps.png'
import PhoneOutlineServiceSearch from '../assets/images/phoneImages/PhoneOutlineServiceSearch.png'
import PhoneOutlineVoucherMedicationOrder from '../assets/images/phoneImages/PhoneOutlineVoucherMedicationOrder.png'
import Fade from '@mui/material/Fade'
import Hidden from '@mui/material/Hidden'
import Typography from '@mui/material/Typography'
import StoryTypography from '../stories/Typography'
import ComponentShifterFadeControl from '@app/components/ComponentShifterFadeControl'
import ComponentShifterFade from '@app/components/ComponentShifterFade'
import algolia from '../assets/images/partnerLogosOffWhite/algoliaLogoWhite.png'
import applePay from '../assets/images/partnerLogosOffWhite/applePayLogoWhite.png'
import google from '../assets/images/partnerLogosOffWhite/googleLogoWhite.png'
import intercom from '../assets/images/partnerLogosOffWhite/intercomLogoWhite.png'
import klarna from '../assets/images/partnerLogosOffWhite/klarnaLogoWhite.png'
import nih from '../assets/images/partnerLogosOffWhite/nihLogoWhite.png'
import stripe from '../assets/images/partnerLogosOffWhite/stripeLogoWhite.png'
import twilio from '../assets/images/partnerLogosOffWhite/twilioLogoWhite.png'
import houstonMethodist from '../assets/images/physicianPartnerLogos/houstonMethodist.png'
import mountSinai from '../assets/images/physicianPartnerLogos/mountSinai.png'
import newYorkPres from '../assets/images/physicianPartnerLogos/newYorkPres.png'
import nyuLagoon from '../assets/images/physicianPartnerLogos/nyuLagoon.png'
import universityHospitalsCleaveland from '../assets/images/physicianPartnerLogos/universityHospitalsCleaveland.png'
import forbesLogo from '../assets/images/asMentionedInLogos/forbesLogo.png'
import andersonLogo from '../assets/images/asMentionedInLogos/andersonLogo.png'
import medicalEconomicsLogo from '../assets/images/asMentionedInLogos/medicalEconomicsLogo.png'
import fmmaLogoWhite from '../assets/images/industryLogos/fmmaLogoWhite.png'
import dpcLogo from '../assets/images/industryLogos/dpcLogo.png'
import healthRosettaLogo from '../assets/images/industryLogos/healthRosettaLogo.png'
import patientRightsLogo from '../assets/images/industryLogos/patientRightsLogo.png'
import beckersWhiteLogo from '../assets/images/asMentionedInLogos/beckersWhiteLogo.png'
import medicineToMarketLogoWhite from '../assets/images/asMentionedInLogos/medicineToMarketLogoWhite.png'
import InstantSearchContainerCustom from '@app/containers/InstantSearchContainerCustom'
import MedicationSearchCard from '@app/components/MedicationSearchCard'
import ServiceSearchCard from '@app/components/ServiceSearchCard'
import ClinicSearchCard from '@app/components/ClinicSearchCard'
import Button from '../stories/Button'
import FAQSection from '../stories/FAQ'
import LiveChat from '../stories/LiveChat'

const heroTitle = (
  <>
    {/* Bypass insurance. */}
    Ditch the middleman, not your savings.
    <br />
    <Box component='span' color='primary.main'>
      {/* Save up to 80% on treatment. */}
      Up to 80% off healthcare.
    </Box>
  </>
)

const heroSubText = <>Access pre-negotiated discounts from our community of top doctors.</>

const LandingPage = () => {
  const { search } = useLocation()
  const [medicationSelected, setMedicationSelected] = useState(false)
  const [clinicSelected, setClinicSelected] = useState(false)
  const [procedureSelected, setProcedureSelected] = useState(true)

  const cleanUp = () => {
    setMedicationSelected(false)
    setClinicSelected(false)
    setProcedureSelected(false)
  }

  const ref = new URLSearchParams(search).get('ref')
  if (ref) {
    localStorage.setItem('refvalue', ref)
  }

  useEffect(() => {
    window.Intercom('trackEvent', 'Page View', {
      page_name: 'Homepage',
    })
  }, [])

  const phoneImages = [
    PhoneOutline,
    PhoneOutline,
    PhoneOutlineMaps,
    PhoneOutline,
    PhoneOutlineServiceSearch,
    PhoneOutlineVoucherMedicationOrder,
  ]
  const phoneText = [
    'Customized navigation',
    'Personalized coordination',
    'Multi-party administration',
    'Instant collaboration',
    'Radical accountability',
  ]

  // Ask James about this if you're curious.
  const phoneTextWithKeys = phoneText.map((text, index) => {
    return { text, key: index }
  })

  const ReviewContainer = ({ review, name }) => {
    return (
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        height='100%'
        width='100%'
        paddingTop='40px'
      >
        <Typography
          variant='h2'
          color='#fff'
          sx={{
            fontSize: {
              xs: '1.5rem',
              md: '2rem',
            },
            textIndent: '-8px',
            fontFamily: 'Poppins',
            fontWeight: 600,
            textAlign: 'center',
          }}
        >
          "{review}"
        </Typography>
        <Typography
          variant='h4'
          color='#fff'
          sx={{
            fontSize: {
              xs: '1rem',
              md: '1.5rem',
            },
          }}
        >
          - {name}
        </Typography>
      </Box>
    )
  }

  const TechPartnerLogoContainer = ({ logoSource, altText, customWidth, link }) => {
    return (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        height='150px'
        marginX='25px'
        sx={{
          width: {
            xs: customWidth || '250px',
            md: customWidth || '140px',
          },
        }}
      >
        <a href={link} target='_blank' rel='noreferrer'>
          <img
            src={logoSource}
            alt={altText}
            style={{
              width: '100%',
              objectFit: 'contain',
            }}
          />
        </a>
      </Box>
    )
  }

  const PhysicianPartnerLogoContainer = ({ logoSource, altText, customWidth, link }) => {
    return (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        height='150px'
        marginX='30px'
        sx={{
          width: {
            xs: customWidth || '300px',
            md: customWidth || '200px',
          },
        }}
      >
        <a href={link} target='_blank' rel='noreferrer'>
          <img
            src={logoSource}
            alt={altText}
            style={{
              width: '100%',
              objectFit: 'contain',
            }}
          />
        </a>
      </Box>
    )
  }

  const SearchTab = ({ text, setSelectedTab, selectedTab = false }) => {
    return (
      <Box
        style={{
          position: 'relative',
        }}
        color='#fff'
        borderRadius='21.125px'
        margin='0 0.5rem'
        outline={selectedTab ? '#fff 1px solid' : 'none'}
      >
        <Button
          size='medium'
          variant='text'
          color='inherit'
          onClick={() => {
            cleanUp()
            setSelectedTab()
          }}
          style={{
            borderRadius: '21.125px',
            margin: '0px',
            zIndex: '3',
          }}
        >
          <Typography variant='body1' align='left'>
            {text}
          </Typography>
        </Button>
      </Box>
    )
  }

  SearchTab.propTypes = {
    text: PropTypes.string.isRequired,
    setSelectedTab: PropTypes.func.isRequired,
    selectedTab: PropTypes.bool,
  }

  return (
    <>
      <Helmet>
        <title>Mishe: Direct care made easy. No insurance needed.</title>
        <meta property='og:title' content='Mishe: Direct care made easy. No insurance needed.' />
        <meta
          name='description'
          property='og:description'
          content='Flexible treatment options, transparent pricing, modern payment tools, and your own personal care concierge team. Mishe is bringing healing back to healthcare'
        />
        <meta property='og:url' content='https://www.mishe.co' />
        <link rel='canonical' href='https://mishe.co' />
      </Helmet>
      {/* Hero Section */}
      <Hero title={heroTitle} subText={heroSubText} />
      {/* Phone Screenshots Section */}
      <Hidden mdDown>
        <PageSection
          height={830}
          direction='up'
          slideBoxShadow='0px -8px 8px 0px rgba(0,0,0,0.1)'
          childrenOnSlide
          bgColor='#652d92'
          bgColorSlide='#dcc4f4'
          infoWidth='0%'
        >
          <Box
            display='flex'
            justifyContent='center'
            height='720px'
            width='100%'
            marginTop='40px'
            padding='20px'
          >
            <Box
              display='flex'
              flexDirection='column'
              alignItems='center'
              height='100%'
              width='100%'
              padding='1rem'
            >
              <HomePageLanderHoverInfo inputImages={phoneImages} inputText={phoneTextWithKeys} />
            </Box>
          </Box>
        </PageSection>
      </Hidden>
      <Hidden mdUp>
        <PageSection
          height={1000}
          direction='up'
          slideBoxShadow='0px -8px 8px 0px rgba(0,0,0,0.1)'
          childrenOnSlide
          bgColor='#652d92'
          bgColorSlide='#dcc4f4'
          infoWidth='0%'
        >
          <Box display='flex' justifyContent='center' height='720px' width='100%' padding='0px'>
            <Box
              display='flex'
              flexDirection='column'
              alignItems='center'
              height='100%'
              width='100%'
              padding='0px'
            >
              <HomePageLanderHoverInfoMobile
                inputImages={phoneImages}
                inputText={phoneTextWithKeys}
              />
            </Box>
          </Box>
        </PageSection>
      </Hidden>
      {/* mishehealthplan Section */}
      <PageSection
        height={800}
        direction='up'
        slideBoxShadow='0px -8px 8px 0px rgba(0,0,0,0.1)'
        childrenOnSlide
        bgColor='#dcc4f4'
        bgColorSlide='#652d92'
        infoWidth='0%'
        customZIndex={1}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: {
              xs: 'wrap',
              lg: 'nowrap',
            },
            alignItems: 'center',
            justifyContent: 'flex-end',
            minHeight: '750px',
            position: 'relative',
            padding: {
              xs: 2,
              md: 5,
            },
          }}
        >
          <Hidden mdDown>
            <Box
              width='40%'
              height='100%'
              paddingTop='64px'
              display='flex'
              alignItems='space-around'
              flexDirection='column'
            >
              <Box display='flex' justifyContent='center'>
                <SearchTab
                  text='Procedures'
                  setSelectedTab={() => setProcedureSelected(true)}
                  selectedTab={procedureSelected}
                />
                <SearchTab
                  text='Medications'
                  setSelectedTab={() => setMedicationSelected(true)}
                  selectedTab={medicationSelected}
                />
                <SearchTab
                  text='Clinics'
                  setSelectedTab={() => setClinicSelected(true)}
                  selectedTab={clinicSelected}
                />
              </Box>
              {medicationSelected && (
                <Box zIndex='7' width='100%' position='relative'>
                  {/* We do not filter by pharmacy here because the patient flow supports */}
                  <InstantSearchContainerCustom
                    HitComponent={MedicationSearchCard}
                    variant='homepage'
                    indexName='medications'
                    configuration={{
                      hitsPerPage: 3,
                    }}
                    searchBoxText='Search for a medication'
                    noResultsMessage='No results'
                    usePagination
                    clearSearchButton
                    homePageSearch
                  />
                </Box>
              )}
              {procedureSelected && (
                <Box zIndex='7' width='100%' position='relative'>
                  <InstantSearchContainerCustom
                    HitComponent={ServiceSearchCard}
                    variant='homepage'
                    indexName='network_services'
                    configuration={{
                      filters: 'network.slug:mishehealthplan OR network.slug:mishe',
                      hitsPerPage: 3,
                      aroundRadius: 'all',
                    }}
                    searchBoxText='Search for a procedure'
                    noResultsMessage='No results'
                    usePagination
                    useLocation
                    clearSearchButton
                    homePageSearch
                    showZipcode
                    geoSearch
                  />
                </Box>
              )}
              {clinicSelected && (
                <Box zIndex='7' width='100%' position='relative'>
                  <InstantSearchContainerCustom
                    HitComponent={ClinicSearchCard}
                    variant='homepage'
                    indexName='clinics'
                    configuration={{
                      hitsPerPage: 3,
                      aroundRadius: 'all',
                    }}
                    searchBoxText='Search for a clinic'
                    noResultsMessage='No results'
                    usePagination
                    // useLocation
                    clearSearchButton
                    homePageSearch
                    showZipcode
                    geoSearch
                  />
                </Box>
              )}
            </Box>
            <Box
              display='flex'
              alignItems='center'
              sx={{
                maxWidth: '60%',
                height: '100%',
                padding: {
                  xs: 0,
                  sm: 2,
                  lg: '100px 24px 24px 64px',
                },
                marginTop: {
                  xs: '-18px',
                  md: 0,
                },
                marginBottom: {
                  xs: '72px',
                  md: 0,
                },
              }}
            >
              <Fade in={true} timeout={2500}>
                <Box>
                  <Typography
                    margin='0'
                    color='#fff'
                    sx={{
                      textAlign: {
                        xs: 'center',
                        md: 'left',
                      },
                      fontSize: {
                        xs: '1.2rem',
                        sm: '1.25rem',
                      },
                      marginBottom: 1,
                      textTransform: 'uppercase',
                      letterSpacing: '0.1em',
                      width: '100%',
                      fontFamily: 'Poppins',
                      fontWeight: 500,
                    }}
                  >
                    Mishe Health Plan
                  </Typography>
                  <Box marginBottom='20px'>
                    <Typography
                      color='#fff'
                      sx={{
                        textAlign: {
                          xs: 'center',
                          md: 'left',
                        },
                        fontSize: {
                          xs: '2.2rem',
                          sm: '3rem',
                          md: '4rem',
                        },
                        lineHeight: {
                          xs: '1.25',
                        },
                        fontFamily: 'Poppins',
                        fontWeight: 700,
                      }}
                    >
                      Covering everyone with a universal health benefit
                    </Typography>
                  </Box>
                  <Typography
                    margin='0'
                    color='#fff'
                    sx={{
                      textAlign: {
                        xs: 'center',
                        md: 'left',
                      },
                      fontSize: {
                        xs: '1.35rem',
                        sm: '1.5rem',
                        md: '1.75rem',
                      },
                      lineHeight: {
                        xs: '1.2',
                      },
                      marginBottom: {
                        xs: 1,
                        md: 3,
                      },
                      fontFamily: 'Poppins',
                      fontWeight: 300,
                    }}
                  >
                    Transparent Prices. Guaranteed Discounts. Massive Savings.
                  </Typography>
                  <Box
                    style={{
                      position: 'relative',
                      width: 'min-content',
                    }}
                    color='#652d92'
                    borderRadius='28px'
                    margin='0 0.5rem'
                  >
                    <Button
                      size='large'
                      variant='text'
                      color='inherit'
                      href='/plans/mishehealthplan'
                      style={{
                        borderRadius: '28px',
                        backgroundColor: '#fff',
                        margin: '0px',
                        zIndex: '3',
                      }}
                    >
                      <StoryTypography
                        variant='body1'
                        align='left'
                        weight='semibold'
                        style={{ whiteSpace: 'nowrap' }}
                      >
                        Learn More
                      </StoryTypography>
                    </Button>
                  </Box>
                </Box>
              </Fade>
            </Box>
          </Hidden>
          <Hidden mdUp>
            <Box
              display='flex'
              alignItems='center'
              sx={{
                width: '100%',
                height: '50%',
                padding: {
                  xs: 0,
                  sm: 2,
                  lg: '100px 24px 24px 64px',
                },
              }}
            >
              <Fade in={true} timeout={2500}>
                <Box>
                  <Typography
                    margin='0'
                    color='#fff'
                    sx={{
                      textAlign: {
                        xs: 'center',
                        md: 'left',
                      },
                      fontSize: {
                        xs: '1.2rem',
                        sm: '1.25rem',
                      },
                      marginBottom: 1,
                      textTransform: 'uppercase',
                      letterSpacing: '0.1em',
                      width: '100%',
                      fontFamily: 'Poppins',
                      fontWeight: 500,
                    }}
                  >
                    The Mishe Health Plan
                  </Typography>
                  <Box marginBottom='20px'>
                    <Typography
                      color='#fff'
                      sx={{
                        textAlign: {
                          xs: 'center',
                          md: 'left',
                        },
                        fontSize: {
                          xs: '2.2rem',
                          sm: '3rem',
                          md: '4rem',
                        },
                        lineHeight: {
                          xs: '1.25',
                        },
                        fontFamily: 'Poppins',
                        fontWeight: 700,
                      }}
                    >
                      Covering everyone with a universal health benefit
                    </Typography>
                  </Box>
                  <Typography
                    margin='0'
                    color='#fff'
                    sx={{
                      textAlign: {
                        xs: 'center',
                        md: 'left',
                      },
                      fontSize: {
                        xs: '1.35rem',
                        sm: '1.5rem',
                        md: '1.75rem',
                      },
                      lineHeight: {
                        xs: '1.2',
                      },
                      marginBottom: {
                        xs: 1,
                        md: 3,
                      },
                      fontFamily: 'Poppins',
                      fontWeight: 300,
                    }}
                  >
                    Transparent Prices. Guaranteed Discounts. Massive Savings.
                  </Typography>
                  <Box
                    width='100%'
                    color='#652d92'
                    display='flex'
                    justifyContent='center'
                    marginTop='16px'
                  >
                    <Button
                      size='medium'
                      variant='text'
                      color='inherit'
                      href='/plans/mishehealthplan'
                      style={{
                        borderRadius: '21.125px',
                        backgroundColor: '#fff',
                        margin: '0px',
                        zIndex: '3',
                      }}
                    >
                      <StoryTypography
                        variant='body1'
                        align='left'
                        weight='semibold'
                        style={{ whiteSpace: 'nowrap' }}
                      >
                        Learn More
                      </StoryTypography>
                    </Button>
                  </Box>
                </Box>
              </Fade>
            </Box>
            <Box
              width='100%'
              height='50%'
              display='flex'
              alignItems='space-around'
              flexDirection='column'
            >
              <Box display='flex' justifyContent='center'>
                <SearchTab
                  text='Procedures'
                  setSelectedTab={() => setProcedureSelected(true)}
                  selectedTab={procedureSelected}
                />
                <SearchTab
                  text='Medications'
                  setSelectedTab={() => setMedicationSelected(true)}
                  selectedTab={medicationSelected}
                />
                <SearchTab
                  text='Clinics'
                  setSelectedTab={() => setClinicSelected(true)}
                  selectedTab={clinicSelected}
                />
              </Box>
              {medicationSelected && (
                <Box zIndex='7' width='100%' position='relative'>
                  <InstantSearchContainerCustom
                    HitComponent={MedicationSearchCard}
                    variant='homepage'
                    indexName='medications'
                    configuration={{
                      hitsPerPage: 3,
                    }}
                    searchBoxText='Search for a medication'
                    noResultsMessage='No results'
                    usePagination
                    clearSearchButton
                    homePageSearch
                  />
                </Box>
              )}
              {procedureSelected && (
                <Box zIndex='7' width='100%' position='relative'>
                  <InstantSearchContainerCustom
                    HitComponent={ServiceSearchCard}
                    variant='homepage'
                    indexName='network_services'
                    configuration={{
                      filters: 'network.slug:mishehealthplan OR network.slug:mishe',
                      hitsPerPage: 3,
                      aroundRadius: 'all',
                    }}
                    searchBoxText='Search for a procedure'
                    noResultsMessage='No results'
                    usePagination
                    // useLocation
                    clearSearchButton
                    homePageSearch
                    showZipcodeMobile
                    geoSearch
                  />
                </Box>
              )}
              {clinicSelected && (
                <Box zIndex='7' width='100%' position='relative'>
                  <InstantSearchContainerCustom
                    HitComponent={ClinicSearchCard}
                    variant='homepage'
                    indexName='clinics'
                    configuration={{
                      hitsPerPage: 3,
                      aroundRadius: 'all',
                    }}
                    searchBoxText='Search for a clinic'
                    noResultsMessage='No results'
                    usePagination
                    // useLocation
                    clearSearchButton
                    homePageSearch
                    showZipcodeMobile
                    geoSearch
                  />
                </Box>
              )}
            </Box>
          </Hidden>
        </Box>
      </PageSection>
      {/* Reviews Section */}
      <PageSection
        height={500}
        direction='up'
        childrenOnSlide
        bgColor='#652d92'
        bgColorSlide='#dcc4f4'
        infoWidth='0%'
      >
        <Box
          position='relative'
          display='flex'
          justifyContent='center'
          flexDirection='column'
          height='100%'
          width='100%'
          sx={{
            padding: {
              xs: '80px 16px 16px 16px',
              md: '80px 0px 0px 0px',
            },
          }}
        >
          <Box>
            <Typography
              variant='h2'
              color='#fff'
              sx={{
                fontSize: {
                  xs: '1.5rem',
                  md: '2rem',
                },
                textIndent: '-8px',
                fontFamily: 'Poppins',
                fontWeight: 600,
                textAlign: 'center',
              }}
            >
              What patients are saying
            </Typography>
            <Typography
              variant='h4'
              color='#fff'
              sx={{
                fontSize: {
                  xs: '1rem',
                  md: '1.5rem',
                },
                textAlign: 'center',
              }}
            >
              We think we&apos;re great, but don&apos;t take it from us.
            </Typography>
          </Box>
          <Hidden mdDown>
            <ComponentShifterFadeControl cycleLength={7000} buttonColor='#fff'>
              <ReviewContainer
                review='They went above and beyond the call of duty for me. There were problems with providers, their services, and more. But Miche worked through all of it. I wish I had that kind of persistence and patience.'
                name='Mike'
              />
              <ReviewContainer
                review="I left an online form incomplete and Mishe called me the next day to complete the referral. I'm impressed by the dedication and professionalism such behavior demonstrates."
                name='Brian'
              />
              <ReviewContainer
                review='Mishe was my savior. I cannot thank them enough for this very unique service and will only use them moving forward for my medical needs.'
                name='Nicole'
              />
              <ReviewContainer
                review="We received a bill of 52K for the same surgery for which Mishe gave us a quote of 5.6K. I'll definitely recommend their service to anyone and especially to those who are in the same situation."
                name='Aitzaz'
              />
            </ComponentShifterFadeControl>
          </Hidden>
          <Hidden mdUp>
            <ComponentShifterFade length={7000}>
              <ReviewContainer
                review='They went above and beyond the call of duty for me. There were problems with providers, their services, and more. But Miche worked through all of it. I wish I had that kind of persistence and patience.'
                name='Mike'
              />
              <ReviewContainer
                review="I left an online form incomplete and Mishe called me the next day to complete the referral. I'm impressed by the dedication and professionalism such behavior demonstrates."
                name='Brian'
              />
              <ReviewContainer
                review='Mishe was my savior. I cannot thank them enough for this very unique service and will only use them moving forward for my medical needs.'
                name='Nicole'
              />
              <ReviewContainer
                review="We received a bill of 52K for the same surgery for which Mishe gave us a quote of 5.6K. I'll definitely recommend their service to anyone and especially to those who are in the same situation."
                name='Aitzaz'
              />
            </ComponentShifterFade>
          </Hidden>
        </Box>
      </PageSection>
      {/* Treated by world leading Physicians */}
      <PageSection
        height={400}
        direction='up'
        childrenOnSlide
        bgColor='#dcc4f4'
        bgColorSlide='#652d92'
        infoWidth='0%'
      >
        <Box
          display='flex'
          justifyContent='center'
          flexDirection='column'
          height='100%'
          width='100%'
          sx={{
            padding: {
              xs: '16px',
              md: '48px',
            },
          }}
        >
          <Typography
            variant='h2'
            color='#fff'
            sx={{
              fontSize: {
                xs: '1.5rem',
                md: '2rem',
              },
              textIndent: '-8px',
              fontFamily: 'Poppins',
              fontWeight: 600,
              textAlign: 'center',
            }}
          >
            Treated by world leading Physicians
          </Typography>
          <Typography
            variant='h4'
            color='#fff'
            sx={{
              fontSize: {
                xs: '1rem',
                md: '1.5rem',
              },
              textAlign: 'center',
            }}
          >
            Our network partners power the world&apos;s leading health systems.
          </Typography>
          <Box display='flex' justifyContent='center' width='100%' marginTop='20px'>
            <Hidden mdDown>
              <PhysicianPartnerLogoContainer
                logoSource={houstonMethodist}
                altText='houstonMethodist'
              />
              <PhysicianPartnerLogoContainer logoSource={mountSinai} altText='mountSinai' />
              <PhysicianPartnerLogoContainer
                logoSource={newYorkPres}
                altText='newYorkPres'
                customWidth='400px'
              />
              <PhysicianPartnerLogoContainer logoSource={nyuLagoon} altText='nyuLagoon' />
              <PhysicianPartnerLogoContainer
                logoSource={universityHospitalsCleaveland}
                altText='universityHospitalsCleaveland'
              />
            </Hidden>
            <Hidden mdUp>
              <ComponentShifterFade length={3500}>
                <PhysicianPartnerLogoContainer
                  logoSource={houstonMethodist}
                  altText='houstonMethodist'
                />
                <PhysicianPartnerLogoContainer logoSource={mountSinai} altText='mountSinai' />
                <PhysicianPartnerLogoContainer
                  logoSource={newYorkPres}
                  altText='newYorkPres'
                  customWidth='400px'
                />
                <PhysicianPartnerLogoContainer logoSource={nyuLagoon} altText='nyuLagoon' />
                <PhysicianPartnerLogoContainer
                  logoSource={universityHospitalsCleaveland}
                  altText='universityHospitalsCleaveland'
                />
              </ComponentShifterFade>
            </Hidden>
          </Box>
        </Box>
      </PageSection>
      {/* Powered By World Class Infrastructure */}
      <PageSection
        height={400}
        direction='up'
        childrenOnSlide
        bgColor='#652d92'
        bgColorSlide='#dcc4f4'
        infoWidth='0%'
      >
        <Box
          display='flex'
          justifyContent='center'
          flexDirection='column'
          height='100%'
          width='100%'
          sx={{
            padding: {
              xs: '16px',
              md: '48px',
            },
          }}
        >
          <Typography
            variant='h2'
            color='#fff'
            sx={{
              fontSize: {
                xs: '1.5rem',
                md: '2rem',
              },
              textIndent: '-8px',
              fontFamily: 'Poppins',
              fontWeight: 600,
              textAlign: 'center',
            }}
          >
            Powered by world class infrastructure
          </Typography>
          <Typography
            variant='h4'
            color='#fff'
            sx={{
              fontSize: {
                xs: '1rem',
                md: '1.5rem',
              },
              textAlign: 'center',
            }}
          >
            Our technology partners power the world&apos;s largest brands.
          </Typography>
          <Box display='flex' justifyContent='center' width='100%' marginTop='20px'>
            <Hidden mdDown>
              <TechPartnerLogoContainer logoSource={algolia} altText='algolia' />
              <TechPartnerLogoContainer logoSource={google} altText='google' />
              <TechPartnerLogoContainer logoSource={intercom} altText='intercom' />
              <TechPartnerLogoContainer logoSource={klarna} altText='klarna' />
              <TechPartnerLogoContainer logoSource={twilio} altText='twilio' />
              <TechPartnerLogoContainer logoSource={nih} altText='nih' customWidth='300px' />
              <TechPartnerLogoContainer logoSource={applePay} altText='applePay' />
              <TechPartnerLogoContainer logoSource={stripe} altText='stripe' />
            </Hidden>
            <Hidden mdUp>
              <ComponentShifterFade length={3500}>
                <TechPartnerLogoContainer logoSource={algolia} altText='algolia' />
                <TechPartnerLogoContainer logoSource={google} altText='google' />
                <TechPartnerLogoContainer logoSource={intercom} altText='intercom' />
                <TechPartnerLogoContainer logoSource={klarna} altText='klarna' />
                <TechPartnerLogoContainer logoSource={twilio} altText='twilio' />
                <TechPartnerLogoContainer logoSource={nih} altText='nih' />
                <TechPartnerLogoContainer logoSource={applePay} altText='applePay' />
                <TechPartnerLogoContainer logoSource={stripe} altText='stripe' />
              </ComponentShifterFade>
            </Hidden>
          </Box>
        </Box>
      </PageSection>
      {/* As mentioned in */}
      <PageSection
        height={400}
        direction='up'
        childrenOnSlide
        bgColor='#dcc4f4'
        bgColorSlide='#652d92'
        infoWidth='0%'
      >
        <Box
          display='flex'
          justifyContent='center'
          flexDirection='column'
          height='100%'
          width='100%'
          sx={{
            padding: {
              xs: '16px',
              md: '48px',
            },
          }}
        >
          <Typography
            variant='h2'
            color='#fff'
            sx={{
              fontSize: {
                xs: '1.5rem',
                md: '2rem',
              },
              textIndent: '-8px',
              fontFamily: 'Poppins',
              fontWeight: 600,
              textAlign: 'center',
            }}
          >
            As mentioned in
          </Typography>
          <Typography
            variant='h4'
            color='#fff'
            sx={{
              fontSize: {
                xs: '1rem',
                md: '1.5rem',
              },
              textAlign: 'center',
            }}
          >
            We're revolutionizing healthcare and people are talking.
          </Typography>
          <Box display='flex' justifyContent='center' width='100%' marginTop='20px'>
            <Hidden mdDown>
              <PhysicianPartnerLogoContainer
                logoSource={forbesLogo}
                altText='forbes'
                link='https://www.forbes.com/sites/gebai/2024/04/07/more-risks-more-opportunities-whats-next-for-employers-in-healthcare/?sh=7c3ff36465f4'
              />
              <PhysicianPartnerLogoContainer
                logoSource={beckersWhiteLogo}
                altText='beckers'
                link='https://www.beckershospitalreview.com/finance/suny-downstate-health-sciences-u-partnership-aims-to-improve-price-transparency.html'
              />
              <PhysicianPartnerLogoContainer
                logoSource={medicineToMarketLogoWhite}
                altText='medicineToMarket'
                link='https://medicinetomarket.com/mishe-unveils-multi-channel-pharmacy-network/'
              />
              <PhysicianPartnerLogoContainer
                logoSource={medicalEconomicsLogo}
                altText='medicalEconomics'
                link='https://www.medicaleconomics.com/view/why-cash-is-the-first-step-to-eliminating-barriers-in-health-care'
              />
              <PhysicianPartnerLogoContainer
                logoSource={andersonLogo}
                altText='anderson'
                link='https://a16z.com/the-biggest-company-in-the-world/'
              />
            </Hidden>
            <Hidden mdUp>
              <ComponentShifterFade length={3500}>
                <PhysicianPartnerLogoContainer
                  logoSource={forbesLogo}
                  altText='forbes'
                  link='https://www.forbes.com/sites/gebai/2024/04/07/more-risks-more-opportunities-whats-next-for-employers-in-healthcare/?sh=7c3ff36465f4'
                />
                <PhysicianPartnerLogoContainer
                  logoSource={beckersWhiteLogo}
                  altText='beckers'
                  link='https://www.beckershospitalreview.com/finance/suny-downstate-health-sciences-u-partnership-aims-to-improve-price-transparency.html'
                />
                <PhysicianPartnerLogoContainer
                  logoSource={medicineToMarketLogoWhite}
                  altText='medicineToMarket'
                  link='https://medicinetomarket.com/mishe-unveils-multi-channel-pharmacy-network/'
                />
                <PhysicianPartnerLogoContainer
                  logoSource={medicalEconomicsLogo}
                  altText='medicalEconomics'
                  link='https://www.medicaleconomics.com/view/why-cash-is-the-first-step-to-eliminating-barriers-in-health-care'
                />
                <PhysicianPartnerLogoContainer
                  logoSource={andersonLogo}
                  altText='anderson'
                  link='https://a16z.com/the-biggest-company-in-the-world/'
                />
              </ComponentShifterFade>
            </Hidden>
          </Box>
        </Box>
      </PageSection>
      {/* Affiliated with Industry Changemakers */}
      <PageSection
        height={400}
        direction='up'
        childrenOnSlide
        bgColor='#652d92'
        bgColorSlide='#dcc4f4'
        infoWidth='0%'
      >
        <Box
          display='flex'
          justifyContent='center'
          flexDirection='column'
          height='100%'
          width='100%'
          sx={{
            padding: {
              xs: '16px',
              md: '48px',
            },
          }}
        >
          <Typography
            variant='h2'
            color='#fff'
            sx={{
              fontSize: {
                xs: '1.5rem',
                md: '2rem',
              },
              textIndent: '-8px',
              fontFamily: 'Poppins',
              fontWeight: 600,
              textAlign: 'center',
            }}
          >
            Affiliated with industry changemakers
          </Typography>
          <Typography
            variant='h4'
            color='#fff'
            sx={{
              fontSize: {
                xs: '1rem',
                md: '1.5rem',
              },
              textAlign: 'center',
            }}
          >
            Healthcare has reached an inflection point. The future is now.
          </Typography>
          <Box display='flex' justifyContent='center' width='100%' marginTop='20px'>
            <Hidden mdDown>
              <PhysicianPartnerLogoContainer
                logoSource={fmmaLogoWhite}
                altText='fmma'
                link='https://fmma.org/'
              />
              <PhysicianPartnerLogoContainer
                logoSource={healthRosettaLogo}
                altText='healthRosetta'
                link='https://healthrosetta.org/'
              />
              <PhysicianPartnerLogoContainer
                logoSource={patientRightsLogo}
                altText='patientRights'
                link='https://patientrightsadvocate.org/'
              />
              <PhysicianPartnerLogoContainer
                logoSource={dpcLogo}
                altText='dpc'
                link='https://www.dpcare.org/'
              />
            </Hidden>
            <Hidden mdUp>
              <ComponentShifterFade length={3500}>
                <PhysicianPartnerLogoContainer
                  logoSource={fmmaLogoWhite}
                  altText='fmma'
                  link='https://fmma.org/'
                />
                <PhysicianPartnerLogoContainer
                  logoSource={healthRosettaLogo}
                  altText='healthRosetta'
                  link='https://healthrosetta.org/'
                />
                <PhysicianPartnerLogoContainer
                  logoSource={patientRightsLogo}
                  altText='patientRights'
                  link='https://patientrightsadvocate.org/'
                />
                <PhysicianPartnerLogoContainer
                  logoSource={dpcLogo}
                  altText='dpc'
                  link='https://www.dpcare.org/'
                />
              </ComponentShifterFade>
            </Hidden>
          </Box>
        </Box>
      </PageSection>
      {/* FAQ */}
      <FAQSection />
      {/* Cocierge */}
      <LiveChat />
    </>
  )
}

export default LandingPage
